import Debug from "debug";
import * as React from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  cloneEntity,
  deepEqualsByTopLevelProperties,
  Entity,
  getFieldArray,
  QueryOptions,
  Row,
} from "packages/gossamer-universal";
import {
  displayReduxTKError,
  getPageNumber,
  initializeQueryOptions,
  ListSettings,
  moveToPage,
  Paginator,
  setSearchQueryOptions,
  useQuery,
} from "../index";
import styles from "./QueryMulti.module.css";

const debug = Debug("gfe/da/QueryMulti");

export interface SubSectionMultiProps<T extends Row> {
  entity: Entity<T>;
  excludeValues?: string[];
  onRowClick?: (keyValue: string) => void;
  queryOptions?: QueryOptions;
  rowClickSetsSearchPaths?: boolean;
  rows: T[];
  updateQueryOptions?: (newQueryOptions: QueryOptions) => void;
}

interface QueryMultiURLParamsProps<T extends Row> {
  alterEntity?: (cloneEntity: Entity<T>) => void;
  entity: Entity<T>;
  excludeValues?: string[];
  onRowClick?: (keyValue: string) => void;
  render: (props: SubSectionMultiProps<T>) => JSX.Element;
  showItemCount?: boolean;
  showSettingsControl?: boolean;
  showPaginator?: "never" | "as-required" | "always";
}

type QueryMultiProps<T extends Row> = QueryMultiURLParamsProps<T> & {
  queryOptions?: QueryOptions;
};

export const QueryMulti = <T extends Row>(props: QueryMultiProps<T>): JSX.Element => {
  const [localEntity, setLocalEntity] = React.useState<Entity<T>>(initializeEntity(props));
  const [prevPropQueryOptions, setPrevPropQueryOptions] = React.useState<QueryOptions>(props.queryOptions);
  const [queryOptions, setQueryOptions] = React.useState<QueryOptions>(
    initializeQueryOptions(localEntity, props.queryOptions || {})
  );
  React.useEffect(() => {
    if (!checkQueryOptionsChanges(props.queryOptions, prevPropQueryOptions)) {
      return;
    }
    debug(`props.queryOptions change`);
    setPrevPropQueryOptions(props.queryOptions);
    setQueryOptions(initializeQueryOptions(localEntity, props.queryOptions || {}));
  }, [props.queryOptions]);
  return (
    <QueryMultiInner
      localEntity={localEntity}
      setLocalEntity={setLocalEntity}
      queryOptions={queryOptions}
      setQueryOptions={setQueryOptions}
      excludeValues={props.excludeValues}
      onRowClick={props.onRowClick}
      render={props.render}
      showItemCount={props.showItemCount}
      showSettingsControl={props.showSettingsControl}
      showPaginator={props.showPaginator}
    />
  );
};

const getQueryOptionsFromSearchParams = (localEntity: Entity<{}>, searchParams: URLSearchParams) => {
  let queryOptions = {};
  try {
    queryOptions = JSON.parse(searchParams.get("q")) || {};
  } catch (e) {
    console.error(`error parsing 'q' url param for QueryOptions`);
  }
  return initializeQueryOptions(localEntity, queryOptions);
};

const getSearchParamsFromQueryOptions = (queryOptions: QueryOptions) => {
  const searchParams = new URLSearchParams();
  searchParams.set("q", JSON.stringify(queryOptions || {}));
  return searchParams;
};

const checkQueryOptionsChanges = (newQueryOptions: QueryOptions, oldQueryOptions: QueryOptions): boolean => {
  const equals = deepEqualsByTopLevelProperties(oldQueryOptions, newQueryOptions);
  if (Object.values(equals).indexOf(false) === -1) {
    return false;
  }
  delete equals.offset;
  const pageChangeOnly = Object.values(equals).indexOf(false) === -1;
  if (!pageChangeOnly) {
    newQueryOptions.offset = 0;
  }
  return true;
};

export const QueryMultiURLParams = <T extends Row>(props: QueryMultiURLParamsProps<T>): JSX.Element => {
  const [localEntity, setLocalEntity] = React.useState<Entity<T>>(initializeEntity(props));
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const queryOptions = getQueryOptionsFromSearchParams(localEntity, searchParams);
  const setQueryOptions = (newQueryOptions: QueryOptions) => {
    checkQueryOptionsChanges(newQueryOptions, queryOptions);
    setSearchQueryOptions(pathname, newQueryOptions);
    setSearchParams(getSearchParamsFromQueryOptions(newQueryOptions));
  };
  return (
    <QueryMultiInner
      localEntity={localEntity}
      setLocalEntity={setLocalEntity}
      queryOptions={queryOptions}
      setQueryOptions={setQueryOptions}
      excludeValues={props.excludeValues}
      onRowClick={props.onRowClick}
      render={props.render}
      showItemCount={props.showItemCount}
      showSettingsControl={props.showSettingsControl}
      showPaginator={props.showPaginator}
    />
  );
};

interface InnerProps<T extends Row> {
  excludeValues?: string[];
  localEntity: Entity<T>;
  setLocalEntity: (localEntity: Entity<T>) => void;
  onRowClick?: (keyValue: string) => void;
  queryOptions: QueryOptions;
  setQueryOptions: (queryOptions: QueryOptions) => void;
  render: (props: SubSectionMultiProps<T>) => JSX.Element;
  showItemCount?: boolean;
  showSettingsControl?: boolean;
  showPaginator?: "never" | "as-required" | "always";
}

export const QueryMultiInner = <T extends Row>(props: InnerProps<T>): JSX.Element => {
  const { data, error, isFetching } = useQuery<T>(props.localEntity, props.queryOptions);
  const SubSection = props.render;
  const [pageNumber, pageSize] = getPageNumber(props.queryOptions);
  const showPaginator =
    props.showPaginator === "always" ||
    ((!props.showPaginator || props.showPaginator === "as-required") && (data?.length >= pageSize || pageNumber > 1));

  const mainClassName = isFetching ? styles.FetchingOpacity : "";
  return (
    <>
      <div className={mainClassName}>
        {data && !error && (
          <SubSection
            excludeValues={props.excludeValues}
            entity={props.localEntity}
            onRowClick={props.onRowClick}
            queryOptions={props.queryOptions}
            rows={data}
            updateQueryOptions={props.setQueryOptions}
          />
        )}
      </div>
      <div className={styles.QueryMultiFooter}>
        {showPaginator && !error && !isFetching && (
          <Paginator
            pageNumber={pageNumber}
            pageSize={pageSize}
            moveToPage={(newPage) => props.setQueryOptions(moveToPage(props.queryOptions, newPage))}
            rowsInPage={data?.length}
          />
        )}
        {!showPaginator && !error && !isFetching && props.showItemCount !== false && data && (
          <div>{data.length === 0 ? "none" : data.length === 1 ? "1 item" : data.length + " items"}</div>
        )}
        {props.showSettingsControl !== false && !error && !isFetching && (
          <ListSettings
            entity={props.localEntity}
            handleCopyDataToClipboard={(includeHeader?: boolean) =>
              copyDataToClipboard(props.localEntity, data, includeHeader)
            }
            queryOptions={props.queryOptions}
            updateEntity={props.setLocalEntity}
            updateQueryOptions={props.setQueryOptions}
          />
        )}
      </div>
      {error && <div>{displayReduxTKError(error)}</div>}
      {isFetching && (
        <div>
          <Spinner animation="border" size="sm" />
        </div>
      )}
    </>
  );
};

const initializeEntity = <T extends Row>(props: QueryMultiProps<T>) => {
  const localEntity = cloneEntity(props.entity);
  if (props.alterEntity) {
    props.alterEntity(localEntity);
  }
  return localEntity;
};

const copyDataToClipboard = async <T extends Row>(entity: Entity<T>, rows: T[], includeHeader: boolean) => {
  if (!rows) {
    return;
  }
  const convertCell = (from: string) => {
    if (/[,\n\r\"]/.exec(from)) {
      return `"${from.replace(/\"/g, '""')}"`;
    }
    return from;
  };
  const array = rows.map((row) =>
    getFieldArray(entity)
      .filter(([field, _]) => field.listColumn)
      .map(([_, fieldId]) => convertCell(row[fieldId]))
      .join(",")
  );
  if (includeHeader) {
    array.unshift(
      getFieldArray(entity)
        .filter(([field, _]) => field.listColumn)
        .map(([field, _]) => convertCell(field.label))
        .join(",")
    );
  }
  const str = array.join("\n");
  debug(str);
  navigator.clipboard.writeText(str);
  await new Promise((resolve) => {
    setTimeout(resolve, 250);
  });
};
