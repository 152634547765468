import * as React from "react";
import { PageProps, PageTitle, Section, TransButtons, useTrans } from "packages/gossamer-react-redux";
import { Event } from "model";

const FIELD_COUNT = 23;

interface RowOutcome {
  isValid: boolean;
  validationSummary?: string;
}

export const BulkUpload = (props: PageProps): JSX.Element => {
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/",
  });
  const [rowOutcomes, setRowOutcomes] = React.useState<RowOutcome[]>([]);
  const InnerFrame = props.innerFrame;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    if (!data) {
      console.warn("no data");
      return;
    }
    event.target.value = "";
    const allCells = data.split("\t"); // annoyingly pasting from a spreadsheet has no line terminator
    const outcomes = [];
    while (allCells.length > 0) {
      const cells = allCells.splice(0, FIELD_COUNT);
      for (let i = 0; i < FIELD_COUNT; i += 1) {
        cells[i] = cells[i] || "";
      }
      if (cells.reduce((prev, curr) => prev && !curr, true)) {
        continue;
      }
      cells.forEach((cell, index) => {
        console.log(`>${index}<>${cell}<>${cell.length}<>${cell.codePointAt(cell.length - 1)}<`);
      });
      const out: RowOutcome = {
        isValid: false,
      };
      try {
        const row = trans.makeCreateRow(Event, {
          title: cells[0],
          type: cells[1],
          description: cells[2],
          specialism: cells[3],
          grade: cells[4],
          distributionType: cells[5],
          location: cells[6],
          startsAt: cells[7] ? new Date(cells[7]).toISOString() : null,
          endsAt: cells[8] ? new Date(cells[8]).toISOString() : null,
          price: cells[9],
          visibility: cells[10],
          bookingMethod: cells[11],
          capacity: Number(cells[12]),
          bookingUrl: cells[13],
          eventScale: cells[14],
          allowsOralPresentation: cells[15] === "YES",
          allowsPosterPresentation: cells[16] === "YES",
          clinicalAuditPresentations: cells[17],
          teachingProgramme: cells[18] === "YES",
          teachingProgrammeDuration: cells[19],
          acceptCoTutorOffers: cells[20] === "YES",
          teachingQualification: cells[21],
          leadershipAndManagement: cells[22],
        });
        const validations = row.getValidations();
        out.isValid = validations.length === 0;
        if (!out.isValid) {
          out.validationSummary = validations.map(([fieldId, msg, val]) => `${fieldId}: '${val}' ${msg}`).join(", ");
          row.setDelete(true);
        }
      } catch (e) {
        console.error(e);
        out.validationSummary = e.toString();
      }
      outcomes.push(out);
    }
    setRowOutcomes(outcomes);
  };
  return (
    <InnerFrame>
      <PageTitle title="Event Bulk Upload" />
      <Section>
        <div>
          <p>Click on this box and paste your data:</p>
          <input type="text" onChange={handleChange} disabled={rowOutcomes.length > 0} />
        </div>
        <table>
          <thead>
            <tr>
              <th>Row</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {rowOutcomes.map((outcome, index) => (
              <tr key={String(index)}>
                <td>{index + 1}</td>
                <td>{outcome.isValid ? "valid" : outcome.validationSummary}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {rowOutcomes.length > 0 && (
          <p>
            Click 'save' to save the valid rows (the invalid rows won't be saved - you can correct and upload them
            again), or click 'cancel' to skip saving any of them.
          </p>
        )}
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
