import * as React from "react";
import { PageProps, Section, useDocumentTitle } from "packages/gossamer-react-redux";

export const ContactUs = (props: PageProps): JSX.Element => {
  useDocumentTitle("Contact Us");
  return (
    <div
      style={{
        padding: "100px 40px",
      }}
    >
      <Section title="Contact Us">
        <p>
          Reach out to the Careerbook team at <a href="mailto:hello@careerbook.uk">hello@careerbook.uk</a> if you have
          any questions or issues.
        </p>
      </Section>
    </div>
  );
};
