import {
  ClinicalAuditPresentationOptions,
  EventRow,
  LeadershipManagementOptions,
  PresentationsOptions,
  SurgicalConferenceOptions,
  TeachingExperienceOptions,
  TeachingTrainingOptions,
  UserRow,
} from "model";

interface PointsElement {
  text: string;
  category: "medical" | "surgical";
  maxPoints: number;
  userPoints: number;
}

export const getPoints = (eventRow: EventRow, userRow?: UserRow): PointsElement[] => {
  const out: PointsElement[] = [];

  const eventPresentationType = eventRow.allowsOralPresentation
    ? "ORAL"
    : eventRow?.allowsPosterPresentation
    ? "POSTER"
    : "NONE";
  const bestOffered = eventRow.eventScale + "-" + eventPresentationType;
  out.push({
    text: `${eventPresentationType.toLowerCase()} presentation opportunity at a ${eventRow.eventScale?.toLowerCase()} event`,
    category: "medical",
    maxPoints: PresentationsOptions.find((opt) => opt.id === bestOffered)?.points || 0,
    userPoints: PresentationsOptions.find((opt) => opt.id === userRow?.presentations)?.points || 0,
  });

  out.push({
    text: `clinical audit presentations`,
    category: "surgical",
    maxPoints:
      ClinicalAuditPresentationOptions.find((opt) => opt.id === eventRow.clinicalAuditPresentations)?.points || 0,
    userPoints:
      ClinicalAuditPresentationOptions.find((opt) => opt.id === userRow?.clinicalAuditPresentations)?.points || 0,
  });

  const bestTeachingExperience =
    eventRow?.teachingProgrammeDuration === "MORE-THAN-3-MONTHS"
      ? "REGULAR"
      : ["LESS-THAN-3-MONTHS", "OCCASIONAL"].indexOf(eventRow.teachingProgrammeDuration) > -1
      ? "OCCASIONAL"
      : "NONE";
  out.push({
    text: `teaching opportunities`,
    category: "medical",
    maxPoints: TeachingExperienceOptions.find((opt) => opt.id === bestTeachingExperience)?.points || 0,
    userPoints: TeachingExperienceOptions.find((opt) => opt.id === userRow?.teachingExperience)?.points || 0,
  });

  out.push({
    text: `teaching training at ${eventRow.teachingQualification?.toLowerCase()} level`,
    category: "medical",
    maxPoints: TeachingTrainingOptions.find((opt) => opt.id === eventRow.teachingQualification)?.points || 0,
    userPoints: TeachingTrainingOptions.find((opt) => opt.id === userRow?.teachingTraining)?.points || 0,
  });

  out.push({
    text: `leadership and management opportunities`,
    category: "medical",
    maxPoints:
      LeadershipManagementOptions.find((opt) => opt.id === eventRow.leadershipAndManagement?.toLowerCase())?.points ||
      0,
    userPoints: LeadershipManagementOptions.find((opt) => opt.id === userRow?.leadershipManagement)?.points || 0,
  });

  out.push({
    text: `surgical conference`,
    category: "surgical",
    maxPoints: eventRow.type === "SURGICAL-CONF" ? 1 : 0,
    userPoints: eventRow.type === "SURGICAL-CONF" && userRow?.surgicalConferences !== "CONFS-3-PLUS" ? 1 : 0,
  });

  return out;
};

interface PointsUserSummary {
  netPoints: number;
  text: string;
}

export const getPointsUserSummary = (eventRow: EventRow, userRow?: UserRow): PointsUserSummary => {
  const elements: PointsElement[] = getPoints(eventRow, userRow);
  const netPoints = elements.reduce((prev, curr) => prev + Math.max(curr.maxPoints - curr.userPoints, 0), 0);
  return {
    netPoints,
    text:
      netPoints === 0
        ? "No extra points from this event"
        : "This event offers " +
          elements
            .filter((item) => item.maxPoints > item.userPoints)
            .map((e) => `${e.text}: ${Math.max(e.maxPoints - e.userPoints, 0)}`)
            .join(", "),
  };
};
