import * as React from "react";
import { useNavigate } from "react-router";
import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { PageProps, PageTitle, Section, handleSignIn } from "packages/gossamer-react-redux";

export const AuthRedirect: React.FC<PageProps> = (props): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const navigate = useNavigate();
  const auth = getAuth();

  getRedirectResult(auth)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log("redirect auth succeeded");
      console.log(user);
      handleSignIn(user.email, "admin", null, user.displayName, user.email);
      navigate("/");
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error?.customData?.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.error("redirect auth failed");
      console.log(errorCode, errorMessage, email, credential);
      const provider = new GoogleAuthProvider();
      signInWithRedirect(auth, provider);
    });

  return (
    <InnerFrame localMenu={() => null}>
      <PageTitle title="Login with Google" />
      <Section>
        <div>Signing in...</div>
      </Section>
    </InnerFrame>
  );
};
