import * as React from "react";
import { useParams } from "react-router-dom";
import { PageProps, PageTitle, Section, TransButtons, UpdateWrapper, useTrans } from "packages/gossamer-react-redux";
import { User } from "model";
import { getListRowLink } from "packages/gossamer-universal";

export const UserUpdate = (props: PageProps): JSX.Element => {
  const params = useParams();
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: getListRowLink(User.frontEndOptions.listRowLink, params.key),
  });
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Update" entity={User} keyValue={params.key} />
      <Section>
        <UpdateWrapper entity={User} keyValue={params.key} transaction={trans} />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
