import { FieldId, Option, Primitive, Row } from "../types/core";
import { ConditionBlock, ConditionElement, Filter, FilterDisplay, FilterOperator, QueryOptions } from "../types/data";
import { Entity, FieldType, IntegerField, PersistenceType } from "../types/entity";
import { isBlank } from "./core";
import { getFieldArray, KEY_SEPARATOR } from "./entity";

export const applyBackEndDefaults = <T extends Row>(entity: Entity<T>, row: T): void => {
  Object.keys(entity.fields).forEach((fieldId: FieldId) => {
    if (
      row[fieldId] === undefined &&
      [PersistenceType.BackEndGenerated, PersistenceType.BackEndManaged, PersistenceType.BackEndOnly].indexOf(
        entity.fields[fieldId].persistence
      ) > -1
    ) {
      if (entity.fields[fieldId].defaultVal !== undefined) {
        row[fieldId] = entity.fields[fieldId].defaultVal;
      } else if (entity.fields[fieldId].type === FieldType.Boolean) {
        row[fieldId] = false;
      }
    }
  });
};

export const doFrontEndEvaluates = <T extends Row>(entity: Entity<T>, data: T): T => {
  if (!data) {
    return data;
  }
  const out = {};
  getFieldArray(entity).forEach(([field, fieldId]) => {
    if (field.evaluateFrontEndIn) {
      const newVal = field.evaluateFrontEndIn(data);
      // debug(`evaluating %s, %s -> %s`, fieldId, data[fieldId], newVal);
      out[fieldId] = newVal;
    } else {
      out[fieldId] = data[fieldId] ?? null; // Firestore doesn't like undefined
    }
  });
  return out as T;
};

export const getAutoCompleterSearchExpression = (conditionBlock: ConditionBlock, value: string): ConditionBlock => {
  const out: ConditionBlock = {};
  let replaced = false;
  if (typeof conditionBlock !== "object") {
    throw new Error(`no autoCompleterSearchExpression provided for entity`);
  }
  const doLogicOperator = (filters: Filter[]) =>
    filters.map((filter) => {
      const newFilter = Object.assign({}, filter);
      if (newFilter.value === "[[SEARCH_TERM]]") {
        newFilter.value = value;
        replaced = true;
      }
      return newFilter;
    });

  if (conditionBlock.and) {
    out.and = doLogicOperator(conditionBlock.and as Filter[]);
  }
  if (conditionBlock.or) {
    out.or = doLogicOperator(conditionBlock.or as Filter[]);
  }
  if (!replaced) {
    console.error(`[[SEARCH_TERM]] token not found in QueryCondition`);
  }
  return out;
};

const RANGE_FIELD_TYPES = [FieldType.Date, FieldType.Datetime, FieldType.Decimal, FieldType.Integer];

export const getFieldTypeDefaultFilter = (fieldType: FieldType): FilterOperator => {
  if (RANGE_FIELD_TYPES.indexOf(fieldType) > -1) {
    return "gt"; // greater than
  }
  if (fieldType === FieldType.Boolean || fieldType === FieldType.Options || fieldType === FieldType.Reference) {
    return "eq"; // equals
  }
  return "ct"; // contains
};

export const getFieldTypeFilterOptions = (fieldType: FieldType): Option[] => {
  if (RANGE_FIELD_TYPES.indexOf(fieldType) > -1) {
    return [
      { id: "eq", label: "equals" },
      { id: "ne", label: "not equal to" },
      { id: "gt", label: "greater than" },
      { id: "lt", label: "less than" },
      { id: "bl", label: "is blank" },
      { id: "nb", label: "is non-blank" },
    ];
  }
  if (fieldType === FieldType.Boolean || fieldType === FieldType.Options || fieldType === FieldType.Reference) {
    return [
      { id: "eq", label: "equals" },
      { id: "ne", label: "not equal to" },
      { id: "bl", label: "is blank" },
      { id: "nb", label: "is non-blank" },
    ];
  }
  return [
    { id: "eq", label: "equals" },
    { id: "ne", label: "not equal to" },
    { id: "sw", label: "starts with" },
    { id: "ew", label: "ends with" },
    { id: "ct", label: "contains" },
    { id: "bl", label: "is blank" },
    { id: "nb", label: "is non-blank" },
  ];
};

export const getFilter = (
  fieldId: FieldId,
  operator: FilterOperator,
  value: Primitive | Primitive[],
  display?: FilterDisplay,
  clientTest?: <T extends Row>(row: T, value: Primitive | Primitive[], operator: FilterOperator) => boolean
): Filter => ({
  fieldId,
  operator,
  value,
  display,
  clientTest,
});

export const getFilterArrayFromKey = <T extends Row>(entity: Entity<T>, key: string): Filter[] => {
  const keyParts = key.split(KEY_SEPARATOR);
  if (keyParts.length !== entity.primaryKeyFields.length || keyParts.findIndex((part) => !part) !== -1) {
    throw new Error(`invalid key: ${key} for entity ${entity.id}`);
  }
  return entity.primaryKeyFields.map((fieldId: string, index: number) => ({
    fieldId,
    operator: "eq",
    value: keyParts[index],
  }));
};

export const getSimpleCondition = (fieldId: FieldId, value: string): ConditionBlock => ({
  and: [getFilter(fieldId, "eq", value)],
});

export const getValueAndRemoveCondition = (
  queryOptions: QueryOptions,
  fieldId: string,
  defaultVal: Primitive
): Primitive => {
  let val = defaultVal;
  if (!queryOptions.where?.and) {
    return val;
  }
  const index = queryOptions.where.and.findIndex((cond: ConditionElement) => (cond as Filter).fieldId === fieldId);
  if (index !== undefined && index > -1) {
    val = (queryOptions.where.and[index] as Filter)?.value as boolean;
    queryOptions.where?.and.splice(index, 1); // remove
  }
  return val;
};

// TODO - merge the following two functions - they do the same job
export const isApplicableFilter = (filter: Filter) => {
  return filter.operator === "bl" || filter.operator === "nb" || !!filter.value;
};

export const isOperableFilter = (filter: Filter) =>
  !isBlank(filter.value) || ["bl", "nb"].indexOf(filter.operator) > -1;

export const isAutoIncrement = <T extends Row>(entity: Entity<T>): boolean =>
  entity.primaryKeyFields.reduce((prev, curr) => prev || !!(entity.fields[curr] as IntegerField).autoIncrement, false);

export const testFilter = (filter: Filter, value: Primitive): boolean => {
  const argValStrUpper = String(value).toUpperCase();
  const filtValStrUpper = String(filter.value).toUpperCase();
  if (filter.operator === "bl") {
    return !value;
  } else if (filter.operator === "nb") {
    return !!value;
  } else if (filter.operator === "eq") {
    return isBlank(filter.value) || value === filter.value;
  } else if (filter.operator === "ne") {
    return isBlank(filter.value) || value !== filter.value;
  } else if (filter.operator === "ct") {
    return isBlank(filter.value) || argValStrUpper.indexOf(filtValStrUpper) > -1;
  } else if (filter.operator === "sw") {
    // return argValStrUpper.indexOf(filtValStrUpper) === 0;
    return isBlank(filter.value) || argValStrUpper.substring(0, filtValStrUpper.length) === filtValStrUpper;
  } else if (filter.operator === "ew") {
    // return argValStrUpper.indexOf(filtValStrUpper) === argValStrUpper.length - filtValStrUpper.length;
    return (
      isBlank(filter.value) ||
      argValStrUpper.substring(argValStrUpper.length - filtValStrUpper.length) === filtValStrUpper
    );
  } else if (filter.operator === "gt") {
    return filter.value === null || value === null || value > filter.value;
  } else if (filter.operator === "lt") {
    return filter.value === null || value === null || value < filter.value;
  } else {
    throw new Error(`unsupported operator: ${filter.operator}`);
  }
};

export const testFilterRow = <T extends Row>(filter: Filter, row: T): boolean =>
  testFilter(filter, row[filter.fieldId]);
