import { Option } from "packages/gossamer-universal";

export const gradeOptions: Option[] = [
  { id: "MEDICAL-STUDENT", label: "medical student" },
  { id: "CORE-TRAINEE", label: "Core Trainee (CT1-CT2)", active: false },
  { id: "FOUNDATION-DOCTOR", label: "foundation doctor (FY1-2)" },
  { id: "FOUNDATION-YEARS", label: "Foundation Years (FY1) & (FY2+)", active: false },
  { id: "SENIOR-HOUSE-OFFICER", label: "senior house officer (SHO)" },
  { id: "CORE-OR-SPECIALITY-TRAINEE", label: "core or speciality trainee", active: false },
  { id: "REGISTRAR", label: "SpR / registrar" },
  { id: "CONSULTANT", label: "consultant / GP" },
  { id: "SPECIALTY-TRAINEE", label: "Specialty Trainee (ST3-ST8)", active: false },
  { id: "SPECIALIST-REGISTRAR", label: "Specialist Registrar (SpR)", active: false },
  { id: "NON-DOCTOR", label: "non-doctor", active: false },
  { id: "OTHER", label: "other" },
];
