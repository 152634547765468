import Debug from "debug";
import * as React from "react";
import { FormSelect } from "react-bootstrap";
import { Option } from "packages/gossamer-universal";

const debug = Debug("gfe/fi/Dropdown");

interface DropdownProps {
  altTextBlank?: string;
  altTextChoose?: string;
  className?: string;
  clearOnSelection?: boolean;
  mandatory: boolean;
  onItemSelected: (newValue: string) => void;
  options: Option[];
  validity?: boolean;
  value: string;
}

export const Dropdown = (props: DropdownProps): JSX.Element => {
  const [value, setValue] = React.useState<string>(String(props.value) || "");
  debug(
    `Dropdown ${props.value}, ${props.options.length} matches? ${JSON.stringify(
      props.options.find((opt) => String(opt.id) === value)
    )}`
  );
  const options = props.options
    .filter((opt) => opt.active !== false)
    .map((opt) => (
      <option key={opt.id} value={String(opt.id)}>
        {opt.label}
      </option>
    ));
  if (!props.mandatory) {
    options.unshift(
      <option key="*" value="*">
        [{props.altTextBlank || "blank"}]
      </option>
    );
  } else if (!props.value) {
    options.unshift(
      <option key="*" value="*">
        [{props.altTextChoose || "choose"}]
      </option>
    );
  }
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVal = event.target.value === "*" ? "" : event.target.value;
    props.onItemSelected(newVal || null); // convert "" to null
    if (props.clearOnSelection) {
      setValue("");
    } else {
      setValue(newVal);
    }
  };
  return (
    <FormSelect
      className={props.className}
      isInvalid={props.validity === false}
      isValid={props.validity === true}
      onChange={handleChange}
      value={value}
    >
      {options}
    </FormSelect>
  );
};
