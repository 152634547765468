.HomeBlock {
  width: 100vw;
  height: 270px;
  position: relative;
}

.HomeBlockMain {
  position: absolute;
  left: 10vw;
  right: 10vw;
  text-align: center;
  top: 125px;
  line-height: 1.1;
  font-size: 40px;
  font-weight: 800;
  color: var(--bs-body-color);

  @media (min-width: 600px) {
    top: 170px;
  }
}

.HomeBlockSub {
  position: absolute;
  left: 5vw;
  right: 5vw;
  text-align: center;
  top: 230px;
  line-height: 1;

  @media (min-width: 600px) {
    top: 230px;
  }
}
