import * as React from "react";
import { Link, Outlet } from "react-router-dom";

export const Logo = (): JSX.Element => {
  return (
    <Link
      to="/"
      style={{
        textDecoration: "unset",
      }}
    >
      <img src="/careerbook.svg" style={{ width: 32 }} />
      <span
        style={{
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          verticalAlign: "middle",
        }}
      >
        CareerBook
      </span>
    </Link>
  );
};
