import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { BrowserRouter } from "react-router-dom";
import {
  Auth,
  setAuthProvider,
  setDataProvider,
  setStore,
  transReducer,
  userReducer,
} from "packages/gossamer-react-redux";
import { makeFirebaseAuthProvider, makeFirestoreDataProvider } from "../packages/provider-gcp";
import { app } from "./Firebase";
import { LoggedInRoutes } from "./logged-in/Routes";
import { NotLoggedInRoutes } from "./not-logged-in/Routes";
import { eventMiddleware } from "./event/EventMiddleware";
import { userMiddleware } from "./user/UserMiddleware";
import { onAlreadySignedIn, onSignOut, onSuccessfulSignIn, onSuccessfulSignUp } from "model";
import "./Model"; // setReduxEntitites()
import { ScrollToTop } from "./shared/ScrollToTop";
// import { setupRemoteLogging } from "./shared/RemoteLogging";

const analytics = getAnalytics(app);
const perf = getPerformance(app);

setAuthProvider(
  makeFirebaseAuthProvider(app, {
    autoSignInOnSignUp: true,
    onAlreadySignedIn,
    onSignOut,
    onSuccessfulSignIn,
    onSuccessfulSignUp,
  })
);
setDataProvider(makeFirestoreDataProvider(app));
const store = configureStore({
  reducer: {
    trans: transReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([eventMiddleware, userMiddleware]),
});
setStore(store);
// setupRemoteLogging();

const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Auth loggedInRoutes={LoggedInRoutes} notLoggedInRoutes={NotLoggedInRoutes} />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};

const target = document.querySelector("#app");
ReactDOM.render(<App />, target);
