import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDataProvider, selectUser } from "packages/gossamer-react-redux";
import { EventRow, Reservation, ReservationRow } from "model";
import { LoginOrSignupLinks } from "../not-logged-in/LoginOrSignupLinks";
import { fireEvent } from "packages/provider-gcp/Analytics";

export interface ReserveButtonProps {
  eventRow: EventRow;
  existingReservationRow?: ReservationRow | undefined;
}

type Status = "waiting" | "clicked" | "done" | "error";

export const ReserveButton = (props: ReserveButtonProps): JSX.Element => {
  const [reservationRow, setReservationRow] = React.useState<ReservationRow | undefined>(props.existingReservationRow);
  const isReserved = reservationRow?.isReserved;
  const user = useSelector(selectUser)();
  const [status, setStatus] = React.useState<Status>("waiting");
  const handleClick = () => {
    const eventName =
      isReserved && props.eventRow.bookingMethod === "IN-CAREERBOOK" ? "unbook-clicked" : "book-clicked";
    fireEvent(eventName, {
      eventId: props.eventRow.id,
      bookingMethod: props.eventRow.bookingMethod,
      userId: user.userId,
    });
  };
  if (!user?.userId) {
    return (
      <div style={{ maxWidth: 200 }}>
        <LoginOrSignupLinks /> to book your slot!
      </div>
    );
  }
  if (props.eventRow.bookingMethod === "IN-OTHER-SYSTEM") {
    return (
      <Button size="sm" variant="primary" href={props.eventRow.bookingUrl} onClick={handleClick}>
        Book
      </Button>
    );
  }
  if (props.eventRow.bookingMethod === "BY-EMAIL") {
    return (
      <Button
        size="sm"
        variant="primary"
        href={`mailto:${props.eventRow.organiser}?subject=Request to book: ${props.eventRow.title}`}
        onClick={handleClick}
      >
        Book
      </Button>
    );
  }
  if (typeof props.eventRow.capacity === "number" && (props.eventRow.reserved || 0) >= props.eventRow.capacity) {
    return <div>Sadly, this event is full</div>;
  }
  if (status === "done") {
    return <ActionButton isReserved={isReserved} isSpinning={true} />;
  }
  if (status === "clicked") {
    handleClick();
    return (
      <CreateReservation
        eventRow={props.eventRow}
        reservationRow={reservationRow}
        setReservationRow={setReservationRow}
        setStatus={setStatus}
        status={status}
        userId={user.userId}
      />
    );
  }
  if (status === "error") {
    return <div>an error occurred</div>;
  }
  return <ActionButton isReserved={isReserved} setStatus={setStatus} />;
};

interface CreateReservationProps {
  eventRow: EventRow;
  userId: string;
  reservationRow: ReservationRow | undefined;
  setReservationRow: (row: ReservationRow) => void;
  setStatus: (state: Status) => void;
  status: Status;
}

const CreateReservation = (props: CreateReservationProps): JSX.Element => {
  React.useEffect(() => {
    const reservationRow: ReservationRow = props.reservationRow || {
      eventId: props.eventRow.id,
      userId: props.userId,
      isReserved: false,
      isFavourite: false,
      reservedAt: null,
      unreservedAt: null,
    };
    if (reservationRow.isReserved) {
      reservationRow.isReserved = false;
      reservationRow.unreservedAt = new Date().toISOString();
    } else {
      reservationRow.isReserved = true;
      reservationRow.reservedAt = new Date().toISOString();
    }
    getDataProvider()
      .updateRow(Reservation, reservationRow)
      .then(() => {
        props.setStatus("waiting");
        props.setReservationRow(reservationRow);
      })
      .catch((error) => {
        console.error(error);
        props.setStatus("error");
      });
  }, []);
  return <ActionButton isReserved={props.reservationRow?.isReserved} isSpinning={true} />;
};

interface ActionButtonProps {
  setStatus?: (status: Status) => void;
  isReserved: boolean;
  isSpinning?: boolean;
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  return (
    <Button size="sm" onClick={() => props.setStatus("clicked")} variant={props.isReserved ? "danger" : "primary"}>
      {props.isSpinning && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      {props.isReserved ? <span>&nbsp;Unbook</span> : <span>&nbsp;Book</span>}
    </Button>
  );
};
