import Debug from "debug";
import { CompoundPayload, createData, Entity, getUuid, Row } from "packages/gossamer-universal";
import {
  cancelTransaction,
  createTransaction,
  getDataProvider,
  getStore,
  makeRow,
  postSaveTransaction,
  preSaveTransaction,
  selectTrans,
  touchAllFields,
  TransRow,
  trapTransactionError,
} from "../index";

const debug = Debug("gfe/da/Transaction");

export enum TransactionStatus {
  Active = "ACTIVE",
  Saved = "SAVED",
  Error = "ERROR",
  Cancelled = "CANCELLED",
}

export class Transaction {
  private allowSaveWhenUnmodified?: boolean;
  private compoundURL: string;
  private rows: TransRow<any>[];
  private sendUnmodifiedRows: boolean;
  private status: TransactionStatus;
  private transId: string;

  constructor(compoundURL: string | null, sendUnmodifiedRows: boolean, allowSaveWhenUnmodified?: boolean) {
    this.rows = [];
    this.allowSaveWhenUnmodified = !!allowSaveWhenUnmodified;
    this.compoundURL = compoundURL;
    this.sendUnmodifiedRows = sendUnmodifiedRows;
    this.status = TransactionStatus.Active;
    this.transId = getUuid();
    debug(`new Transaction: ${this.transId}`);
    getStore().dispatch(
      createTransaction({
        trans: this,
      })
    );
  }

  cancel(): void {
    this.status = TransactionStatus.Cancelled;
    getStore().dispatch(
      cancelTransaction({
        trans: this,
      })
    );
  }

  getRows(): TransRow<Row>[] {
    return this.rows;
  }

  getRowsByEntity<T extends Row>(entityId: string): TransRow<T>[] {
    return this.rows.filter((row) => row.getEntity().id === entityId);
  }

  getRow(entityId: string, key: string): TransRow<{}> {
    this.throwIfNotActive();
    const row = this.rows.find((row) => row.getEntity().id === entityId && row.getKey() === key);
    debug(`getRow: ${entityId}, ${key}, ${row}`);
    return row;
  }

  getTransId(): string {
    return this.transId;
  }

  getValidationSummary(): string {
    const msgs = this.rows.reduce(
      (prev, curr) => [
        ...prev,
        ...curr
          .getValidations()
          .map(([label, validation]): string =>
            this.rows.length === 1 ? `${label}: ${validation}` : `${curr.getEntity().title} ${label}: ${validation}`
          ),
      ],
      []
    );
    console.log(msgs);
    let out = msgs.slice(0, Math.min(msgs.length, 2));
    if (msgs.length > 2) {
      out.push(`and ${msgs.length - 2} other error${msgs.length === 3 ? "" : "s"}`);
    }
    return out.join(", ");
  }

  isModified(): boolean {
    this.throwIfNotActive();
    const state = getStore().getState();
    return selectTrans(state)(this.getTransId()).isModified;
  }

  isValid(): boolean {
    this.throwIfNotActive();
    const state = getStore().getState();
    return selectTrans(state)(this.getTransId()).isValid;
  }

  makeCreateRow<T extends Row>(entity: Entity<T>, data?: Partial<T>) {
    this.throwIfNotActive();
    const row = new TransRow<T>(entity, this.rows.length, this.transId, "C");
    this.rows.push(row);
    debug(`Api.dispatch(makeCreateRow) - before`);
    getStore().dispatch(
      makeRow({
        row,
        data: Object.assign(createData<T>(entity), data),
      })
    );
    debug(`Api.dispatch(makeCreateRow) - after`);
    return row;
  }

  makeUpdateRow<T extends Row>(entity: Entity<T>, data: Partial<T>) {
    this.throwIfNotActive();
    const row = new TransRow<T>(entity, this.rows.length, this.transId, "U");
    // debug(`makeUpdateRow: ${entity.id}, ${JSON.stringify(data)}`);
    this.rows.push(row);
    debug(`Api.dispatch(makeUpdateRow) - before`);
    getStore().dispatch(
      makeRow({
        row,
        data,
      })
    );
    debug(`Api.dispatch(makeUpdateRow) - after`);
    return row;
  }

  postSave(): void {
    if (this.status !== TransactionStatus.Saved) {
      throw new Error(`must only be called when transaction is saved`);
    }
    getStore().dispatch(
      postSaveTransaction({
        trans: this,
      })
    );
  }

  save(outcomeId: string): Promise<any> {
    if (!this.allowSaveWhenUnmodified && !this.isModified()) {
      throw new Error(`nothing has been changed`);
    }
    getStore().dispatch(touchAllFields({ trans: this }));
    this.rows.filter((row) => !row.isCreating() || !row.isDeleting()).forEach((row) => row.validate());

    if (!this.isValid()) {
      // getStore().dispatch(reportInvalidFields({ trans: this }));
      throw new Error(this.getValidationSummary());
    }
    getStore().dispatch(
      preSaveTransaction({
        trans: this,
        outcomeId,
      })
    );
    if (!this.isValid()) {
      getStore().dispatch(trapTransactionError({ trans: this }));
      throw new Error(`transaction is not valid`);
    }
    return this.compoundURL ? this.saveCompound(outcomeId) : this.saveElement();
  }

  saveCompound(outcomeId: string): Promise<any> {
    const payload: CompoundPayload = {
      transId: this.transId,
      outcomeId,
      frontEndSaveAt: new Date().toISOString(),
      frontEndPath: location.pathname,
      rows: this.rows
        .filter((row) => !row.isCreating() || !row.isDeleting())
        .map((row) => row.getRowEnvelope())
        .filter((payload) => this.sendUnmodifiedRows || payload.action !== "N"),
    };
    return getDataProvider()
      .compound(this.compoundURL, payload)
      .then((resp: any) => {
        this.status = TransactionStatus.Saved;
        debug(`compound save successful...`);
        debug(resp);
        return resp;
      })
      .catch((error) => {
        debug(error);
        getStore().dispatch(trapTransactionError({ trans: this }));
        // try allowing the Transaction to remain valid
        // this.status = TransactionStatus.Error;
        throw error;
      });
  }

  saveElement(): Promise<any> {
    return Promise.all(this.rows.map((row) => row.save()))
      .then(() => {
        this.status = TransactionStatus.Saved;
        return `elementSave() successful on ${this.rows.length} rows`;
      })
      .catch((error) => {
        debug(error);
        throw new Error(error);
      });
  }

  throwIfNotActive() {
    if (this.status !== TransactionStatus.Active) {
      throw new Error(`this transaction is not active`);
    }
  }
}
