import Debug from "debug";
import * as React from "react";
import { FormControl, ListGroup, Spinner } from "react-bootstrap";
import { Entity, getAutoCompleterSearchExpression, getRowKey, Row } from "packages/gossamer-universal";
import { displayReduxTKError, useEventListener, useQuery } from "../index";
import styles from "./AutoCompleter.module.css";

const debug = Debug("gfe/fi/AutoCompleter");

interface AutocompleterProps {
  className?: string;
  clearOnSelection?: boolean;
  entity: Entity<{}>;
  excludeValues?: string[];
  label?: string;
  linkedLookupValue?: string;
  mandatory: boolean;
  onItemSelected: (newValue: string) => void;
  validity?: boolean;
  value: string;
}

export const AutoCompleter = (props: AutocompleterProps): JSX.Element => {
  const [active, setActive] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>(props.value || "");
  React.useEffect(() => {
    setSearchTerm(props.label || "");
  }, [props.label]);
  const onClose = () => {
    setSearchTerm("");
    setActive(false);
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(`onInputChange(${event.target.value || ""})`);
    setSearchTerm(event.target.value || "");
    setActive(true);
  };
  const onItemSelected = (newValue: string, newLabel: string) => {
    debug(`onItemSelected(${newValue})`);
    setSearchTerm(newLabel);
    setActive(false);
    if (props.clearOnSelection) {
      setSearchTerm("");
    }
    props.onItemSelected(newValue);
  };
  const className = styles.AutoCompleter + (props.className ? " " + props.className : "");
  return (
    <FormControl as="div" className={className}>
      <input type="text" onChange={onInputChange} value={searchTerm} autoComplete="off" />
      {searchTerm?.length > 2 && active && (
        <AutoCompleterSub
          entity={props.entity}
          excludeValues={props.excludeValues}
          linkedLookupValue={props.linkedLookupValue}
          onClose={onClose}
          onItemSelected={onItemSelected}
          searchTerm={searchTerm}
        />
      )}
    </FormControl>
  );
};

interface AutoCompleterSubProps<T extends Row> {
  entity: Entity<T>;
  excludeValues?: string[];
  existingValue?: string;
  linkedLookupValue?: string;
  onClose: () => void;
  onItemSelected: (newValue: string, newLabel: string) => void;
  searchTerm: string;
}

const AutoCompleterSub = <T extends {}>(props: AutoCompleterSubProps<T>): JSX.Element => {
  debug(`AutoCompleterSub`);
  debug(props);
  const [highlightRow, setHighlightRow] = React.useState<number>();
  const { data, error, isFetching } = useQuery(props.entity, {
    offset: 0,
    limit: 20,
    order: props.entity.defaultSort,
    where: getAutoCompleterSearchExpression(
      props.entity.frontEndOptions?.autoCompleterSearchExpression,
      props.searchTerm
    ),
  });
  useEventListener(
    "keydown",
    React.useCallback(
      (event: KeyboardEvent) => {
        debug(`inside keyboard listener`);
        if (data?.length <= highlightRow) {
          setHighlightRow(undefined);
        }
        if (!data || data?.length === 0) {
          return;
        }
        if (event.key === "ArrowDown") {
          if (highlightRow === undefined || highlightRow < data?.length - 1) {
            setHighlightRow(highlightRow === undefined ? 0 : highlightRow + 1);
          }
        } else if (event.key === "ArrowUp") {
          if (highlightRow !== undefined) {
            setHighlightRow(highlightRow === 0 ? undefined : highlightRow - 1);
          }
        } else if (event.key === "Enter") {
          const item = data[highlightRow];
          debug(`calling onItemSelected`);
          props.onItemSelected(
            getRowKey(props.entity, item),
            (props.entity.frontEndOptions?.rowLabelAutocompleterItem || props.entity.rowLabel)(item)
          );
        } else if (event.key === "Escape") {
          props.onClose();
        }
      },
      [highlightRow, data?.length]
    )
  );
  const filteredData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data
      .map((item) => {
        return Object.assign({}, item, {
          key: getRowKey(props.entity, item),
        });
      })
      .filter((item) => !props.excludeValues || props.excludeValues.indexOf(item.key) === -1);
  }, [data]);
  if (error) {
    return <div>{displayReduxTKError(error)}</div>;
  }
  return (
    <ListGroup className={styles.AutoResults} variant="flush">
      {error && <ListGroup.Item key="~error">{displayReduxTKError(error)}</ListGroup.Item>}
      {isFetching && (
        <ListGroup.Item key="~loading">
          <Spinner animation="border" size="sm" />
        </ListGroup.Item>
      )}
      {filteredData.map((item, index: number) => {
        const label = (props.entity.frontEndOptions?.rowLabelAutocompleterItem || props.entity.rowLabel)(item);
        return (
          <ListGroup.Item
            action
            active={item.key === props.existingValue || index === highlightRow}
            key={item.key}
            onClick={props.onItemSelected.bind(null, item.key, label)}
          >
            {label}
          </ListGroup.Item>
        );
      })}
      {!error && !isFetching && data.length === 0 && <ListGroup.Item key="~none">no matches</ListGroup.Item>}
    </ListGroup>
  );
};
