import * as React from "react";
import { Field, FieldId, getRowKey, Row } from "packages/gossamer-universal";
import { ErrorBoundary, General, SubSectionMultiProps } from "../index";
import styles from "./DataPills.module.css";

export interface DataPillsProps<T extends Row> extends SubSectionMultiProps<T> {
  field: Field;
  fieldId: FieldId;
}

export const DataPills = <T extends {}>(props: DataPillsProps<T>): JSX.Element => {
  const children = props.rows
    .filter((row) => !props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1)
    .map((row: any) => {
      const key = getRowKey(props.entity, row);
      return (
        <div key={key}>
          <General editable={false} fieldId={props.fieldId} field={props.field} value={row[props.fieldId]} />
        </div>
      );
    });
  if (props.rows.length === 0) {
    return null;
  }
  return (
    <ErrorBoundary>
      <div className={styles.DataPills}>{children}</div>
    </ErrorBoundary>
  );
};
