import * as React from "react";
import { Link } from "react-router-dom";
import { Entity, getListRowLink } from "packages/gossamer-universal";
import { DynamicText } from "../index";

interface UneditableReferenceProps<T extends {}> {
  data?: T;
  refEntity: Entity<T>;
  suppressLink?: boolean;
  value: string;
}

export const UneditableReference = <T extends {}>(props: UneditableReferenceProps<T>): JSX.Element => {
  const label = props.data
    ? (
        props.refEntity.frontEndOptions?.rowLabelReference ||
        props.refEntity.frontEndOptions?.rowLabelDynamic ||
        props.refEntity.rowLabel
      )(props.data)
    : `[${props.value}: unknown]`;
  const linkURL =
    props.data &&
    props.refEntity.frontEndOptions?.listRowLink &&
    !props.suppressLink &&
    getListRowLink(props.refEntity.frontEndOptions?.listRowLink, props.value);
  const content = <DynamicText text={label} />;
  if (linkURL) {
    return <Link to={linkURL}>{content}</Link>;
  }
  return content;
};
