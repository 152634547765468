import * as React from "react";
import { Button, Stack } from "react-bootstrap";
import { Display, getConfig, PageProps, PageTitle, QuerySingle, Section } from "packages/gossamer-react-redux";
import { User } from "../../model";

export const BasicProfile = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle title="My Profile" />
      <Section>
        <QuerySingle
          entity={User}
          keyValue={getConfig("user-id")}
          render={(innerProps) => <Display {...innerProps} fieldGroup="main" />}
        />
        <Stack direction="horizontal" gap={2}>
          <Button href={`/user/profile-main-update`}>Update</Button>
          <Button href={`/user/select-organisation`}>Set Organisation</Button>
        </Stack>
      </Section>
    </InnerFrame>
  );
};
