import * as React from "react";
import { PageProps, useDocumentTitle } from "packages/gossamer-react-redux";

export const HowItWorks = (props: PageProps): JSX.Element => {
  useDocumentTitle("How it works");
  return (
    <div
      style={{
        padding: "100px 40px",
      }}
    >
      <h2>
        Welcome to <b>Careerbook</b>,
      </h2>
      <p>
        <b>Careerbook</b> is your all in one booking site for booking and organising medical courses, teaching sessions,
        and conferences.
      </p>
      <h3>Profile Page</h3>
      <p>
        This is where you can add and record your achievements using our checklist tool, which mirrors the criteria used
        for core and specialty application scores. By doing this, we can highlight and pair events that match points you
        may still need to boost your score.
      </p>
      <h3>How it works</h3>
      <ol>
        <li>
          Search and book onto a medical event: Easily find and book a wide range of medical courses, conferences, and
          teaching sessions.
        </li>
        <li>
          Boost your chance of getting into training using our Automated Points Calculator: Using our automated points
          calculator in your profile page, we can find and match events that meet your needs and help you gain points in
          areas of the application system to boost your score
        </li>
        <li>
          Create and advertise your events: Allowing you to enhance your bookings, earn for your course, teaching
          session or conference and gain points for your application score
        </li>
        <li>
          Enhanced Visibility: For organisations, Careerbook offers a platform to advertise events and reach a wider
          audience of medical professionals than ever before.
        </li>
        <li>
          Monetise your course: Looking to make money from a course, create them here and manage your bookings, cutting
          costs from making and running websites and admin fees
        </li>
      </ol>
      <h3>Tips to Maximise Your Careerbook Experience</h3>
      <ol>
        <li>
          Regularly Update Your Profile: Keep your achievements and progress up-to-date to get the most accurate event
          recommendations.
        </li>
        <li>
          Explore a Variety of Events: Use the search and filter tools to find events that best match your needs and
          interests.{" "}
        </li>
        <li>
          Leverage the Automated Points Calculator: Make the most of our points calculator to strategically choose
          events that will enhance your application scores.
        </li>
      </ol>
    </div>
  );
};
