import * as React from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAuthProvider, selectUser } from "../index";

export const UserButton = (): JSX.Element => {
  const user = useSelector(selectUser)();
  const navigate = useNavigate();
  if (!user.userId) {
    return (
      <Button size="sm" href="/signin">
        Log in
      </Button>
    );
  }
  return (
    <DropdownButton size="sm" title={"User"}>
      <Dropdown.Item>{user.name}</Dropdown.Item>
      <Dropdown.Item>{user.roleId}</Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          getAuthProvider()
            .signOut()
            .then(() => navigate("/"));
        }}
      >
        Log out
      </Dropdown.Item>
    </DropdownButton>
  );
};
