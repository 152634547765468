import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { ProfileMainUpdate } from "./ProfileMainUpdate";
import { ProfileSurgicalUpdate } from "./ProfileSurgicalUpdate";
import { MyProfile } from "./MyProfile";
import { UserCreate } from "./UserCreate";
import { UserDisplay } from "./UserDisplay";
import { UserSearch } from "./UserSearch";
import { UserUpdate } from "./UserUpdate";
import { PageProps } from "packages/gossamer-react-redux";
import { ProfileMedicalUpdate } from "./ProfileMedicalUpdate";
import { SelectOrganisation } from "./SelectOrganisation";
import { PointsCalculator } from "./PointsCalculator";
import { BasicProfile } from "./BasicProfile";

export const UserRoutes = (props: PageProps): JSX.Element => (
  <Routes>
    <Route path="/create" element={<UserCreate {...props} />} />
    <Route path="/display/:key" element={<UserDisplay {...props} />} />
    <Route path="/points-calc-medical" element={<PointsCalculator {...props} />} />
    <Route path="/points-calc-surgical" element={<PointsCalculator {...props} />} />
    <Route path="/profile-main-update" element={<ProfileMainUpdate {...props} />} />
    <Route path="/profile-medical-update" element={<ProfileMedicalUpdate {...props} />} />
    <Route path="/profile-surgical-update" element={<ProfileSurgicalUpdate {...props} />} />
    <Route path="/profile-main" element={<BasicProfile {...props} />} />
    <Route path="/profile-medical" element={<MyProfile {...props} />} />
    <Route path="/profile-surgical" element={<MyProfile {...props} />} />
    <Route path="/search" element={<UserSearch {...props} />} />
    <Route path="/select-organisation" element={<SelectOrganisation {...props} />} />
    <Route path="/update/:key" element={<UserUpdate {...props} />} />
  </Routes>
);
