import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Entity, getTitlePlural } from "packages/gossamer-universal";
import { getSearchURLFromPath, IsAllowed } from "../index";

export interface SearchLinkProps {
  entity: Entity<{}>;
  label?: string;
  urlPath: string;
  urlPrefix?: string;
  isActive?: boolean;
}

export const SearchLink = (props: SearchLinkProps): JSX.Element => {
  const localLabel = props.label || getTitlePlural(props.entity);
  const searchURL = (props.urlPrefix && getSearchURLFromPath(props.urlPrefix)) || props.urlPath;
  return (
    <IsAllowed entity={props.entity} action="read">
      <Link
        style={{
          fontWeight: props.isActive ? 700 : 400,
        }}
        to={searchURL}
      >
        {localLabel}
      </Link>
    </IsAllowed>
  );
};

export const useActiveItem = (links: Record<string, SearchLinkProps[]>, defaultItem?: string) => {
  const { pathname } = useLocation();
  return Object.keys(links).reduce((prev, curr) => {
    links[curr].forEach((link) => (link.isActive = false));
    const match =
      links[curr].find((link) => pathname === link.urlPath) ||
      links[curr].find((link) => link.urlPrefix && pathname.indexOf(link.urlPrefix) === 0);
    if (match) {
      match.isActive = true;
      return curr;
    }
    return prev;
  }, defaultItem);
};
