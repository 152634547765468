import Debug from "debug";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { getSearchURLFromPath, setListManagerSubscriber } from "../index";

const debug = Debug("gfe/ancillary/NavIcons");

export const NavIcons = (): JSX.Element => {
  const [paths, setPaths] = React.useState<string[]>(null);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const search = searchParams.toString() ? "?" + searchParams.toString() : "";
  const searchURL = getSearchURLFromPath(pathname);
  setListManagerSubscriber(setPaths);
  const currPathIndex = paths && paths.findIndex((path) => pathname.endsWith(path));
  const prevPath = paths && currPathIndex > 0 ? paths[currPathIndex - 1] + search : null;
  const nextPath =
    paths && currPathIndex > -1 && currPathIndex < paths.length - 1 ? paths[currPathIndex + 1] + search : null;
  debug(`${paths}, ${currPathIndex}, ${prevPath}, ${nextPath}`);
  return (
    <>
      {searchURL && (
        <Link to={searchURL} title="return to search">
          <Button variant="primary" size="sm">
            🔍
          </Button>
        </Link>
      )}
      {!searchURL && (
        <Button variant="primary" size="sm" disabled={true}>
          🔍
        </Button>
      )}
      {prevPath && (
        <Link to={prevPath} title="previous item">
          <Button variant="primary" size="sm">
            ▲
          </Button>
        </Link>
      )}
      {!prevPath && (
        <Button variant="primary" size="sm" disabled={true}>
          ▲
        </Button>
      )}
      {nextPath && (
        <Link to={nextPath} title="next item">
          <Button variant="primary" size="sm">
            ▼
          </Button>
        </Link>
      )}
      {!nextPath && (
        <Button variant="primary" size="sm" disabled={true}>
          ▼
        </Button>
      )}
    </>
  );
};
