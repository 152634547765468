.FormControlHoriz {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.FormControlVert {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
