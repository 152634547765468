import Debug from "debug";
import { Entity, getRowKey, getListRowLink, QueryOptions } from "packages/gossamer-universal";

const debug = Debug("gfe/an/ListManager");

const urlPrefixToSearchList: SearchItem[] = [];
let setPathsFunc: PathsSubscriber = null;

export type PathsSubscriber = (keys: string[]) => void;
export interface SearchItem {
  urlPrefix: string;
  searchURL: string;
  queryOptions?: QueryOptions;
}

export const addUrlPrefixToSearch = (urlPrefix: string, searchURL: string, entity?: Entity<{}>) => {
  if (getSearchURLFromPath(urlPrefix)) {
    throw new Error(`urlPrefix already registered: ${urlPrefix}`);
  }
  // debug(`adding item to url prefix list: ${urlPrefix}, ${searchURL}`);
  const item: SearchItem = { urlPrefix, searchURL };
  if (entity) {
    item.queryOptions = {
      where: {
        and: entity.defaultFilters || [],
      },
    };
  }
  urlPrefixToSearchList.push(item);
};

export const getSearchURLFromPath = (path: string) => {
  const item = urlPrefixToSearchList.find(({ urlPrefix }) => path.indexOf(urlPrefix) === 0);
  if (!item) {
    return undefined;
  }
  let out = item.searchURL;
  if (item.queryOptions) {
    out += "?q=" + encodeURIComponent(JSON.stringify(item.queryOptions));
  }
  // debug(`getSearchURLFromPath(${path}) => ${out}`);
  return out;
};

export const setListManagerSubscriber = (pathsSub: PathsSubscriber): void => {
  setPathsFunc = pathsSub;
};

export const setSearchQueryOptions = (path: string, queryOptions: QueryOptions) => {
  const item = urlPrefixToSearchList.find(({ urlPrefix }) => path.indexOf(urlPrefix) === 0);
  debug(`setSearchQueryOptions(${path}) item found? ${!!item}`);
  if (item) {
    item.queryOptions = queryOptions;
  }
};

export const updateListManagerWithPaths = (paths: string[]) => {
  debug(`updateListManager: ${paths}`);
  if (setPathsFunc) {
    setPathsFunc(paths);
  }
};

export const updateListManagerWithRows = <T extends {}>(entity: Entity<T>, rows: T[]) => {
  const paths = rows.map((row) => getListRowLink(entity.frontEndOptions.listRowLink, getRowKey(entity, row)));
  debug(`handleRowLinkClick(${paths})`);
  updateListManagerWithPaths(paths);
};
