import Debug from "debug";
import * as React from "react";
import { InputGroup } from "react-bootstrap";
import { cloneDeep } from "lodash";
import {
  ConditionElement,
  Field,
  Filter,
  getFieldArray,
  getFieldTypeDefaultFilter,
  getRowKey,
  isFieldFilterable,
  Option,
  Row,
} from "packages/gossamer-universal";
import { Dropdown, ErrorBoundary, List, SearchCriterion, SubSectionMultiProps } from "../index";

const debug = Debug("gfe/se/Search");

const recentSelections: Record<string, Option[]> = {};
const MAX_RECENT_SELECTIONS = 20;

interface Props<T extends Row> extends SubSectionMultiProps<T> {
  render?: (props: SubSectionMultiProps<T>) => JSX.Element;
}

export const Search = <T extends {}>(props: Props<T>): JSX.Element => {
  const newFields: Option[] = React.useMemo(
    () =>
      getFieldArray(props.entity)
        .filter(([field, _]: [Field, string]) => isFieldFilterable(field))
        .map(([field, fieldId]: [Field, string]) => {
          return {
            id: fieldId,
            label: field.label,
          };
        }),
    [props.entity]
  );

  const handleFilterChange = (index: number, newFilter: Filter) => {
    const newQueryOptions = cloneDeep(props.queryOptions);
    newQueryOptions.where.and[index] = newFilter;
    props.updateQueryOptions(newQueryOptions);
  };

  const handleAddFilter = (fieldId: string) => {
    const newQueryOptions = cloneDeep(props.queryOptions);
    newQueryOptions.where.and.push({
      fieldId,
      operator: getFieldTypeDefaultFilter(props.entity.fields[fieldId].type),
      value: "",
    });
    props.updateQueryOptions(newQueryOptions);
  };

  const handleDeleteFilter = (index: number) => {
    const newQueryOptions = cloneDeep(props.queryOptions);
    newQueryOptions.where.and.splice(index, 1);
    props.updateQueryOptions(newQueryOptions);
  };

  const SubSection = props.render || List;

  const onRowClick = props.onRowClick
    ? (keyValue: string) => {
        recentSelections[props.entity.id] = recentSelections[props.entity.id] || [];
        const selectedRow = props.rows.find((row) => getRowKey(props.entity, row) === keyValue);
        const label = selectedRow ? props.entity.rowLabel(selectedRow) : "[unknown]";
        const index = recentSelections[props.entity.id].findIndex((option) => option.id === keyValue);
        if (index > -1) {
          // if selected item is already in list then remove it
          recentSelections[props.entity.id].splice(index, 1);
        }
        recentSelections[props.entity.id].unshift({
          id: keyValue,
          label,
        });
        if (recentSelections[props.entity.id].length > MAX_RECENT_SELECTIONS) {
          recentSelections[props.entity.id].pop();
        }
        props.onRowClick(keyValue);
      }
    : null;

  const onRecentSelectedReused = (keyValue: string) => {
    props.onRowClick(keyValue);
  };

  return (
    <ErrorBoundary>
      {props.queryOptions.where.and.map((conditionElement: ConditionElement, index) => {
        const filter = conditionElement as Filter;
        if (!filter.fieldId || filter.display === "hidden") {
          return; // silently ignore ConditionElements that are not Filters or are marked as hidden
        }
        return (
          <SearchCriterion
            display={filter.display || "full-edit"}
            key={filter.uniqueId || filter.fieldId}
            field={props.entity.fields[filter.fieldId]}
            filter={conditionElement as Filter}
            handleChange={handleFilterChange.bind(null, index)}
            handleDelete={handleDeleteFilter.bind(null, index)}
          />
        );
      })}
      <InputGroup size="sm" className="mb10px addSearchFilter">
        <InputGroup.Text>Add a search filter</InputGroup.Text>
        <Dropdown
          clearOnSelection={true}
          mandatory={false}
          onItemSelected={handleAddFilter}
          options={newFields}
          value=""
        />
      </InputGroup>
      {recentSelections[props.entity.id] && (
        <InputGroup size="sm" className="mb10px recentSelections">
          <InputGroup.Text>Recent Selections</InputGroup.Text>
          <Dropdown
            clearOnSelection={true}
            mandatory={false}
            onItemSelected={onRecentSelectedReused}
            options={recentSelections[props.entity.id]}
            value=""
          />
        </InputGroup>
      )}
      <SubSection {...props} rowClickSetsSearchPaths={true} onRowClick={onRowClick} />
    </ErrorBoundary>
  );
};
