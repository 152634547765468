import Debug from "debug";
import * as React from "react";
import { Field, ReferenceField } from "packages/gossamer-universal";
import { General, getEntity } from "../index";

const debug = Debug("gfe/fi/Flex");

export type FlexContents = Field & { value: string };

export interface Props {
  fieldId: string;
  value: string;
}

export const Flex = (props: Props): JSX.Element => {
  debug(props);
  try {
    const fieldAndValue = JSON.parse(props.value) as FlexContents;
    const value = fieldAndValue.value;
    delete fieldAndValue.value;
    if (typeof (fieldAndValue as ReferenceField).refEntity === "string") {
      (fieldAndValue as ReferenceField).refEntity = getEntity(
        (fieldAndValue as ReferenceField).refEntity as unknown as string
      );
    }
    return <General editable={false} fieldId={props.fieldId} field={fieldAndValue} value={value} />;
  } catch (e) {
    console.error(e);
    console.error(`Error parsing as a Flex field`, props.value);
    return <span>parsing error</span>;
  }
};
