
.FieldBlock {
  display: flex;
  flex-direction: column;
}

.FieldBlock > *:nth-child(1) {
  font-size: small;
  color: var(--bs-secondary-color);
}
