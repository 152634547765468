import * as React from "react";

import { slugify } from "packages/gossamer-universal";
import styles from "./Section.module.css";

interface SectionProps {
  children?: React.ReactNode;
  id?: string;
  subtitle?: string;
  title?: string;
}

export const Section = (props: SectionProps): JSX.Element => {
  return (
    <section className={styles.Section} id={props.id || props.title ? slugify(props.title) : "main"}>
      {props.title && <h2>{props.title}</h2>}
      {props.subtitle && <p className="small">{props.subtitle}</p>}
      {props.children}
    </section>
  );
};
