import { AuthProvider } from "packages/gossamer-universal";
import { getConfig, handleSignIn } from "..";

let provider: AuthProvider = null;

export const getAuthProvider = () => {
  if (!provider) {
    throw new Error(`AuthProvider has not been set`);
  }
  return provider;
};

export const setAuthProvider = (arg: AuthProvider) => {
  if (provider) {
    throw new Error(`AuthProvider already set`);
  }
  provider = arg;
};

export const makeDemoAuthProvider = (): AuthProvider => {
  const onload = () => {
    if (getConfig("login-bypass") === "true") {
      handleSignIn(getConfig("user-id"), getConfig("role-id"), getConfig("org-path"), null, null);
    }
  };

  const passwordResetInitiate = () => {
    throw new Error(`not implemented`);
  };

  const passwordResetComplete = () => {
    throw new Error(`not implemented`);
  };

  const passwordResetResendCode = () => {
    throw new Error(`not implemented`);
  };

  const signIn = () => {
    throw new Error(`not implemented`);
  };

  const signInMFACode = () => {
    throw new Error(`not implemented`);
  };

  const signOut = () => {
    throw new Error(`not implemented`);
  };

  const signUp = () => {
    throw new Error(`not implemented`);
  };

  const signUpResendCode = () => {
    throw new Error(`not implemented`);
  };

  const signUpVerifyCode = () => {
    throw new Error(`not implemented`);
  };

  const signUpVerifyMFACode = () => {
    throw new Error(`not implemented`);
  };

  return {
    onload,
    passwordResetInitiate,
    passwordResetComplete,
    passwordResetResendCode,
    signIn,
    signInMFACode,
    signOut,
    signUp,
    signUpResendCode,
    signUpVerifyCode,
    signUpVerifyMFACode,
  };
};
