import Debug from "debug";
import {
  DataRowAction,
  Entity,
  Field,
  FieldId,
  getFieldArray,
  getRowKey,
  PersistenceType,
  Primitive,
  Row,
  RowEnvelope,
} from "packages/gossamer-universal";
import {
  getDataProvider,
  getStore,
  ReduxTransRow,
  selectRow,
  setFieldTouched,
  setFieldValue,
  setRowDeleting,
  validateRow,
} from "../index";

const debug = Debug("gfe/da/TransRow");

export class TransRow<T extends Row> {
  private action: DataRowAction;
  private active: boolean;
  private entity: Entity<T>;
  private rowNumber: number;
  private transId: string;

  constructor(entity: Entity<T>, rowNumber: number, transId: string, action: DataRowAction) {
    this.action = action;
    this.active = true;
    this.entity = entity;
    this.rowNumber = rowNumber;
    this.transId = transId;
  }

  getAction(): DataRowAction {
    return this.action;
  }

  getEntity(): Entity<T> {
    return this.entity;
  }

  getFieldValue(fieldId: FieldId): Primitive {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    return row.fields[fieldId].currValue;
  }

  getKey(): string {
    const payload = this.getRawData();
    return getRowKey(this.entity, payload);
  }

  private getPayload(row: ReduxTransRow): T {
    const payload: any = {};
    Object.keys(row.fields).forEach((fieldId) => {
      payload[fieldId] = row.fields[fieldId].currValue;
    });
    return payload;
  }

  getResponsePayload(row: ReduxTransRow): T {
    const payload = this.getPayload(row);
    Object.keys(row.fields).forEach((fieldId) => {
      if ([PersistenceType.FrontEndOnly].indexOf(this.entity.fields[fieldId].persistenceType) > -1) {
        delete payload[fieldId];
      }
    });
    return payload;
  }

  getRawData(): T {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    return this.getPayload(row);
  }

  getRowEnvelope(): RowEnvelope<T> {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    const data = this.getResponsePayload(row);
    const isModified = Object.keys(data).reduce(
      (prev, curr) => prev || data[curr] !== row.fields[curr].origValue,
      false
    );
    return {
      action: this.isCreating() ? "C" : this.isDeleting() ? "D" : isModified ? "U" : "N",
      entityId: this.entity.id,
      origTransId: row.origTransId,
      data,
    };
  }

  getRowNumber(): number {
    return this.rowNumber;
  }

  getTransId(): string {
    return this.transId;
  }

  getValidations(): any /*[string, string][]*/ {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    return getFieldArray(this.entity)
      .map(([field, fieldId]): [string, string, Primitive] => [
        field.label,
        row.fields[fieldId].validation,
        row.fields[fieldId].currValue,
      ])
      .filter(([_, validation]) => !!validation);
  }

  isCreating(): boolean {
    return this.action === "C";
  }

  isDeleting(): boolean {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    return row.isDeleting;
  }

  isModified(): boolean {
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    return row.isModified;
  }

  save(): Promise<any> {
    this.throwIfNotActive();
    const state = getStore().getState();
    const row = selectRow(state)(this.transId, this.rowNumber);
    const payload = this.getPayload(row);
    debug(`saving: ${this.entity.id} with payload: `, payload);
    if (row.action !== "C") {
      if (!row.isDeleting && !row.isModified) {
        return Promise.resolve(false); // nothing to update and is not deleting - no action
      }
    }
    const key = getRowKey(this.entity, payload);
    if (row.isDeleting) {
      if (row.action === "C") {
        return Promise.resolve(false); // creating and deleting - no action
      }
      getDataProvider()
        .deleteRow(this.entity, key)
        .then(() => {
          this.active = false;
          return true;
        });
    }
    if (!row.isModified) {
      throw new Error(`TransRow is not modified`);
    }
    if (row.action === "C") {
      return getDataProvider()
        .createRow(this.entity, payload)
        .then(() => {
          this.active = false;
          return true;
        });
    }
    return getDataProvider()
      .updateRow(this.entity, payload)
      .then(() => {
        this.active = false;
        return true;
      });
  }

  setFieldTouched(fieldId: FieldId): void {
    getStore().dispatch(
      setFieldTouched({
        row: this,
        fieldId,
      })
    );
  }

  setFieldValue(fieldId: FieldId, value: Primitive): void {
    getStore().dispatch(
      setFieldValue({
        row: this,
        fieldId,
        value,
      })
    );
  }

  setDelete(arg: boolean): void {
    getStore().dispatch(
      setRowDeleting({
        row: this,
        isDeleting: arg,
      })
    );
  }

  throwIfFieldDoesNotExist(fieldId: FieldId) {
    if (!this.entity.fields[fieldId]) {
      throw new Error(`field '${fieldId}' not recognized on entity ${this.entity.id}`);
    }
  }

  throwIfNotActive() {
    if (!this.active) {
      throw new Error(`this TransRow is not active`);
    }
  }

  validate(): void {
    getStore().dispatch(
      validateRow({
        row: this,
      })
    );
  }
}
