import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { Four04, SignIn, SignUp } from "packages/gossamer-react-redux";
import { AuthRedirect } from "packages/provider-gcp";
import { Home } from "./Home";
import { InnerFrame } from "./InnerFrame";
import { PageFrame } from "./PageFrame";
import { EventDisplay } from "front-end/event/EventDisplay";
import { ForgotPasswordSend } from "packages/gossamer-react-redux/auth/ForgotPasswordSend";

export const NotLoggedInRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<PageFrame />}>
        <Route index element={<Home innerFrame={InnerFrame} />} />
        <Route path="/event/display/:key" element={<EventDisplay innerFrame={InnerFrame} />} />
        <Route path="/forgot-password-send" element={<ForgotPasswordSend />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/redirect" element={<AuthRedirect innerFrame={InnerFrame} />} />
        <Route path="*" element={<Four04 innerFrame={InnerFrame} />} />
      </Route>
    </Routes>
  );
};
