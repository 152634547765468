import * as React from "react";
import { useSelector } from "react-redux";
import { getDataProvider, selectUser } from "packages/gossamer-react-redux";
import { EventRow, Reservation, ReservationRow } from "model";
import { fireEvent } from "packages/provider-gcp/Analytics";

export interface FavouriteButtonProps {
  eventRow: EventRow;
  existingReservationRow?: ReservationRow | undefined;
}

type Status = "waiting" | "clicked" | "done" | "error";

export const FavouriteButton = (props: FavouriteButtonProps): JSX.Element => {
  const [reservationRow, setReservationRow] = React.useState<ReservationRow | undefined>(props.existingReservationRow);
  const user = useSelector(selectUser)();
  const [status, setStatus] = React.useState<Status>("waiting");
  if (!user?.userId) {
    return <div />;
  }
  if (status === "done") {
    return <ActionButton isFavourite={reservationRow?.isFavourite} isSpinning={true} />;
  }
  if (status === "clicked") {
    const eventName = reservationRow?.isFavourite ? "unfavourite-clicked" : "favourite-clicked";
    fireEvent(eventName, {
      eventId: props.eventRow.id,
      bookingMethod: props.eventRow.bookingMethod,
      userId: user.userId,
    });
    return (
      <CreateReservation
        eventRow={props.eventRow}
        reservationRow={reservationRow}
        setReservationRow={setReservationRow}
        setStatus={setStatus}
        status={status}
        userId={user.userId}
      />
    );
  }
  if (status === "error") {
    return <div>an error occurred</div>;
  }
  return <ActionButton isFavourite={reservationRow?.isFavourite} setStatus={setStatus} />;
};

interface CreateReservationProps {
  eventRow: EventRow;
  userId: string;
  reservationRow: ReservationRow | undefined;
  setReservationRow: (row: ReservationRow) => void;
  setStatus: (state: Status) => void;
  status: Status;
}

const CreateReservation = (props: CreateReservationProps): JSX.Element => {
  React.useEffect(() => {
    const reservationRow: ReservationRow = props.reservationRow || {
      eventId: props.eventRow.id,
      userId: props.userId,
      isReserved: false,
      isFavourite: false,
      reservedAt: null,
      unreservedAt: null,
    };
    reservationRow.isFavourite = !reservationRow.isFavourite;
    getDataProvider()
      .updateRow(Reservation, reservationRow)
      .then(() => {
        props.setStatus("waiting");
        props.setReservationRow(reservationRow);
      })
      .catch((error) => {
        console.error(error);
        props.setStatus("error");
      });
  }, []);
  return <ActionButton isFavourite={props.reservationRow?.isFavourite} isSpinning={true} />;
};

interface ActionButtonProps {
  setStatus?: (status: Status) => void;
  isFavourite: boolean;
  isSpinning?: boolean;
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  return (
    <div onClick={() => props.setStatus("clicked")}>
      <img src={`/heart-${props.isFavourite ? "closed" : "open"}.svg`} style={{ width: 30 }} />
    </div>
  );
};
