import * as React from "react";
import { useSelector } from "react-redux";
import {
  getConfig,
  InMemoryTextSearch,
  PageProps,
  QueryMulti,
  Section,
  selectUser,
  useDocumentTitle,
} from "packages/gossamer-react-redux";
import { Event, EventRow, Reservation, ReservationRow } from "model";
import { EventTile } from "../event/EventTile";
import { EventTileContainer } from "../event/EventTileContainer";
import { getFilter } from "packages/gossamer-universal";
import { HomeBlock } from "front-end/shared/HomeBlock";
import { MainFilters } from "front-end/event/MainFilters";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";
import { Link } from "react-router-dom";
import { Stack } from "react-bootstrap";

export const Home = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const user = useSelector(selectUser)();
  useDocumentTitle("Home");
  return (
    <>
      <HomeBlock />
      <Stack direction="horizontal" style={{ paddingTop: 10, justifyContent: "space-evenly" }}>
        <Link to="/user/points-calc-medical">Points Calculator</Link>
        <Link to="/event/create">Create an Event</Link>
      </Stack>
      <InnerFrame>
        <Section>
          <QueryMulti
            entity={Reservation}
            queryOptions={{
              where: {
                and: [getFilter("userId", "eq", user.userId), getFilter("unreservedAt", "bl", null)],
              },
            }}
            showItemCount={false}
            showPaginator="never"
            showSettingsControl={false}
            render={(outerProps) => (
              <QueryMulti
                entity={Event}
                queryOptions={{
                  where: {
                    and: [
                      {
                        or: [
                          getFilter("startsAt", "gt", new Date().toISOString()),
                          getFilter("distributionType", "eq", "ON-DEMAND"),
                        ],
                      },
                      {
                        or: [
                          getFilter("visibility", "eq", "ANYONE"),
                          getFilter("organisation", "eq", getConfig("org-path")),
                        ],
                      },
                      getFilter("visibility", "ne", "HIDDEN"),
                    ],
                  },
                }}
                showItemCount={false}
                showPaginator="never"
                showSettingsControl={false}
                render={(middleProps) => (
                  <InMemoryTextSearch
                    {...middleProps}
                    searchFields={["title", "location", "description", "type"]}
                    render={(innerProps) => (
                      <MainFilters
                        rows={innerProps.rows}
                        render={(nanoProps) => (
                          <InMemoryPaginator
                            pageSize={10}
                            rows={nanoProps.rows}
                            render={(picoprops) => (
                              <EventTileContainer>
                                {picoprops.rows.map((row: EventRow) => {
                                  const reservation = outerProps.rows.find(
                                    (res: ReservationRow) => res.eventId === row.id
                                  );
                                  return <EventTile eventRow={row} key={row.id} existingReservationRow={reservation} />;
                                })}
                              </EventTileContainer>
                            )}
                          />
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </Section>
      </InnerFrame>
    </>
  );
};
