import Debug from "debug";
import * as React from "react";
import { useSelector } from "react-redux";
import { FormControl } from "react-bootstrap";
import {
  Field,
  getFieldPrimitiveFromString,
  getFieldStringFromPrimitive,
  isFieldEditable,
  Row,
} from "packages/gossamer-universal";
import { General, selectField, TransRow } from "../index";

const debug = Debug("gfe/fi/TransField");

interface TransFieldProps<T extends Row> {
  className?: string;
  fieldId: string;
  field: Field;
  row: TransRow<T>;
}

export const TransField = <T extends {}>(props: TransFieldProps<T>): JSX.Element => {
  const { currValue, touched, validation } = useSelector(selectField)(
    props.row.getTransId(),
    props.row.getRowNumber(),
    props.fieldId
  );
  const handleBlur = (newValue: string) => {
    debug(`handleBlur(${props.fieldId})`);
    props.row.setFieldTouched(props.fieldId);
  };
  const handleChange = (newValue: string) => {
    const prim = getFieldPrimitiveFromString(props.field.type, newValue);
    debug(`handleChange(${props.fieldId}) to ${newValue} prim: ${prim}`);
    props.row.setFieldValue(props.fieldId, prim);
  };

  const validity = touched ? (!!validation ? false : !!currValue ? true : null) : null;
  debug(
    `${props.fieldId}, val ${currValue}, vis ${props.field.visible}, vln ${validation}, tch ${touched}, vly ${validity}`
  );
  const str = getFieldStringFromPrimitive(props.field.type, currValue);
  return (
    <>
      <General
        className={props.className}
        editable={isFieldEditable(props.field, props.row.getAction())}
        field={props.field}
        fieldId={props.fieldId}
        handleBlur={handleBlur}
        handleChange={handleChange}
        validity={validity}
        value={str}
      />
      {!!validation && <FormControl.Feedback type="invalid">{validation}</FormControl.Feedback>}
    </>
  );
};
