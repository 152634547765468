import Debug from "debug";
import * as React from "react";
import { DecimalField, Field, FieldType, TextField, getFieldStringFromPrimitive } from "packages/gossamer-universal";
import { DynamicText } from "../index";
import styles from "./Uneditable.module.css";

const debug = Debug("gfe/fi/Uneditable");

interface UneditableProps {
  className?: string;
  field: Field;
  value: string;
}

export const Uneditable = (props: UneditableProps): JSX.Element => {
  let display = getFieldStringFromPrimitive(props.field.type, props.value);
  if (props.field.type === FieldType.Date && !!props.value) {
    const d = new Date(props.value);
    display = d.toLocaleDateString();
  }
  if (props.field.type === FieldType.Datetime && !!props.value) {
    const d = new Date(props.value);
    display = d.toLocaleString();
  }
  if (props.field.type === FieldType.Options && props.value !== null) {
    const option = props.field.options.find((opt) => opt.id === String(props.value));
    display = option ? option.label : `[${props.value}: unknown]`;
  }
  if ([FieldType.Decimal, FieldType.Integer].indexOf(props.field.type) > -1) {
    display = parseFloat(props.value).toFixed((props.field as DecimalField).decimalDigits || 0);
    if (display === "NaN") {
      display = "-";
    }
  }
  if (props.field.evaluateFrontEndDisplay) {
    display = props.field.evaluateFrontEndDisplay(props.value);
  }
  if (props.field.staticPrefix) {
    display = props.field.staticPrefix + display;
  }
  if (props.field.staticSuffix) {
    display += props.field.staticSuffix;
  }
  const inner =
    props.field.type === FieldType.Text && (props.field as TextField).isDynamic ? (
      <DynamicText text={display} />
    ) : (
      display
    );

  debug(`${props.field.label} ${props.value} ${display}`);
  const className = styles.RowCell + (props.className ? " " + props.className : "");
  return <div className={className}>{inner}</div>;
};
