import { Entity, FieldType, PersistenceType } from "packages/gossamer-universal";
import { Event } from "./Event";
import { User } from "./User";

export interface ReservationRow {
  eventId: string;
  userId: string;
  isFavourite: boolean;
  isReserved: boolean;
  reservedAt: string;
  unreservedAt: string;
}

export const Reservation: Entity<ReservationRow> = {
  id: "Reservation",
  title: "Reservation",
  service: "main",
  rowLabel: (row: ReservationRow) => `${row.eventId}/${row.userId}`,
  primaryKeyFields: ["eventId", "userId"],
  security: [
    { role: "admin", action: "all", access: "all" },
    { role: "all", action: "read", access: "all" },
  ],
  defaultSort: [
    ["eventId", "ASC"],
    ["userId", "ASC"],
  ],
  defaultFilters: [{ fieldId: "lastName", operator: "ct", value: "" }],
  fields: {
    eventId: {
      seq: 5,
      type: FieldType.Reference,
      label: "Event",
      persistence: PersistenceType.SetOnCreateOnly,
      refEntity: Event,
      mandatory: true,
      listColumn: true,
    },
    userId: {
      seq: 10,
      type: FieldType.Reference,
      label: "User",
      refEntity: User,
      mandatory: true,
      listColumn: true,
    },
    isFavourite: {
      seq: 15,
      label: "Is Favourite",
      type: FieldType.Boolean,
    },
    isReserved: {
      seq: 20,
      label: "Is Reserved",
      type: FieldType.Boolean,
    },
    reservedAt: {
      seq: 25,
      label: "Reserved At",
      type: FieldType.Datetime,
      mandatory: true,
      listColumn: true,
    },
    unreservedAt: {
      seq: 30,
      label: "Unreserved At",
      type: FieldType.Datetime,
      listColumn: true,
    },
  },
};
