import React from "react";
import { Row } from "packages/gossamer-universal";
import { Paginator } from "../index";

interface InMemoryPaginatorProps<T extends Row> {
  pageSize: number;
  render: ({ rows }: { rows: T[] }) => JSX.Element;
  rows: T[] | undefined;
}

export const InMemoryPaginator = <T extends Row>(props: InMemoryPaginatorProps<T>) => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  React.useEffect(() => {
    setPageNumber(1); // reset to 1st page when length changes
  }, [(props.rows || []).length]);
  const Render = props.render;
  const rowsIn = props.rows || [];
  const start = (pageNumber - 1) * props.pageSize;
  const end = Math.min(start + props.pageSize, rowsIn.length);
  const rowsOut = rowsIn.slice(start, end);
  return (
    <>
      <Render rows={rowsOut} />
      <div style={{ display: "flex", flexFlow: "row no-wrap", justifyContent: "space-between", marginTop: 20 }}>
        <div>
          {rowsIn.length === 0
            ? "none"
            : rowsOut.length === 1
            ? "1 item of " + rowsIn.length
            : rowsOut.length + " items of " + rowsIn.length}
        </div>
        <Paginator
          pageNumber={pageNumber}
          pageSize={props.pageSize}
          moveToPage={(newPage) => setPageNumber(newPage)}
          rowsInPage={props.rows?.length}
        />
      </div>
    </>
  );
};
