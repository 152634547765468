import * as React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IsAllowed, PageProps, PageTitle, QueryMultiURLParams, Search, Section } from "packages/gossamer-react-redux";
import { Organisation } from "model";

export const OrganisationSearch = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Search" entity={Organisation} />
      <Section>
        <QueryMultiURLParams entity={Organisation} render={Search} />
      </Section>
      <IsAllowed entity={Organisation} action="create">
        <Link to="/organisation/create">
          <Button>Create</Button>
        </Link>
      </IsAllowed>
    </InnerFrame>
  );
};
