import Debug from "debug";
import { AnyAction, Store } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Entity,
  DataProvider,
  QueryOptions,
  QueryResponseMultipleRows,
  doFrontEndEvaluates,
  QueryResponseSingleRow,
  CompoundPayload,
  getRowKey,
} from "packages/gossamer-universal";
import { FetchJson, getEntity } from "../index";
import {
  clearAllQueriesInGCache,
  clearSingleRecordInGCache,
  GCacheState,
  gCacheSweep,
  getGCacheEntity,
  getQueryFromGCache,
  getRecordFromGCache,
  ReduxGCacheQuery,
  ReduxGCacheRecord,
  selectQueryFromGCache,
  selectRecordFromGCache,
} from "./ReduxGCache";

const debug = Debug("gfe/da/ReduxGCacheDataProvider");

type GCacheStore = Store<{ cache: GCacheState }, AnyAction>;

export const makeGCacheDataProvider = (
  store: GCacheStore,
  fetchJson: FetchJson,
  gCacheSweepIntervalMillis?: number
): DataProvider => {

  const getQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions): Promise<T[]> => {
    throw new Error("not implemented yet");
  }

  const useQuery = <T extends {}>(entity: Entity<T>, queryOptions: QueryOptions): QueryResponseMultipleRows<T> => {
    const queryOptionsStr = JSON.stringify(queryOptions);
    useDispatch()({
      type: getQueryFromGCache.type,
      payload: {
        entity: getGCacheEntity(entity),
        query: queryOptions,
      },
    });
    const cacheItem = useSelector(selectQueryFromGCache)(entity.id, queryOptionsStr) as ReduxGCacheQuery<T>;
    if (cacheItem.data && !cacheItem.frontEndEvaluatesApplied) {
      cacheItem.data = cacheItem.data?.map((row) => doFrontEndEvaluates(entity, row) as T);
      cacheItem.frontEndEvaluatesApplied = true;
    }
    debug(`data: ${cacheItem.data?.length}, ${entity.id}, ${queryOptionsStr}`);
    return cacheItem;
  };

  const getRecord = <T extends {}>(entity: Entity<T>, key: string): Promise<T> => {
    throw new Error("not implemented yet");
  }

  const useRecord = <T extends {}>(entity: Entity<T>, key: string): QueryResponseSingleRow<T> => {
    useDispatch()({
      type: getRecordFromGCache.type,
      payload: {
        entity: getGCacheEntity(entity),
        key,
      },
    });
    const cacheItem = useSelector(selectRecordFromGCache)(entity.id, key) as ReduxGCacheRecord<T>;
    if (cacheItem.data && !cacheItem.frontEndEvaluatesApplied) {
      cacheItem.data = doFrontEndEvaluates(entity, cacheItem.data);
      cacheItem.frontEndEvaluatesApplied = true;
    }
    debug(`data: ${cacheItem.data}, ${entity.id}, ${key}`);
    return cacheItem;
  };

  const createRow = async <T>(entity: Entity<T>, payload: T) => {
    return fetchJson("POST", `/${entity.service}/${entity.id}`, payload).then((resp: any) => {
      resetCache(entity.id);
      return resp;
    });
  };

  const updateRow = async <T>(entity: Entity<T>, payload: T) => {
    const key = getRowKey(entity, payload);
    return fetchJson("PUT", `/${entity.service}/${entity.id}/${encodeURIComponent(key)}`, payload).then((resp: any) => {
      resetCache(entity.id, key);
      return resp;
    });
  };

  const deleteRow = async <T>(entity: Entity<T>, key: string) => {
    return fetchJson("DELETE", `/${entity.service}/${entity.id}/${encodeURIComponent(key)}`).then((resp: any) => {
      resetCache(entity.id);
      return resp;
    });
  };

  const compound = async <T>(compoundURL: string, payload: CompoundPayload) => {
    return fetchJson("POST", compoundURL, payload).then((resp: any) => {
      payload.rows.forEach((row) =>
        resetCache(row.entityId, row.action === "U" ? getRowKey(getEntity(row.entityId), row.data) : undefined)
      );
      return resp;
    });
  };

  const resetCache = (entityId: string, updateKey?: string): void => {
    store.dispatch(
      clearAllQueriesInGCache({
        entityId,
      })
    );
    if (updateKey) {
      store.dispatch(
        clearSingleRecordInGCache({
          entityId,
          key: updateKey,
        })
      );
    }
  };

  if (typeof gCacheSweepIntervalMillis === "number" && gCacheSweepIntervalMillis > 0) {
    setInterval(() => {
      store.dispatch(gCacheSweep({}));
    }, gCacheSweepIntervalMillis);
  }

  return {
    getQuery,
    useQuery,
    getRecord,
    useRecord,
    createRow,
    updateRow,
    deleteRow,
    compound,
  };
};
