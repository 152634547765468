import * as React from "react";
import { Col as BCol, Row as BRow, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Field, getFieldArray, getFieldColumnWidths, isBlank } from "packages/gossamer-universal";
import { ErrorBoundary, General, SubSectionSingleProps } from "../index";
import styles from "./FieldBlock.module.css";

export interface DisplayFieldBlockProps {
  fieldId: string;
  field: Field;
  value: string;
}

export const DisplayFieldBlock = (props: DisplayFieldBlockProps): JSX.Element => (
  <BCol key={props.fieldId} {...getFieldColumnWidths(props.field)}>
    <div className={`${styles.FieldBlock} fieldBlock`}>
      <div>
        {props.field.label}
        {props.field.description && (
          <OverlayTrigger placement="right" overlay={<Tooltip>{props.field.description}</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
              <span ref={ref} {...triggerHandler}>
                &nbsp;&nbsp;ⓘ
              </span>
            )}
          </OverlayTrigger>
        )}
      </div>
      <b>
        <General editable={false} fieldId={props.fieldId} field={props.field} value={props.value} />
      </b>
    </div>
  </BCol>
);

export const Display = <T extends {}>(props: SubSectionSingleProps<T>): JSX.Element => {
  return (
    <ErrorBoundary>
      <BRow className="mb20px">
        {getFieldArray(props.entity)
          .filter(
            ([field, fieldId]: [Field, string]) =>
              field.visible !== false &&
              (!isBlank(props.row[fieldId]) || field.showEvenIfBlank) &&
              (!props.fieldGroup || props.fieldGroup === field.fieldGroup)
          )
          .map(([field, fieldId]: [Field, string]) => (
            <DisplayFieldBlock key={fieldId} fieldId={fieldId} field={field} value={props.row[fieldId]} />
          ))}
      </BRow>
    </ErrorBoundary>
  );
};
