import * as React from "react";
import { NotifyInfo, setNotifySubscriber } from "../index";

const TIMEOUT = 150;
const FLASHES = 40;

const renderNotify = (ntf: NotifyInfo): JSX.Element => {
  const className = `ntf_${ntf[1]}`;
  return <div className={className}>{ntf[0]}</div>;
};

export const DisplayNotify = (): JSX.Element => {
  const [ntf, setNtf] = React.useState<NotifyInfo | null>(null);
  const [emphCount, setEmphCount] = React.useState<number>(FLASHES);

  setNotifySubscriber(setNtf);

  const toggleEmphasis = () => {
    setEmphCount(emphCount - 1);
  };

  React.useEffect(() => {
    let timeout = null;
    if (ntf) {
      if (emphCount === 0) {
        setEmphCount(FLASHES);
        setNtf(null);
      }
      timeout = setTimeout(toggleEmphasis, TIMEOUT);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ntf, emphCount]);

  const className = "ntf" + (emphCount % 2 === 0 ? " ntf_emphasis" : "");

  return <div className={className}>{!!ntf && renderNotify(ntf)}</div>;
};
