import Debug from "debug";
import { FirebaseApp } from "firebase/app";
import {
  and,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  or,
  query,
  QueryConstraint,
  QueryFieldFilterConstraint,
  setDoc,
  where,
  WhereFilterOp,
  QueryCompositeFilterConstraint,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  CompoundPayload,
  ConditionBlock,
  ConditionElement,
  DataProvider,
  doFrontEndEvaluates,
  Entity,
  Filter,
  FilterOperator,
  getRowKey,
  isBlank,
  QueryOptions,
  QueryResponseMultipleRows,
  QueryResponseSingleRow,
  testFilterRow,
} from "packages/gossamer-universal";

const debug = Debug("gcp/Firestore");

const MAP_OPERATOR: Partial<Record<FilterOperator, WhereFilterOp>> = {
  eq: "==",
  ne: "!=",
  lt: "<",
  gt: ">",
};

const mapFilter = (filter: Filter): QueryFieldFilterConstraint | null => {
  if (!filter?.operator) {
    return null;
  }
  const op = MAP_OPERATOR[filter.operator];
  if (filter.fieldId && op && !isBlank(filter.value) && !filter.clientTest) {
    return where(filter.fieldId, op, filter.value);
  }
};

const mapConditionElement = (ce: ConditionElement): QueryCompositeFilterConstraint | QueryFieldFilterConstraint => {
  const cb = ce as ConditionBlock;
  if (!ce) {
    throw new Error(`no condition element`);
  }
  if (cb.and || cb.or) {
    return mapConditionBlock(cb);
  }
  return mapFilter(ce as Filter);
};

const mapConditionBlock = (cb: ConditionBlock): QueryCompositeFilterConstraint | QueryFieldFilterConstraint => {
  if (cb.and) {
    if (cb.and.length > 1) {
      return and(...cb.and.map(mapConditionElement).filter((arg) => !!arg));
    } else {
      return mapFilter(cb.and[0] as Filter);
    }
  } else if (cb.or) {
    if (cb.or.length > 1) {
      return or(...cb.or.map(mapConditionElement).filter((arg) => !!arg));
    } else {
      return mapFilter(cb.or[0] as Filter);
    }
  } else {
    throw new Error(`neither and nor or supplied`);
  }
};

export const mapQuery = (coll: CollectionReference, queryOptions: QueryOptions) => {
  const condition = mapConditionElement(queryOptions.where);
  if (condition?.type === "where") {
    return query(coll, condition, limit(queryOptions.limit || 100));
  } else {
    return query(coll, condition);
  }
};

// TODO - needs to be improved to work with all possible where clause structures
export const postQueryFilter = <T>(rows: T[], queryOptions: QueryOptions): T[] => {
  return rows.filter((row) =>
    (queryOptions.where?.and || []).reduce(
      (prev, curr: Filter) => (prev && !curr.operator) || testFilterRow<T>(curr, row),
      true
    )
  );
};

export const makeFirestoreDataProvider = (app: FirebaseApp): DataProvider => {
  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);

  const save = <T extends {}>(db: Firestore, entity: Entity<T>, payload: T): Promise<void> => {
    return setDoc(doc(db, entity.id, getRowKey(entity, payload)), payload);
  };

  const getQuery = async <T>(entity: Entity<T>, queryOptions: QueryOptions): Promise<T[]> => {
    debug(`getQuery`, entity.id);
    const coll = collection(db, entity.id);
    const q = mapQuery(coll, queryOptions);
    const querySnap = await getDocs(q);
    const data = querySnap.docs.map((doc) => doc.data() as T);
    return postQueryFilter(data, queryOptions);
  };

  const useQuery = <T>(entity: Entity<T>, queryOptions: QueryOptions): QueryResponseMultipleRows<T> => {
    const [data, setData] = useState<T[] | null>(null);
    const [error, setError] = useState<any>(null);
    useEffect(() => {
      setData(null);
      setError(null);
      getQuery(entity, queryOptions)
        .then((rows: T[]) => {
          setData(rows.map((row) => doFrontEndEvaluates(entity, row)));
        })
        .catch((error) => {
          setError(error);
          console.error(error);
        });
    }, [queryOptions]);
    return {
      data,
      error,
      isFetching: !data && !error,
    };
  };

  // for useKeyQuery
  const getRecord = async <T>(entity: Entity<T>, key: string): Promise<T | null> => {
    debug(`getRecord`, entity.id, key);
    if (!key) {
      throw new Error(`no key string supplied: ${entity.id}`);
    }
    const docSnap = await getDoc(doc(db, entity.id, key));
    return docSnap.exists() ? (docSnap.data() as T) : null;
  };

  const useRecord = <T>(entity: Entity<T>, key: string): QueryResponseSingleRow<T> => {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<any>(null);
    useEffect(() => {
      setData(null);
      setError(null);
      getRecord(entity, key)
        .then((row) => {
          setData(doFrontEndEvaluates(entity, row));
        })
        .catch((error) => {
          setError(error);
          console.error(error);
        });
    }, [key]);
    return { data, error, isFetching: !data && !error };
  };

  const createRow = async <T>(entity: Entity<T>, row: T) => {
    const key = getRowKey(entity, row);
    await setDoc(doc(db, entity.id, key), row);
  };

  const updateRow = async <T>(entity: Entity<T>, row: T) => {
    const key = getRowKey(entity, row);
    await setDoc(doc(db, entity.id, key), row);
  };

  const deleteRow = async <T>(entity: Entity<T>, key: string) => {
    await deleteDoc(doc(db, entity.id, key));
  };

  const compound = (compoundURL: string, payload: CompoundPayload) => {
    throw new Error("unsupported");
  };

  return {
    getQuery,
    useQuery,
    getRecord,
    useRecord,
    createRow,
    updateRow,
    deleteRow,
    compound,
  };
};
