import { deepFreezeEntities, Entity } from "packages/gossamer-universal";

const ENTITIES: Record<string, Entity<{}>> = {};

export const addEntities = (newEntities: Entity<{}>[]) => {
  newEntities.forEach((entity) => {
    if (ENTITIES[entity.id]) {
      throw new Error(`entity ${entity.id} is already present`);
    }
    ENTITIES[entity.id] = entity;
  });
  deepFreezeEntities(newEntities);
};

export const getEntity = (entityId: string) => {
  if (Object.keys(ENTITIES).length === 0) {
    throw new Error(`no entities added`);
  }
  const entity = ENTITIES[entityId];
  if (!entity) {
    throw new Error(`entity not recognized: ${entityId}`);
  }
  return entity;
};

export const mapEntities = <T, R>(callback: (entity: Entity<T>) => R): R[] => {
  if (Object.keys(ENTITIES).length === 0) {
    throw new Error(`no entities added`);
  }
  return Object.values(ENTITIES).map(callback);
};
