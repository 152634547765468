import * as React from "react";
import { Entity, getTitlePlural, Row } from "packages/gossamer-universal";
import { QuerySingle, useDocumentTitle } from "../index";

type Verb = "Create" | "Delete" | "Display" | "Search" | "Update";

interface PageTitleProps<T extends Row> {
  entity?: Entity<T>;
  hide?: boolean;
  keyValue?: string;
  subtitle?: string;
  title?: string;
  verb?: Verb;
}

interface PageTitleInnerProps<T extends Row> extends PageTitleProps<T> {
  row?: T;
}

const PageTitleInner = <T extends {}>(props: PageTitleInnerProps<T>): JSX.Element => {
  let title = props.verb || props.title;
  if (props.entity) {
    title = props.row
      ? `${title} ${props.entity.title}: ${(props.entity.frontEndOptions?.rowLabelPageTitle || props.entity.rowLabel)(
          props.row
        )}`
      : props.verb === "Create"
      ? `Create a new ${props.entity.title}`
      : props.verb === "Search"
      ? `Search for ${getTitlePlural(props.entity)}`
      : `${title} this ${props.entity.title}`;
  }
  useDocumentTitle(title);
  return props.hide ? null : <h1>{title}</h1>;
};

export const PageTitle = <T extends {}>(props: PageTitleProps<T>): JSX.Element => {
  if (!props.title && !props.verb) {
    throw new Error(`verb or title must be provided as prop`);
  }
  if (!!props.title && !!props.verb) {
    throw new Error(`verb and title must not both be provided as prop`);
  }
  if (props.keyValue && !props.entity) {
    throw new Error(`if keyValue is provided as prop then so must entity`);
  }
  return (
    <div className="mb20px">
      <QuerySingle
        entity={props.entity}
        keyValue={props.keyValue}
        render={(subprops) => <PageTitleInner {...props} {...subprops} />}
        renderBlank={() => <PageTitleInner {...props} />}
        renderNotFound={() => <PageTitleInner {...props} />}
      />
      {props.subtitle && <p className="small">{props.subtitle}</p>}
    </div>
  );
};
