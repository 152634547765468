import * as React from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Reservation, User } from "model";
import {
  Display,
  IsAllowed,
  List,
  PageProps,
  PageTitle,
  QueryMulti,
  QuerySingle,
  Section,
} from "packages/gossamer-react-redux";
import { getFilter } from "packages/gossamer-universal";

export const UserDisplay = (props: PageProps): JSX.Element => {
  const params = useParams();
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Display" entity={User} keyValue={params.key} subtitle="Details relating to a User" />

      <Section>
        <QuerySingle entity={User} keyValue={params.key} render={Display} />
        <IsAllowed entity={User} action="update">
          <Link to={`/user/update/${params.key}`}>
            <Button>Update</Button>
          </Link>
        </IsAllowed>
      </Section>

      <Section title="Reservations" subtitle="Events for which this User has made a reservation">
        <QueryMulti
          entity={Reservation}
          alterEntity={(localEntity: typeof Reservation) => {
            localEntity.fields.userId.listColumn = false;
          }}
          queryOptions={{
            where: {
              and: [getFilter("userId", "eq", params.key)],
            },
          }}
          render={List}
        />
      </Section>
    </InnerFrame>
  );
};
