import Debug from "debug";
import * as React from "react";
import { FormCheck } from "react-bootstrap";
import { BooleanField } from "packages/gossamer-universal";
import { FormControl } from "../index";

const debug = Debug("gfe/fi/Boolean");

export interface BooleanProps {
  editable: boolean;
  field: BooleanField;
  handleChange?: (newValue: string) => void;
  validity?: boolean;
  value: string;
}

const BooleanUneditable = (props: BooleanProps): JSX.Element => {
  return <span>{!!props.value ? "yes" : "no"}</span>;
};

const BooleanEditable = (props: BooleanProps): JSX.Element => {
  const ref = React.useRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(event.target.checked);
    props.handleChange(event.target.checked ? "true" : "false");
  };
  debug(`${props.value}, ${typeof props.value}`);
  return (
    <FormControl /* validity={props.validity} not clear whether validity is relevant */>
      <FormCheck type="switch" ref={ref} checked={props.value === "true"} onChange={handleChange} />
    </FormControl>
  );
};

export const Boolean = (props: BooleanProps): JSX.Element => {
  return (
    <>
      {props.editable && <BooleanEditable {...props} />}
      {!props.editable && <BooleanUneditable {...props} />}
    </>
  );
};
