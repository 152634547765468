import { Field, FieldType } from "../types/entity";
import { ColumnAlign, ColumnWidths } from "../types/ui";

const COLUMN_ALIGNS = {
  [FieldType.Decimal]: "right",
  [FieldType.Integer]: "right",
};
export const getFieldColumnAlign = (field: Field): ColumnAlign =>
  field.listColumnAlign || COLUMN_ALIGNS[field.type] || "left";

export const getFieldColumnStyle = (field: Field, row: any): any /* React.CSSProperties */ =>
  field.listCellStyle ? field.listCellStyle(row) : {};

const COLUMN_WIDTHS = {
  [FieldType.Richtext]: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 6,
    xxl: 6,
  },
};

export const getFieldColumnWidths = (field: Field): Partial<ColumnWidths> => {
  if (field.formColumnWidths) {
    return field.formColumnWidths;
  }
  return getFieldTypeColumnWidths(field.type);
};

export const getFieldTypeColumnWidths = (fieldType: FieldType): ColumnWidths => {
  if (COLUMN_WIDTHS[fieldType]) {
    return COLUMN_WIDTHS[fieldType];
  }
  return {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 3,
    xxl: 3,
  };
};

export const getListRowLink = (listRowLink: string | null | undefined, key: string | null | undefined) => {
  if (!listRowLink) {
    throw new Error(`no listRowLink supplied`);
  }
  if (!key) {
    throw new Error(`no key supplied`);
  }
  return listRowLink.replace("{key}", encodeURIComponent(key));
};

export const getSectionColumnWidths = (columnWidths?: ColumnWidths): ColumnWidths => {
  if (columnWidths) {
    return columnWidths;
  }
  return {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
  };
};

export const getTooltipHoverText = (field: Field, row: any): string =>
  field.tooltipText ? field.tooltipText(row) : "";
