import Debug from "debug";
import { Middleware } from "redux";
import { setFieldValue } from "packages/gossamer-react-redux";
import { Entity, getFieldArray, OptionsField } from "packages/gossamer-universal";
import { PointsOption, UserRow } from "model";

const debug = Debug("UserMiddleware");

export const userMiddleware: Middleware = (store) => (next) => (action) => {
  // debug(`entering userMiddleware`);
  const out = next(action);
  try {
    if (action.type === setFieldValue.type) {
      const entity = action.payload.row.getEntity() as Entity<UserRow>;
      if (
        entity.id === "User" &&
        entity.fields[action.payload.fieldId].fieldGroup !== "main" &&
        ["points", "medicalPoints", "surgicalPoints"].indexOf(action.payload.fieldId) === -1
      ) {
        debug(action.payload.fieldId);
        let medical = 0;
        let surgical = 0;
        let total = 0;
        getFieldArray(entity).forEach(([field, fieldId]) => {
          const value = action.payload.row.getFieldValue(fieldId);
          const points =
            ((field as OptionsField).options?.find((opt) => opt.id === value) as PointsOption)?.points || 0;
          total += points;
          if (field.fieldGroup === "medical") {
            medical += points;
          } else {
            surgical += points;
          }
        });
        debug(`setting points to`, total);
        action.payload.row.setFieldValue("points", total);
        action.payload.row.setFieldValue("medicalPoints", medical);
        action.payload.row.setFieldValue("surgicalPoints", surgical);
      }
    }
  } catch (e) {
    console.error(e);
  }
  // debug(`exiting userMiddleware`);
  return out;
};
