import {
  Entity,
  FieldType,
  FrontEndOptions,
  getUuid,
  OptionsField,
  PersistenceType,
  SecurityContext,
} from "packages/gossamer-universal";
import { Organisation } from "./Organisation";
import { User } from "./User";
import { toLocaleDateTimeToMinutes } from "front-end/shared/Helpers";
import { gradeOptions } from "./SharedOptions";

export interface EventRow {
  id: string;
  title: string;
  type: string;
  image: string;
  specialism: string;
  grade: string;
  distributionType: string;
  location: string;
  startsAt: string;
  endsAt: string;
  price: string;
  summaryColumn: string;
  organiser: string;
  organisation: string;
  visibility: string;
  bookingMethod: string;
  description: string;
  capacity: number;
  reserved: number;
  bookingUrl: string;
  createdAt: string;

  // conference only
  eventScale: string;
  allowsOralPresentation: boolean;
  allowsPosterPresentation: boolean;
  clinicalAuditPresentations: string;

  // course only
  teachingProgramme: boolean;
  teachingProgrammeDuration: string;
  acceptCoTutorOffers: boolean;
  teachingQualification: string;

  leadershipAndManagement: string;
}

export const Event: Entity<EventRow> = {
  id: "Event",
  title: "Event",
  service: "main",
  rowLabel: (row: EventRow) => row.title,
  primaryKeyFields: ["id"],
  security: [
    { role: "admin", action: "all", access: "all" },
    {
      role: "all",
      action: "all",
      access: "test",
      test: (row: EventRow, sctx: SecurityContext) => {
        const out = row.organiser === sctx.userId;
        console.log(`inside event security rule`, row.organiser, sctx.userId, out);
        return out;
      },
    },
    { role: "all", action: "read", access: "all" },
  ],
  defaultSort: [["title", "ASC"]],
  defaultFilters: [{ fieldId: "title", operator: "ct", value: "" }],
  frontEndOptions: {
    listRowLink: "/event/display/{key}",
  } as FrontEndOptions<EventRow>,
  backEndOptions: {
    type: "table",
  },
  fields: {
    id: {
      seq: 5,
      type: FieldType.Text,
      label: "Id",
      persistence: PersistenceType.SetOnCreateOnly,
      mandatory: true,
      visible: false,
      evaluateFrontEndCreate: () => getUuid(),
    },
    title: {
      seq: 10,
      type: FieldType.Text,
      label: "Title",
      mandatory: true,
      listColumn: true,
      maxLength: 80,
    },
    type: {
      seq: 15,
      label: "Event Type",
      type: FieldType.Options,
      mandatory: true,
      options: [
        {
          id: "COURSE",
          label: "Course",
        },
        {
          id: "SURGICAL-CONF",
          label: "Surgical Conference",
        },
        {
          id: "CONFERENCE",
          label: "Non-Surgical Conference",
        },
        {
          id: "TEACHING-SESSION",
          label: "Teaching Session/Seminar",
        },
      ],
    },
    specialism: {
      seq: 17,
      label: "Specialty",
      type: FieldType.Options,
      options: [
        { id: "ALLERGY-AND-IMMUNOLOGY", label: "Allergy and Immunology" },
        { id: "ANAESTHETICS", label: "Anaesthetics" },
        { id: "CAREER-DEVELOPMENT", label: "Career Development" },
        { id: "DERMATOLOGY", label: "Dermatology" },
        { id: "EMERGENCY-MEDICINE", label: "Emergency Medicine" },
        { id: "EXTREME-MEDICINE", label: "Extreme Medicine" },
        { id: "FAMILY-MEDICINE-OR-GENERAL-PRACTISE", label: "Family Medicine OR General Practise" },
        { id: "INTERNAL-MEDICINE", label: "Internal Medicine" },
        { id: "MEDICAL-GENETICS", label: "Medical Genetics" },
        { id: "NEUROLOGY", label: "Neurology" },
        { id: "NUCLEAR-MEDICINE", label: "Nuclear Medicine" },
        { id: "OBSTETRICS-AND-GYNECOLOGY", label: "Obstetrics and Gynecology" },
        { id: "ONCOLOGY", label: "Oncology" },
        { id: "OPHTHALMOLOGY", label: "Ophthalmology" },
        { id: "PAEDIATRICS", label: "Paediatrics" },
        { id: "PATHOLOGY", label: "Pathology" },
        { id: "PHYSICAL-MEDICINE-AND-REHABILITATION", label: "Physical Medicine and Rehabilitation" },
        { id: "PSYCHIATRY", label: "Psychiatry" },
        { id: "RADIOLOGY", label: "Radiology" },
        { id: "SURGERY", label: "Surgery" },
        { id: "UROLOGY", label: "Urology" },
      ],
    },
    grade: {
      seq: 17,
      label: "Grade",
      type: FieldType.Options,
      options: gradeOptions,
    },
    distributionType: {
      seq: 18,
      label: "Hosting Type",
      type: FieldType.Options,
      mandatory: true,
      options: [
        { id: "IN-PERSON", label: "in person" },
        { id: "ONLINE", label: "online" },
        { id: "HYBRID", label: "hybrid" },
        { id: "ON-DEMAND", label: "on demand" },
      ],
    },
    location: {
      seq: 25,
      type: FieldType.Text,
      label: "Location",
      maxLength: 80,
    },
    startsAt: {
      seq: 30,
      type: FieldType.Datetime,
      label: "Starts At",
      evaluateFrontEndDisplay: (val: string) => toLocaleDateTimeToMinutes(val),
    },
    endsAt: {
      seq: 35,
      type: FieldType.Datetime,
      label: "Ends At",
      evaluateFrontEndDisplay: (val: string) => toLocaleDateTimeToMinutes(val),
    },
    price: {
      seq: 40,
      type: FieldType.Text,
      label: "Price",
      staticPrefix: "£",
      regex: "^[-0-9 \\.,]*$",
    },
    summaryColumn: {
      seq: 42,
      type: FieldType.Text,
      label: "Details",
      visible: false,
      listColumn: true,
      evaluateFrontEndIn: (row: EventRow) =>
        [
          (Event.fields.distributionType as OptionsField).options.find((opt) => opt.id === row.distributionType)?.label,
          row.location,
          toLocaleDateTimeToMinutes(row.startsAt),
          Event.fields.price.staticPrefix + row.price,
        ]
          .filter((part) => !!part)
          .join(", "),
    },
    organiser: {
      seq: 45,
      type: FieldType.Reference,
      label: "Organiser",
      refEntity: User,
      persistence: PersistenceType.FrontEndGenerated,
    },
    organisation: {
      seq: 50,
      type: FieldType.Reference,
      label: "Organisation",
      fieldGroup: "main",
      refEntity: Organisation,
      persistence: PersistenceType.FrontEndGenerated,
    },
    visibility: {
      seq: 55,
      type: FieldType.Options,
      label: "Visibility",
      mandatory: true,
      fieldGroup: "main",
      description: "Who is this event shown to and who can book a place?",
      options: [
        {
          id: "ANYONE",
          label: "anyone",
        },
        {
          id: "THIS-ORG-ONLY",
          label: "this organisation only",
        },
        {
          id: "HIDDEN",
          label: "hidden",
        },
      ],
    },
    bookingMethod: {
      seq: 60,
      type: FieldType.Options,
      label: "Booking Method",
      mandatory: true,
      fieldGroup: "main",
      description: "How would you like to manage booking for this event?",
      options: [
        {
          id: "IN-CAREERBOOK",
          label: "in CareerBook - users will book here directly and you'll see a list of those people",
        },
        {
          id: "IN-OTHER-SYSTEM",
          label: "in another system - you'll need to provide the URL of the event so we can redirect users",
        },
        {
          id: "BY-EMAIL",
          label: "by email - users will send you an email to request a booking",
        },
      ],
    },
    capacity: {
      seq: 65,
      type: FieldType.Integer,
      label: "Capacity",
      description: "maximum number of people who can attend this event",
      min: 1,
    },
    reserved: {
      seq: 70,
      type: FieldType.Integer,
      label: "Reserved",
      persistence: PersistenceType.BackEndManaged,
      description: "number of people who have reserved places at this event so far",
    },
    bookingUrl: {
      seq: 75,
      type: FieldType.Text,
      label: "Booking URL - where to redirect users who want to book",
    },
    description: {
      seq: 80,
      type: FieldType.Richtext,
      label: "Description",
    },
    image: {
      seq: 85,
      type: FieldType.Image,
      label: "Image",
    },
    createdAt: {
      seq: 90,
      type: FieldType.Datetime,
      label: "Created At",
      persistence: PersistenceType.BackEndManaged,
      evaluateFrontEndCreate: () => new Date().toISOString(),
      evaluateFrontEndDisplay: (val: string) => toLocaleDateTimeToMinutes(val),
    },
    eventScale: {
      seq: 100,
      label: "Event Scale",
      type: FieldType.Options,
      options: [
        {
          id: "NATIONAL",
          label: "National / International",
        },
        {
          id: "REGIONAL",
          label: "Regional",
        },
        {
          id: "LOCAL",
          label: "Local",
        },
      ],
    },
    allowsOralPresentation: {
      seq: 105,
      label: "Allows Oral Presentation?",
      type: FieldType.Boolean,
    },
    allowsPosterPresentation: {
      seq: 110,
      label: "Allows Poster Presentation?",
      type: FieldType.Boolean,
    },
    clinicalAuditPresentations: {
      seq: 115,
      label:
        "Does this conference allow for clinical audit presentations (Attendees will receive points subject to events T&Cs and acceptance)?",
      type: FieldType.Options,
      options: [
        {
          id: "NATIONAL",
          label: "Yes, at a national or international level",
        },
        {
          id: "REGIONAL",
          label: "Yes, at a regional level",
        },
        {
          id: "LOCAL",
          label: "Yes, at a local level",
        },
        {
          id: "NONE",
          label: "No",
        },
      ],
    },
    teachingProgramme: {
      seq: 200,
      label: "Does this Course represent a teaching programme for healthcare professionals or medical students?",
      type: FieldType.Boolean,
    },
    teachingProgrammeDuration: {
      seq: 205,
      label: "What is the duration of this teaching programme?",
      type: FieldType.Options,
      options: [
        {
          id: "MORE-THAN-3-MONTHS",
          label: "more than three months",
        },
        {
          id: "LESS-THAN-3-MONTHS",
          label: "less than three months",
        },
        {
          id: "OCCASIONAL",
          label: "occasional teaching",
        },
        {
          id: "NA",
          label: "n/a",
        },
      ],
    },
    acceptCoTutorOffers: {
      seq: 210,
      label:
        "Would you like to receive offers from others looking to co-tutor/organise the teaching programme (points will be earned for yourself and for co-tutor)?",
      type: FieldType.Boolean,
    },
    teachingQualification: {
      seq: 220,
      label: "Does this Course offer a teaching qualification?",
      type: FieldType.Options,
      options: [
        {
          id: "MASTERS",
          label: "Yes, Masters",
        },
        {
          id: "HIGHER",
          label: "Yes, PGDip or PGCert",
        },
        {
          id: "OTHER",
          label: "Yes, other",
        },
        {
          id: "NONE",
          label: "No",
        },
      ],
    },
    leadershipAndManagement: {
      seq: 225,
      label: "Is this event part of a leadership or managerial role?",
      type: FieldType.Options,
      options: [
        {
          id: "REGIONAL",
          label: "Yes, at a national or regional level",
        },
        {
          id: "LOCAL",
          label: "Yes, at a local level",
        },
        {
          id: "NO",
          label: "No",
        },
      ],
    },
  },
};
