import Debug from "debug";
import * as React from "react";
import { getRowKey, Row } from "packages/gossamer-universal";
import { Display, ErrorBoundary, SubSectionMultiProps, SubSectionSingleProps } from "../index";

const debug = Debug("gfe/se/BlockSequence");

interface BlockSequenceProps<T extends Row> extends SubSectionMultiProps<T> {
  fieldGroup?: string;
  subRender?: (props: SubSectionSingleProps<T>) => JSX.Element;
}

export const BlockSequence = <T extends {}>(props: BlockSequenceProps<T>): JSX.Element => {
  const SubSection = props.subRender || Display;

  const children = props.rows
    .filter((row) => !props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1)
    .map((row: any) => {
      const key = getRowKey(props.entity, row);
      debug(`row ${key} ${Object.keys(row)}`);
      return (
        <ErrorBoundary key={key}>
          <SubSection entity={props.entity} fieldGroup={props.fieldGroup} keyValue={key} row={row} />
        </ErrorBoundary>
      );
    });

  if (props.rows.length === 0) {
    return null;
  }
  return <ErrorBoundary>{children}</ErrorBoundary>;
};
