import * as React from "react";
import { Button, FormCheck, Modal, Spinner } from "react-bootstrap";
import {
  cloneEntity,
  Entity,
  FieldId,
  getFieldArray,
  PersistenceType,
  QueryOptions,
  Row,
} from "packages/gossamer-universal";
import { changePageSize } from "../index";

interface ListSettingsProps<T extends Row> {
  entity: Entity<T>;
  handleCopyDataToClipboard?: (includeHeader: boolean) => Promise<void>;
  queryOptions: QueryOptions;
  updateEntity: (newLocalEntity: Entity<T>) => void;
  updateQueryOptions: (newQueryOptions: QueryOptions) => void;
}

export const ListSettings = <T extends {}>(props: ListSettingsProps<T>): JSX.Element => {
  const [show, setShow] = React.useState(false);
  const [copyingToClipboard, setCopyingToClipboard] = React.useState(false);
  const [includeHeader, setIncludeHeader] = React.useState(false);

  const onRowsPerPageChange = (newPageSize: number) => {
    props.updateQueryOptions(changePageSize(props.queryOptions, newPageSize));
  };

  const RowsPerPageOption = (subprops: { option: number }): JSX.Element => (
    <FormCheck
      type="radio"
      name="rowsPerPage"
      inline
      label={String(subprops.option)}
      checked={subprops.option === props.queryOptions.limit}
      onChange={() => onRowsPerPageChange(subprops.option)}
    />
  );

  const onColumnShowToggle = (fieldId: FieldId) => {
    const newLocalEntity = cloneEntity(props.entity);
    newLocalEntity.fields[fieldId].listColumn = !newLocalEntity.fields[fieldId].listColumn;
    props.updateEntity(newLocalEntity);
  };

  const onCopyToClipboardClicked = async () => {
    setCopyingToClipboard(true);
    await props.handleCopyDataToClipboard(includeHeader);
    setCopyingToClipboard(false);
  };

  return (
    <>
      <button
        className="ControlIcon"
        onClick={() => setShow(true)}
        style={{
          fontSize: 24,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        ⚙
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="list-settings-modal-title"
        style={{
          userSelect: "none",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="list-settings-modal-title">{props.entity.title} List Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>Rows per Page:</span>&nbsp;&nbsp;
            <RowsPerPageOption option={10} />
            <RowsPerPageOption option={20} />
            <RowsPerPageOption option={50} />
            <RowsPerPageOption option={100} />
            <RowsPerPageOption option={500} />
          </div>
          <div>Columns to Show:</div>
          {getFieldArray(props.entity)
            .filter(
              ([field, _]) =>
                [PersistenceType.BackEndOnly, PersistenceType.FrontEndGenerated].indexOf(field.persistence) === -1
            )
            .map(([field, fieldId]) => {
              return (
                <FormCheck
                  type="switch"
                  id={"listSettings-" + fieldId}
                  key={fieldId}
                  name={fieldId}
                  label={field.label}
                  checked={!!field.listColumn}
                  onChange={() => {
                    onColumnShowToggle(fieldId);
                  }}
                />
              );
            })}
          {props.handleCopyDataToClipboard && (
            <>
              <br />
              <div>
                <Button onClick={() => onCopyToClipboardClicked()}>
                  {copyingToClipboard && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  )}
                  {!copyingToClipboard && "Copy data as CSV to clipboard"}
                </Button>
              </div>
              <br />
              <div>
                <FormCheck
                  id="includeHeaderRow"
                  type="switch"
                  label="Include header row?"
                  checked={includeHeader}
                  onChange={() => {
                    setIncludeHeader(!includeHeader);
                  }}
                />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
