import * as React from "react";

interface EventTileContainerProps {
  children: JSX.Element[];
}

export const EventTileContainer = (props: EventTileContainerProps): JSX.Element => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 20,
      justifyContent: "center",
    }}
  >
    {props.children}
  </div>
);
