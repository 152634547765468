import Debug from "debug";
import { Middleware } from "redux";
import { getConfig, makeRow, setFieldValue, TransRow } from "packages/gossamer-react-redux";
import { Event, EventRow } from "model";
import { FieldType, OptionsField } from "packages/gossamer-universal";

const debug = Debug("EventMiddleware");

const CONF_ONLY_FIELDS = [
  "eventScale",
  "allowsOralPresentation",
  "allowsPosterPresentation",
  "clinicalAuditPresentations",
];
const COURSE_TYPES = ["COURSE", "TEACHING-SESSION"];
const COURSE_ONLY_FIELDS = ["teachingProgramme", "teachingQualification"];
const TEACHING_FIELDS = ["teachingProgrammeDuration", "acceptCoTutorOffers"];

const setEventType = (entity: typeof Event, row: TransRow<EventRow>) => {
  const eventType = row.getFieldValue("type") as string;
  const isCourse = COURSE_TYPES.indexOf(eventType) > -1;
  CONF_ONLY_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = !isCourse;
    entity.fields[fieldId].mandatory = !isCourse && entity.fields[fieldId].type !== FieldType.Boolean;
    if (isCourse) {
      row.setFieldValue(fieldId, "");
    }
  });
  COURSE_ONLY_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = isCourse;
    if (!isCourse) {
      row.setFieldValue(fieldId, "");
    }
  });
  (entity.fields.distributionType as OptionsField).options.find((opt) => opt.id === "ON-DEMAND").active =
    eventType === "COURSE";
  if (eventType === "COURSE" && row.getFieldValue("distributionType") === "ON-DEMAND") {
    row.setFieldValue("distributionType", "");
  }
};

const setDistributionType = (entity: typeof Event, row: TransRow<EventRow>) => {
  const distributionType = row.getFieldValue("distributionType") as string;
  entity.fields.location.editable = ["IN-PERSON", "HYBRID"].indexOf(distributionType) > -1;
  entity.fields.location.mandatory = ["IN-PERSON", "HYBRID"].indexOf(distributionType) > -1;
  entity.fields.startsAt.editable = distributionType !== "ON-DEMAND";
  entity.fields.startsAt.mandatory = distributionType !== "ON-DEMAND";
  entity.fields.endsAt.editable = distributionType !== "ON-DEMAND";
  if (["IN-PERSON", "HYBRID"].indexOf(distributionType) === -1) {
    row.setFieldValue("location", "");
  }
  if (distributionType === "ON-DEMAND") {
    row.setFieldValue("startsAt", "");
    row.setFieldValue("endsAt", "");
  }
};

const setBookingMethod = (entity: typeof Event, row: TransRow<EventRow>) => {
  const bookingMethod = row.getFieldValue("bookingMethod") as string;
  entity.fields.capacity.editable = bookingMethod === "IN-CAREERBOOK";
  entity.fields.capacity.mandatory = bookingMethod === "IN-CAREERBOOK";
  entity.fields.bookingUrl.editable = bookingMethod === "IN-OTHER-SYSTEM";
  entity.fields.bookingUrl.mandatory = bookingMethod === "IN-OTHER-SYSTEM";
  if (bookingMethod !== "IN-CAREERBOOK") {
    row.setFieldValue("capacity", "");
  }
  if (bookingMethod !== "IN-OTHER-SYSTEM") {
    row.setFieldValue("bookingUrl", "");
  }
};

const setTeachingProgramme = (entity: typeof Event, row: TransRow<EventRow>) => {
  const teaching = row.getFieldValue("teachingProgramme") as boolean;
  TEACHING_FIELDS.forEach((fieldId) => {
    entity.fields[fieldId].editable = teaching;
    entity.fields[fieldId].mandatory = teaching && entity.fields[fieldId].type !== FieldType.Boolean;
    if (!teaching) {
      row.setFieldValue(fieldId, "");
    }
  });
};

export const eventMiddleware: Middleware = (store) => (next) => (action) => {
  debug(`entering eventMiddleware`);
  const out = next(action);
  try {
    if (action.type === makeRow.type) {
      const entity = action.payload.row.getEntity() as typeof Event;
      if (entity.id === Event.id) {
        const userId = getConfig("user-id");
        // set organiser and organsation
        if (!action.payload.row.getFieldValue("organiser")) {
          debug(`setting event organiser to`, userId);
          action.payload.row.setFieldValue("organiser", userId);
          action.payload.row.setFieldValue("organisation", getConfig("org-path"));
        }
        setEventType(entity, action.payload.row);
        setDistributionType(entity, action.payload.row);
        setBookingMethod(entity, action.payload.row);
        setTeachingProgramme(entity, action.payload.row);
      }
    }
    if (action.type === setFieldValue.type) {
      const entity = action.payload.row.getEntity() as typeof Event;
      if (entity.id === Event.id && action.payload.fieldId === "type") {
        setEventType(entity, action.payload.row);
      }
      if (entity.id === Event.id && action.payload.fieldId === "distributionType") {
        setDistributionType(entity, action.payload.row);
      }
      if (entity.id === Event.id && action.payload.fieldId === "bookingMethod") {
        setBookingMethod(entity, action.payload.row);
      }
      if (entity.id === Event.id && action.payload.fieldId === "teachingProgramme") {
        setTeachingProgramme(entity, action.payload.row);
      }
      action.payload.row.validate();
    }
  } catch (e) {
    console.error(e);
  }
  debug(`exiting eventMiddleware`);
  return out;
};
