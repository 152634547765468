import * as React from "react";
import { PageProps, useDocumentTitle } from "../index";

export const Four04 = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  useDocumentTitle("Not Found");
  return (
    <InnerFrame localMenu={() => null}>
      <div>Not Found</div>
    </InnerFrame>
  );
};
