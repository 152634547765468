import { EventRow, Organisation } from "model";
import { useRecord } from "packages/gossamer-react-redux";
import { filePartsGet } from "packages/gossamer-universal";

export const useGetEventImageSrc = (eventRow: EventRow) => {
  const eventImgParts = filePartsGet(eventRow.image);
  const organisation = useRecord(Organisation, eventRow.organisation);
  const orgImgParts = filePartsGet(organisation?.data?.image);
  return eventImgParts.mimetype
    ? `data:${eventImgParts.mimetype};base64, ${eventImgParts.content}`
    : orgImgParts.mimetype
    ? `data:${orgImgParts.mimetype};base64, ${orgImgParts.content}`
    : eventRow.type === "COURSE"
    ? "/course.png"
    : "/conference.jpg";
};
