import { Entity, FieldType, getUuid, PersistenceType } from "packages/gossamer-universal";

export interface ErrorRow {
  id: string;
  recordedAt: string;
  title: string;
  content: string;
}

export const Error: Entity<ErrorRow> = {
  id: "Error",
  title: "Error",
  service: "main",
  rowLabel: (row: ErrorRow) => row.title,
  primaryKeyFields: ["id"],
  security: [{ role: "admin", action: "all", access: "all" }],
  defaultSort: [["title", "ASC"]],
  defaultFilters: [{ fieldId: "title", operator: "ct", value: "" }],
  fields: {
    id: {
      seq: 5,
      type: FieldType.Text,
      label: "Id",
      persistence: PersistenceType.FrontEndGenerated,
      mandatory: true,
      visible: false,
      evaluateFrontEndCreate: () => getUuid(),
    },
    recordedAt: {
      seq: 10,
      type: FieldType.Datetime,
      label: "Recorded At",
      listColumn: true,
    },
    title: {
      seq: 15,
      type: FieldType.Text,
      label: "Title",
      mandatory: true,
      listColumn: true,
      maxLength: 80,
    },
    content: {
      seq: 20,
      type: FieldType.Text,
      label: "Content",
      listColumn: true,
    },
  },
};
