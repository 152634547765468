import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getConfig, useRecord } from "packages/gossamer-react-redux";
import { EventRow, User, UserRow } from "model";
import { getPointsUserSummary } from "../shared/Points";

export interface NetPointsProps {
  eventRow: EventRow;
}

export const NetPoints = (props: NetPointsProps): JSX.Element => {
  const userId = getConfig("user-id");
  if (userId) {
    return <NetPointsGet eventRow={props.eventRow} userId={userId} />;
  }
  return <NetPointsInner eventRow={props.eventRow} />;
};

interface NetPointsGetProps extends NetPointsProps {
  userId: string;
}

export const NetPointsGet = (props: NetPointsGetProps): JSX.Element => {
  const { data, isFetching, error } = useRecord(User, props.userId);
  if (isFetching) {
    return <div>Loading...</div>;
  } else if (!!data && !error) {
    return <NetPointsInner eventRow={props.eventRow} userRow={data} />;
  } else {
    return <div>an error occurred</div>;
  }
};

interface NetPointsInnerProps extends NetPointsProps {
  userRow?: UserRow;
}

const NetPointsInner = (props: NetPointsInnerProps): JSX.Element => {
  const { netPoints, text } = getPointsUserSummary(props.eventRow, props.userRow);
  return (
    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{text}</Tooltip>}>
      <b>
        {netPoints} {props.userRow ? "" : "max"}
      </b>
    </OverlayTrigger>
  );
};
