import * as React from "react";
import { PageProps, Section, useDocumentTitle } from "packages/gossamer-react-redux";

export const About = (props: PageProps): JSX.Element => {
  useDocumentTitle("About Careerbook");
  return (
    <div
      style={{
        padding: "100px 40px",
      }}
    >
      <Section title="About Careerbook">
        <p>
          Careerbook is your all in one booking platform for discovering, booking, and creating medical courses and
          conferences.
        </p>
        <p>It was started by James Wallner, Doctor in Paediatric and Adult Emergency Medicine.</p>
        <h2>Our Mission</h2>
        <p>
          Our mission is to centralise the booking and organisation of medical courses, conferences, and teaching
          sessions, making it easier for healthcare professionals. We also match events using our automated point
          calculator that mimics the scoring criteria for medical training to provide you with the most relevant and
          useful events, helping you achieve your career goals.
        </p>
        <p>
          Search, Book or Create any level of course, teaching session or conference. Find a course of interest or to
          gain points. Create and share your course and monetise it, managing it all from our platform.
        </p>
      </Section>
    </div>
  );
};
