
.SortIcons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  font-size: smaller;
}

.SortIcons > button:nth-child(2) {
  margin-top: -10px;
}

.SortIcons > button {
  color: var(--bs-gray-500);
  line-height: 1.5;
}

.SortIcons > :global(.sort_0) {
  color: var(--bs-emphasis-color);
}

.SortIcons > :global(.sort_1) {
  color: var(--bs-secondary-color);
}

.SortIcons > :global(.sort_2) {
  color: var(--bs-tertiary-color);
}

:global(.cellAlign-left) {
  text-align: left;
}

:global(.cellAlign-center) {
  text-align: center;
}

:global(.cellAlign-right) {
  text-align: right;
}

th > div:global(.colSortable-true) {
  padding-right: 2rem;
}

.RowLink {
  cursor: pointer;
  --bs-table-hover-color: #0d6efd;
}
