import Debug from "debug";
import React from "react";
import { FormControl } from "react-bootstrap";
// import { debounce } from "lodash";
import { Row } from "packages/gossamer-universal";
import { List, SubSectionMultiProps } from "../index";
// import { reportTotalActiveEvents } from "front-end/shared/RemoteLogging";

const debug = Debug("lunr/InMemoryTextSearch");

interface InMemoryTextSearchProps<T extends Row> extends SubSectionMultiProps<T> {
  render?: (props: SubSectionMultiProps<T>) => JSX.Element;
  searchFields: string[];
}

const whitespaceRegex = /\s+/g;
const nonWordCardRegex = /[^\w\s]+/g;

export const InMemoryTextSearch = <T extends {}>(props: InMemoryTextSearchProps<T>): JSX.Element => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

  const selectRows = (newSearchTerm: string) => {
    if (newSearchTerm && props.rows) {
      const words = newSearchTerm.replace(whitespaceRegex, " ").replace(nonWordCardRegex, "").toLowerCase().split(" ");
      // debug(`words`, words.join(","));
      const newRows = props.rows.filter((row) => {
        const searchText = props.searchFields
          .map((fieldId) => row[fieldId])
          .join(" ")
          .toLowerCase();
        return words.reduce((prev, curr) => prev && searchText.indexOf(curr) > -1, true); // find every word
      });
      debug(`filtering rows with search term`, newSearchTerm, words.join(","), newRows.length);
      setSelectedRows(newRows);
    } else {
      setSelectedRows(props.rows);
    }
  };

  React.useEffect(() => {
    if (props.rows) {
      // reportTotalActiveEvents(String(props.rows.length));
      selectRows(searchTerm);
    }
  }, [props.rows]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newSearchTerm = event.currentTarget.value || "";
    selectRows(newSearchTerm);
    setSearchTerm(newSearchTerm);
  };

  const SubSection = props.render || List;

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "58px 15px 10px",
          zIndex: 10,
          backgroundColor: "var(--bs-dark)",
          borderBottom: "1px solid var(--bs-border-color)",
          width: "100%",
        }}
      >
        <FormControl
          onChange={handleChange}
          placeholder="Search for..."
          type="text"
          style={{
            width: "calc(100% - 45px)",
          }}
        />
      </div>
      <SubSection {...props} rows={selectedRows} />
    </div>
  );
};
