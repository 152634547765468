import { DataProvider } from "packages/gossamer-universal";

let provider: DataProvider = null;

export const getDataProvider = () => {
  if (!provider) {
    throw new Error(`DataProvider has not been set`);
  }
  return provider;
};

export const setDataProvider = (arg: DataProvider) => {
  if (provider) {
    throw new Error(`DataProvider already set`);
  }
  provider = arg;
};
