import Debug from "debug";
import * as React from "react";
import { Alert, Button, Form, FormControl, FormGroup, FormLabel, Modal, Spinner, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import { getAuthProvider } from "../index";

const debug = Debug("gfe/au/SignIn");

export const SignIn = (): JSX.Element => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>(null);
  const emailAdrRef = React.useRef<HTMLInputElement>();
  const passwordRef = React.useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const handleFormSubmit = (event: React.FormEvent) => {
    login();
    event.preventDefault();
  };

  const login = async () => {
    try {
      setMessage(null);
      setLoading(true);
      debug(`calling signIn(${emailAdrRef.current.value}, xxx)`);
      await getAuthProvider().signIn(emailAdrRef.current.value, passwordRef.current.value);
      debug(`back`);
      navigate("/");
    } catch (err) {
      console.error(err);
      setMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <Modal show={true} backdrop="static" centered={true}>
      <Form onSubmit={handleFormSubmit}>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={3}>
            {message && <Alert variant="danger">{message}</Alert>}
            <FormGroup controlId="username">
              <FormLabel>Email address</FormLabel>
              <FormControl type="text" ref={emailAdrRef} autoComplete="username" />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel>Password</FormLabel>
              <FormControl type="password" ref={passwordRef} autoComplete="current-password" />
            </FormGroup>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Stack
            direction="horizontal"
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button variant="link" href="/">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              {!isLoading && "Log in"}
            </Button>
          </Stack>
          <Stack
            direction="horizontal"
            style={{
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Button variant="link" href="/forgot-password-send">
              Forgotten password?
            </Button>
          </Stack>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
