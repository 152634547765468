import * as React from "react";
import { Container } from "react-bootstrap";
import { InnerFrameProps } from "packages/gossamer-react-redux";

export const InnerFrame = (props: InnerFrameProps): JSX.Element => {
  return (
    <Container
      fluid="md"
      style={{
        marginTop: 60,
      }}
    >
      {props.children}
    </Container>
  );
};
