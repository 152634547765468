import * as React from "react";
import { Stack } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { EventRow, ReservationRow } from "model";
import { FavouriteButton } from "./FavouriteButton";
import { ReserveButton } from "./ReserveButton";

interface EventStripProps {
  eventRow: EventRow;
  existingReservationRow?: ReservationRow | undefined;
}

export const EventStrip = (props: EventStripProps): JSX.Element => {
  const url = `/event/display/${props.eventRow.id}`;
  const title = props.eventRow.title;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Stack direction="horizontal" gap={2}>
        <ReserveButton {...props} />
        <FavouriteButton {...props} />
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <FacebookShareButton url={location.origin + url}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={location.origin + url} title={title}>
          <XIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={location.origin + url} title={title}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Stack>
    </div>
  );
};
