import * as React from "react";
import { Button, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Display, getConfig, PageProps, PageTitle, QuerySingle, Section } from "packages/gossamer-react-redux";
import { User } from "../../model";

export const PointsCalculator = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  // const user = useSelector(selectUser)();
  const lastPath = useLocation().pathname.split("/").pop()?.split("-")[2];
  const navigate = useNavigate();
  console.log(lastPath);
  return (
    <InnerFrame>
      <PageTitle
        title="Points Calculator"
        subtitle="This is where you can add and log your achievements using our checklist tool, which mirrors the criteria used for core and specialty
application scores. By doing this, we can highlight and pair events that match points you can still gain to boost your application score
"
      />
      <Section>
        <Nav
          justify
          variant="underline"
          activeKey={lastPath}
          onSelect={(selectedKey) => navigate("../points-calc-" + selectedKey)}
        >
          <Nav.Item>
            <Nav.Link eventKey="medical">Medical</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="surgical">Surgical</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="mb20px" />
        <QuerySingle
          entity={User}
          keyValue={getConfig("user-id")}
          render={(innerProps) => <Display {...innerProps} fieldGroup={lastPath} />}
        />
        <Button href={`/user/profile-${lastPath}-update`}>Update</Button>
      </Section>
    </InnerFrame>
  );
};
