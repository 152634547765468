import Debug from "debug";
import * as React from "react";
import { Button, FormControl } from "react-bootstrap";
import {
  getConfig,
  List,
  PageProps,
  PageTitle,
  QueryMulti,
  QuerySingle,
  Section,
  setConfig,
  SubSectionMultiProps,
  SubSectionSingleProps,
  useTrans,
} from "packages/gossamer-react-redux";
import { Organisation, OrganisationRow, User, UserRow } from "model";
import { getFilter } from "packages/gossamer-universal";
import { useUpdateRow } from "packages/gossamer-react-redux/data/useUpdateRow";

const debug = Debug("user/SelectOrganisation");

export const SelectOrganisation = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  debug(`SelectOrganisation`, getConfig("user-id"));
  return (
    <InnerFrame>
      <PageTitle title="Select your Organisation" />
      <Section>
        <p>
          Please enter your employer or university exactly as it is officially known, with correct spaces and
          capitalisation
        </p>
        <QuerySingle entity={User} keyValue={getConfig("user-id")} render={SelectOrganisationInner} />
      </Section>
    </InnerFrame>
  );
};

const SelectOrganisationInner = (props: SubSectionSingleProps<UserRow>): JSX.Element => {
  debug(`SelectOrganisationInner`, props.keyValue, props.row.lastName);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/user/profile-main",
  });
  const userRow = useUpdateRow(trans, User, props.row);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newSearchTerm = event.currentTarget.value || "";
    setSearchTerm(newSearchTerm);
  };

  const createNew = async () => {
    const orgRow = trans.makeCreateRow(Organisation);
    orgRow.setFieldValue("name", searchTerm);
    orgRow.setFieldValue("createdBy", props.keyValue);
    userRow.setFieldValue("organisation", orgRow.getFieldValue("id"));
    await saveWithOutcome("save");
    setConfig("org-unit", props.keyValue);
  };

  const setExisting = async (keyValue: string) => {
    console.log(`clicked`, keyValue);
    userRow.setFieldValue("organisation", keyValue);
    await saveWithOutcome("save");
    setConfig("org-unit", keyValue);
    // redirect
  };

  return (
    <>
      <FormControl onChange={handleChange} placeholder="Organisation Name" type="text" />
      {searchTerm.length >= 3 && (
        <QueryMulti
          entity={Organisation}
          render={(innerProps) => <OrganisationList {...innerProps} createNew={createNew} />}
          queryOptions={{
            where: {
              and: [getFilter("name", "ct", searchTerm)],
            },
            limit: 25,
          }}
          onRowClick={setExisting}
          showItemCount={false}
          showSettingsControl={false}
        />
      )}
    </>
  );
};

interface OrganisationListProps extends SubSectionMultiProps<OrganisationRow> {
  createNew: () => Promise<void>;
}

const OrganisationList = (props: OrganisationListProps): JSX.Element => {
  return (
    <>
      <List {...props} />
      {props.rows.length === 0 && (
        <p>
          We do not have an organisation with that name. Please check your spelling. If it is correct then please enter
          the full name and then <Button onClick={props.createNew}>click here to create it</Button>.
        </p>
      )}
    </>
  );
};
