import { Row } from "../types/core";

const FILE_PARTS_SEPARATOR = "|";

export const deduceNameFromEmail = (emailAddress: string) => {
  const parts1 = emailAddress.split("@");
  const parts2 = parts1[0].split("+");
  const parts3 = parts2[0].split(".");
  return parts3.map((part) => part.substring(0, 1).toUpperCase() + part.substring(1).toLowerCase()).join(" ");
};

export const filePartsCompose = (title: string, mimetype: string, content: string) => {
  if (!title && !mimetype && !content) return null;
  if (!title || !mimetype || !content) throw new Error(`please supply all 3 file parts or none`);
  return [title, mimetype, content].join(FILE_PARTS_SEPARATOR);
};

export const filePartsGet = (combined: string) => {
  if (!combined) {
    return {
      title: null,
      mimetype: null,
      content: null,
    };
  }
  const parts = combined.split(FILE_PARTS_SEPARATOR);
  if (parts.length !== 3) {
    throw new Error(`invalid file property, had ${parts.length} parts - should be 3`);
  }
  return {
    title: parts[0],
    mimetype: parts[1],
    content: parts[2],
  };
};

export const filePartsMerge = (row: Row, fieldId: string) => {
  row[fieldId] = filePartsCompose(row[`${fieldId}_title`], row[`${fieldId}_mimetype`], row[`${fieldId}_content`]);
  delete row[`${fieldId}_title`];
  delete row[`${fieldId}_mimetype`];
  delete row[`${fieldId}_content`];
};

export const filePartsSplit = <T extends Row>(row: T, fieldId: string) => {
  if (!row[fieldId]) {
    // file field is blank
    return;
  }
  const { title, mimetype, content } = filePartsGet(String(row[fieldId]));
  row[`${fieldId}_title`] = title;
  row[`${fieldId}_mimetype`] = mimetype;
  row[`${fieldId}_content`] = content;
  delete row[fieldId];
};

export const stripAwsRegionFromUserId = (userId: string) => {
  const parts = userId.split(":");
  return parts.length > 1 ? parts[1] : userId;
};
