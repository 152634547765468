import { cloneDeep } from "lodash";
import { Entity, QueryOptions, SortDirection } from "packages/gossamer-universal";

const DEFAULT_PAGE_SIZE = 20;

export const changePageSize = (queryOptions: QueryOptions, newPageSize: number) => {
  const newQueryOptions = cloneDeep(queryOptions);
  newQueryOptions.offset = 0;
  newQueryOptions.limit = newPageSize;
  return newQueryOptions;
};

export const displayReduxTKError = (error: any) =>
  error.response?.data || error.data || error.error || error.message || String(error);

export const getPageNumber = (queryOptions: QueryOptions): [number, number] => {
  const pageSize = queryOptions.limit || DEFAULT_PAGE_SIZE;
  const pageNumber = (queryOptions.offset || 0) / pageSize + 1;
  return [pageNumber, pageSize];
};

export const initializeQueryOptions = (entity: Entity<{}>, queryOptions: QueryOptions): QueryOptions => {
  const newQueryOptions = cloneDeep(queryOptions);
  if (typeof newQueryOptions.limit !== "number") {
    newQueryOptions.limit = DEFAULT_PAGE_SIZE;
  }
  if (!newQueryOptions.order) {
    newQueryOptions.order = entity.defaultSort || [];
  }
  if (!newQueryOptions.where) {
    newQueryOptions.where = {
      and: [],
    };
  }
  return newQueryOptions;
};

export const moveToPage = (queryOptions: QueryOptions, newPageNumber: number): QueryOptions => {
  const newQueryOptions = cloneDeep(queryOptions);
  const pageSize = queryOptions.limit || DEFAULT_PAGE_SIZE;
  newQueryOptions.offset = (newPageNumber - 1) * pageSize;
  return newQueryOptions;
};

export const sortHeaderClicked = (queryOptions: QueryOptions, fieldId: string, direction: SortDirection) => {
  const newQueryOptions = cloneDeep(queryOptions);
  newQueryOptions.offset = 0; // return to page 1
  newQueryOptions.order = newQueryOptions.order || [];
  const found = newQueryOptions.order.findIndex((sort) => sort[0] === fieldId);
  if (found > -1) {
    newQueryOptions.order.splice(found, 1);
  }
  newQueryOptions.order.unshift([fieldId, direction]);
  return newQueryOptions;
};
