import * as React from "react";
import {
  getConfig,
  PageProps,
  PageTitle,
  Section,
  TransButtons,
  UpdateWrapper,
  useTrans,
} from "packages/gossamer-react-redux";
import { User } from "../../model";
import { CurrentPoints } from "./CurrentPoints";
import { cloneEntity } from "packages/gossamer-universal";

const localUser = cloneEntity(User);
localUser.fields.surgicalPoints.visible = false;

export const ProfileSurgicalUpdate = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/user/points-calc-surgical",
  });

  return (
    <InnerFrame>
      <br />
      <br />
      <PageTitle title="Update My Surgical Achievements" subtitle="Information about me" />
      <Section>
        <CurrentPoints transaction={trans} category="surgical" />
        <UpdateWrapper transaction={trans} entity={localUser} keyValue={getConfig("user-id")} fieldGroup="surgical" />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
