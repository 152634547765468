import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { BulkUpload } from "./BulkUpload";
import { BulkUpload2 } from "./BulkUpload2";
import { EventCopy } from "./EventCopy";
import { EventCreate } from "./EventCreate";
import { EventDelete } from "./EventDelete";
import { EventDisplay } from "./EventDisplay";
import { EventsFavourited } from "./EventsFavourited";
import { EventsOrganising } from "./EventsOrganising";
import { EventsReserved } from "./EventsReserved";
import { EventSearch } from "./EventSearch";
import { EventUpdate } from "./EventUpdate";
import { PageProps } from "packages/gossamer-react-redux";

export const EventRoutes = (props: PageProps): JSX.Element => (
  <Routes>
    <Route path="/bulk-upload" element={<BulkUpload {...props} />} />
    <Route path="/bulk-upload-2" element={<BulkUpload2 {...props} />} />
    <Route path="/copy/:key" element={<EventCopy {...props} />} />
    <Route path="/create" element={<EventCreate {...props} />} />
    <Route path="/delete/:key" element={<EventDelete {...props} />} />
    <Route path="/display/:key" element={<EventDisplay {...props} />} />
    <Route path="/favourited" element={<EventsFavourited {...props} />} />
    <Route path="/organising" element={<EventsOrganising {...props} />} />
    <Route path="/reserved" element={<EventsReserved {...props} />} />
    <Route path="/search" element={<EventSearch {...props} />} />
    <Route path="/update/:key" element={<EventUpdate {...props} />} />
  </Routes>
);
