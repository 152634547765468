import { initializeApp } from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzVCNi8Ga-GSAGu-vcQLqhZNhDjqI7RQY",
  authDomain: "carreraa.firebaseapp.com",
  projectId: "carreraa",
  storageBucket: "carreraa.appspot.com",
  messagingSenderId: "511518483302",
  appId: "1:511518483302:web:09f5c9554627b392141ea7",
  measurementId: "G-9NF2MNM81T",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
