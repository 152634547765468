import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";

export const getLocalDateString = (d: Date) =>
  [
    d.toLocaleString("default", { year: "numeric" }),
    d.toLocaleString("default", { month: "2-digit" }),
    d.toLocaleString("default", { day: "2-digit" }),
  ].join("-");

export const deepEqualsByTopLevelProperties = (obj1: any, obj2: any): Record<string, boolean> => {
  const out = {};
  Object.keys(obj1)
    .concat(Object.keys(obj2))
    .forEach((key) => {
      out[key] = isEqual(obj1[key], obj2[key]);
    });
  return out;
};

export const deepFreeze = (arg: any, keyChain: any) => {
  if (Array.isArray(arg)) {
    Object.freeze(arg);
    arg.forEach((el) => deepFreeze(el, keyChain));
  } else if (typeof arg === "object") {
    Object.freeze(arg);
    Object.keys(arg).forEach((key) => {
      if (keyChain === "*" || keyChain[key]) {
        deepFreeze(arg[key], keyChain === "*" ? "*" : keyChain[key]);
      }
    });
  }
};

export const deepFreezeEntities = (arg: any) => {
  deepFreeze(arg, {
    primaryKeyFields: "*",
    security: "*",
    defaultSort: "*",
    defaultFilters: "*",
    autoCompleterSearchExpression: "*",
    fields: {
      options: "*",
    },
  });
};

export const getUuid = () => uuidv4();

export const isBlank = (arg: any) => arg === undefined || arg === null || arg === "";

export const isUuid = (val: string) => /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.exec(val);

export const slugify = (arg: string): string => {
  return arg.toLowerCase().replace(/\s+/, "");
};

export const stringToSentenceCase = (sentence: string) =>
  sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
