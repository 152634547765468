import React from "react";
import { Pagination } from "react-bootstrap";

interface Props {
  // changePageSize: (newPageSize: number) => void;
  moveToPage: (newPage: number) => void;
  pageNumber: number;
  pageSize: number;
  rowsInPage: number;
}

export const Paginator = (props: Props): JSX.Element => {
  const moveToPage = (newPage: number) => {
    window.scrollTo(0, 0);
    props.moveToPage(newPage);
  };
  return (
    <Pagination>
      <Pagination.First onClick={moveToPage.bind(null, 1)} disabled={props.pageNumber === 1} />
      <Pagination.Prev onClick={moveToPage.bind(null, props.pageNumber - 1)} disabled={props.pageNumber === 1} />
      <Pagination.Item active>{props.pageNumber}</Pagination.Item>
      <Pagination.Next
        onClick={moveToPage.bind(null, props.pageNumber + 1)}
        disabled={props.rowsInPage < props.pageSize}
      />
    </Pagination>
  );
};
