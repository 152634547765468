import * as React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Event, EventRow, ReservationRow } from "model";
import { OptionsField } from "packages/gossamer-universal";
import { NetPoints } from "./NetPoints";
import { toLocaleDateTimeToMinutes } from "front-end/shared/Helpers";
import { EventStrip } from "./EventStrip";
import { ErrorBoundary } from "packages/gossamer-react-redux";
import { useGetEventImageSrc } from "./useGetEventImageSrc";

interface EventTileProps {
  eventRow: EventRow;
  existingReservationRow?: ReservationRow | undefined;
}

export const EventTile = (props: EventTileProps): JSX.Element => {
  const url = `/event/display/${props.eventRow.id}`;
  const src = useGetEventImageSrc(props.eventRow);
  const title = props.eventRow.title;
  return (
    <div style={{ maxWidth: 500, width: "100%" }}>
      <ErrorBoundary>
        <Card>
          <Card.Img variant="top" src={src} />
          <Card.Body>
            <Card.Title>
              <Link to={url}>
                <h2>{title}</h2>
              </Link>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {(Event.fields.type as OptionsField).options.find((opt) => opt.id === props.eventRow.type)?.label ||
                "Unknown"}
            </Card.Subtitle>
            <Card.Text>{props.eventRow.location}</Card.Text>
            <p style={{ whiteSpace: "pre-wrap", maxHeight: 192, overflowY: "clip" }}>
              {props.eventRow.description || ""}
            </p>
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "50%" }}>
                {props.eventRow.startsAt && (
                  <>
                    Starts At:
                    <br />
                    <b>{toLocaleDateTimeToMinutes(props.eventRow.startsAt)}</b>
                  </>
                )}
              </div>
              <div style={{ width: "25%", textAlign: "right" }}>
                Cost:
                <br />
                <b>£{props.eventRow.price}</b>
              </div>
              <div style={{ width: "25%", textAlign: "right" }}>
                Points:
                <br />
                <NetPoints eventRow={props.eventRow} />
              </div>
            </div>
            <div>
              {props.eventRow.distributionType === "IN-PERSON" && (props.eventRow.location || "In-person")}
              {props.eventRow.distributionType === "ONLINE" && "Online / scheduled"}
              {props.eventRow.distributionType === "HYBRID" && (props.eventRow.location || "In-person") + " and Online"}
              {props.eventRow.distributionType === "ON-DEMAND" && "Online / on-demand"}
            </div>
          </Card.Body>
          <Card.Footer>
            <EventStrip {...props} />
          </Card.Footer>
        </Card>
      </ErrorBoundary>
    </div>
  );
};
