import Debug from "debug";
import * as React from "react";
import { Button, InputGroup, Spinner } from "react-bootstrap";
import {
  cloneEntity,
  cloneField,
  ConditionElement,
  Entity,
  Field,
  FieldId,
  isAllowAddRows,
  Row,
} from "packages/gossamer-universal";
import {
  displayReduxTKError,
  General,
  Grid,
  GridProps,
  GridHeaderProps,
  GridRowProps,
  Transaction,
  TransRow,
  useQuery,
} from "../index";

const debug = Debug("gfe/se/GridWrapper");

interface Props<T extends Row> {
  addRowFieldId?: string;
  entity: Entity<T>;
  extraFilters?: ConditionElement[];
  linkField?: string;
  linkValue?: string;
  modify?: (localEntity: Entity<T>, addRowField: Field) => void;
  renderFooter?: () => JSX.Element;
  renderGrid?: (props: GridProps<T>) => JSX.Element;
  renderHeader?: (props: GridHeaderProps<T>) => JSX.Element;
  renderRow?: (props: GridRowProps<T>) => JSX.Element;
  transaction: Transaction;
}

const getLinkFilter = (
  linkField?: FieldId,
  linkValue?: string,
  extraFilters?: ConditionElement[]
): ConditionElement[] =>
  (!!linkField && !!linkValue
    ? [{ fieldId: linkField, operator: "eq", value: linkValue } as ConditionElement]
    : []
  ).concat(extraFilters || []);

export const GridWrapper = <T extends {}>(props: Props<T>): JSX.Element => {
  debug("start of render GridWrapper");
  const [filters, setFilters] = React.useState<ConditionElement[]>([]);
  const [addRowField, setAddRowField] = React.useState<Field>(null);
  const [localEntity, setLocalEntity] = React.useState<Entity<{}>>(null);
  React.useEffect(() => {
    setFilters(getLinkFilter(props.linkField, props.linkValue, props.extraFilters));
  }, [props.linkField, props.linkValue]);
  React.useEffect(() => {
    const localEntity = cloneEntity(props.entity);
    if (props.linkField && props.linkValue) {
      localEntity.fields[props.linkField].listColumn = false;
    }
    setLocalEntity(localEntity);
    if (isAllowAddRows(localEntity) && typeof props.addRowFieldId === "string") {
      const addRowField = cloneField(localEntity.fields[props.addRowFieldId]);
      addRowField.mandatory = false;
      addRowField.editable = true;
      addRowField.label = "Add a new " + addRowField.label;
      setAddRowField(addRowField);
      localEntity.fields[props.addRowFieldId].editable = false;
    }
    if (props.modify) {
      props.modify(localEntity, addRowField);
    }
  }, []);

  const ready = !!localEntity && !!filters;
  return (
    <>
      {ready && (
        <GridDataAccess
          addRowField={addRowField}
          addRowFieldId={props.addRowFieldId}
          filters={filters}
          linkField={props.linkField}
          linkValue={props.linkValue}
          entity={localEntity}
          renderFooter={props.renderFooter}
          renderGrid={props.renderGrid}
          renderHeader={props.renderHeader}
          renderRow={props.renderRow}
          transaction={props.transaction}
        />
      )}
      {!ready && <div>Loading...</div>}
    </>
  );
};

interface GridDataAccessProps<T extends Row> {
  addRowField?: Field;
  addRowFieldId?: string;
  entity: Entity<T>;
  filters: ConditionElement[];
  linkField?: string;
  linkValue?: string;
  renderFooter?: () => JSX.Element;
  renderGrid?: (props: GridProps<T>) => JSX.Element;
  renderHeader?: (props: GridHeaderProps<T>) => JSX.Element;
  renderRow?: (props: GridRowProps<T>) => JSX.Element;
  transaction: Transaction;
}

export const GridDataAccess = <T extends {}>(props: GridDataAccessProps<T>): JSX.Element => {
  debug("start of render GridDataAccess");
  const [rows, setRows] = React.useState<TransRow<T>[]>([]);
  const { data, error, isFetching } = useQuery(props.entity, {
    order: props.entity.defaultSort || [],
    where: {
      and: props.filters,
    },
  });
  React.useEffect(() => {
    if (rows && rows.length > 0) {
      return; // ignore
      // throw new Error(`rows already added`);
    }
    debug(`starting addition of ${data?.length} existing rows`);
    const newRows = data?.map((row) => props.transaction.makeUpdateRow(props.entity, row));
    debug(`end of existing row addition`);
    setRows(newRows);
  }, [data]);

  const addRow = (newValue?: string) => {
    debug(`adding a new row with value ${newValue}`);
    const newRows = rows.slice();
    const newRow = props.transaction.makeCreateRow(props.entity);
    if (props.linkField && props.linkValue) {
      newRow.setFieldValue(props.linkField, props.linkValue);
    }
    if (props.addRowFieldId) {
      newRow.setFieldValue(props.addRowFieldId, newValue);
    }
    newRows.push(newRow);
    setRows(newRows);
    debug(`addRow() rows: ${newRows.length}`);
  };

  const deleteRow = (row: TransRow<any>) => {
    row.setDelete(true);
    const newRows = rows.slice();
    const index = newRows.indexOf(row);
    if (index === -1) {
      console.error(`row not found in array: ${row.getEntity()}/${row.getKey()}`);
    } else {
      newRows.splice(index, 1);
      setRows(newRows);
    }
    debug(`deleteRow() rows: ${newRows.length}`);
  };

  // must be included in a re-render when adding or deleting a row
  if (props.addRowField) {
    props.addRowField.excludeValues = props.transaction
      .getRowsByEntity(props.entity.id)
      .filter((row) => !row.isDeleting())
      .map((row) => String(row.getFieldValue(props.addRowFieldId as string)));
  }

  const LocalGrid = props.renderGrid || Grid;
  return (
    <>
      {rows && (
        <div className="mb20px">
          {props.entity && (
            <LocalGrid
              deleteRow={deleteRow}
              entity={props.entity}
              rows={rows}
              renderFooter={props.renderFooter}
              renderHeader={props.renderHeader}
              renderRow={props.renderRow}
            />
          )}
          {isAllowAddRows(props.entity) && !props.addRowField && (
            <div>
              <Button
                onClick={() => {
                  addRow();
                }}
                className="main"
              >
                Add New Row
              </Button>
            </div>
          )}
          {!!props.addRowField && (
            <InputGroup size="sm">
              <InputGroup.Text>{props.addRowField.label}</InputGroup.Text>
              <General
                className="addNewRow"
                clearOnSelection={true}
                editable={true}
                fieldId="addNewRow"
                field={props.addRowField}
                handleChange={addRow}
                value=""
              />
            </InputGroup>
          )}
        </div>
      )}
      {error && <div>{displayReduxTKError(error)}</div>}
      {isFetching && (
        <div>
          <Spinner animation="border" size="sm" />
        </div>
      )}
    </>
  );
};
