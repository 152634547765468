import * as React from "react";
import styles from "./FormControl.module.css";

interface FormControlProps {
  children?: React.ReactNode;
  className?: string;
  flexDirection?: "row" | "column";
  validity?: boolean;
}

export const FormControl = (props: FormControlProps): JSX.Element => {
  const classes = ["form-control"];
  if (props.className) {
    classes.push(props.className);
  }
  if (props.flexDirection === "row") {
    classes.push(styles.FormControlHoriz);
  }
  if (props.flexDirection === "column") {
    classes.push(styles.FormControlVert);
  }
  if (props.validity === true) {
    classes.push("is-valid");
  }
  if (props.validity === false) {
    classes.push("is-invalid");
  }
  return <div className={classes.join(" ")}>{props.children}</div>;
};
