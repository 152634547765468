import * as React from "react";
import {
  getConfig,
  InMemoryTextSearch,
  PageProps,
  QueryMulti,
  Section,
  useDocumentTitle,
} from "packages/gossamer-react-redux";
import { Event, EventRow } from "model";
import { EventTile } from "../event/EventTile";
import { EventTileContainer } from "../event/EventTileContainer";
import { getFilter } from "packages/gossamer-universal";
import { HomeBlock } from "front-end/shared/HomeBlock";
import { MainFilters } from "front-end/event/MainFilters";
import { InMemoryPaginator } from "packages/gossamer-react-redux/data/InMemoryPaginator";

export const Home = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  useDocumentTitle("Home");
  return (
    <>
      <HomeBlock />
      <InnerFrame>
        <Section>
          <QueryMulti
            entity={Event}
            queryOptions={{
              where: {
                and: [
                  {
                    or: [
                      getFilter("startsAt", "gt", new Date().toISOString()),
                      getFilter("distributionType", "eq", "ON-DEMAND"),
                    ],
                  },
                  getFilter("visibility", "eq", "ANYONE"),
                ],
              },
            }}
            showItemCount={false}
            showPaginator="never"
            showSettingsControl={false}
            render={(middleProps) => (
              <InMemoryTextSearch
                {...middleProps}
                searchFields={["title", "location", "description", "type"]}
                render={(innerProps) => (
                  <MainFilters
                    rows={innerProps.rows}
                    render={(nanoProps) => (
                      <InMemoryPaginator
                        pageSize={10}
                        rows={nanoProps.rows}
                        render={(picoprops) => (
                          <EventTileContainer>
                            {picoprops.rows.map((row: EventRow) => (
                              <EventTile eventRow={row} key={row.id} />
                            ))}
                          </EventTileContainer>
                        )}
                      />
                    )}
                  />
                )}
              />
            )}
          />
        </Section>
      </InnerFrame>
    </>
  );
};
