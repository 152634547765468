import Debug from "debug";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";

const debug = Debug("gfe/fi/Richtext");

interface RichtextProps {
  className?: string;
  handleChange: (newValue: string) => void;
  mandatory: boolean;
  validity?: boolean;
  value: string;
}

export const Richtext = (props: RichtextProps): JSX.Element => {
  debug(`Richtext ${props.value}`);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = event.target.value;
    props.handleChange(newVal);
  };
  return (
    <FormControl
      as={TextareaAutosize}
      className={props.className}
      defaultValue={String(props.value || "")}
      isInvalid={props.validity === false}
      isValid={props.validity === true}
      onChange={handleChange}
    />
  );
};
