import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { OrganisationCreate } from "./OrganisationCreate";
import { OrganisationDisplay } from "./OrganisationDisplay";
import { OrganisationSearch } from "./OrganisationSearch";
import { OrganisationUpdate } from "./OrganisationUpdate";

import { PageProps } from "packages/gossamer-react-redux";

export const OrganisationRoutes = (props: PageProps): JSX.Element => (
  <Routes>
    <Route path="/create" element={<OrganisationCreate {...props} />} />
    <Route path="/display/:key" element={<OrganisationDisplay {...props} />} />
    <Route path="/search" element={<OrganisationSearch {...props} />} />
    <Route path="/update/:key" element={<OrganisationUpdate {...props} />} />
  </Routes>
);
