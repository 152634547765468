import * as React from "react";
import { Spinner } from "react-bootstrap";
import {
  cloneEntity,
  DataVolume,
  doFrontEndEvaluates,
  Entity,
  getRowKey,
  isBlank,
  Row,
} from "packages/gossamer-universal";
import { displayReduxTKError, useQuery, useRecord } from "../index";

export interface SubSectionSingleProps<T extends Row> {
  entity: Entity<T>;
  fieldGroup?: string;
  keyValue: string | null | undefined;
  row: T;
}

export type RenderAllProps<T extends Row> = SubSectionSingleProps<T> & {
  error: any;
  isFetching: boolean;
  isNotFound: boolean;
};

export interface RenderErrorProps {
  error: any;
}

const DefaultError = (props: RenderErrorProps): JSX.Element => <div>{displayReduxTKError(props.error)}</div>;

export interface RenderNoLoadOrNotFoundProps {
  keyValue: string;
}

const DefaultFetching = (): JSX.Element => <Spinner animation="border" size="sm" />;

const DefaultNoLoad = (props: RenderNoLoadOrNotFoundProps): JSX.Element => <div>{props.keyValue}</div>;

const DefaultNotFound = (props: RenderNoLoadOrNotFoundProps): JSX.Element => <div>Not found: {props.keyValue}</div>;

interface Props<T extends Row> {
  alterEntity?: (cloneEntity: Entity<T>) => void;
  entity: Entity<T>;
  fieldGroup?: string;
  keyValue: string;
  loadCondition?: (keyValue: string) => boolean;
  render?: (props: SubSectionSingleProps<T>) => JSX.Element;
  renderAll?: (props: RenderAllProps<T>) => JSX.Element;
  renderBlank?: () => JSX.Element;
  renderError?: (props: RenderErrorProps) => JSX.Element;
  renderFetching?: () => JSX.Element;
  renderNoLoad?: (props: RenderNoLoadOrNotFoundProps) => JSX.Element;
  renderNotFound?: (props: RenderNoLoadOrNotFoundProps) => JSX.Element;
}

export const QuerySingle = <T extends Row>(props: Props<T>): JSX.Element => {
  const localEntity = React.useMemo(() => {
    if (props.alterEntity) {
      const localEntity = cloneEntity(props.entity);
      props.alterEntity(localEntity);
      return localEntity;
    }
    return props.entity;
  }, [props.entity, props.alterEntity]);

  if (isBlank(props.keyValue) && !props.renderAll) {
    const Render = props.renderBlank || (() => null);
    return <Render />;
  }
  if (props.loadCondition && !props.loadCondition(props.keyValue) && !props.renderAll) {
    const Render = props.renderNoLoad || DefaultNoLoad;
    return <Render keyValue={props.keyValue} key={props.keyValue} />;
  }
  if (localEntity.dataVolume === DataVolume.Small) {
    return <QuerySingleSmallVol {...props} entity={localEntity} key={props.keyValue} />;
  }
  return <QuerySingleNotSmallVol {...props} entity={localEntity} key={props.keyValue} />;
};

const QuerySingleSmallVol = <T extends Row>(props: Props<T>): JSX.Element => {
  const { data, error, isFetching, reload } = useQuery(props.entity, {});
  const row = data
    ?.filter((row) => getRowKey(props.entity, row) === String(props.keyValue))
    .map((row) => doFrontEndEvaluates(props.entity, row))[0]; // should only be one match anyway
  return <QuerySingleInner {...props} row={row} error={error} isFetching={isFetching} isNotFound={data && !row} />;
};

const QuerySingleNotSmallVol = <T extends Row>(props: Props<T>): JSX.Element => {
  const { data, error, isFetching, reload } = useRecord<T>(props.entity, props.keyValue);
  return (
    <QuerySingleInner
      {...props}
      row={data}
      error={error}
      isFetching={isFetching}
      isNotFound={error?.response?.status === 404}
    />
  );
};

type QuerySingleInnerProps<T> = Props<T> & {
  error: any;
  isFetching: boolean;
  isNotFound: boolean;
  row: T;
};

const QuerySingleInner = <T extends Row>(props: QuerySingleInnerProps<T>): JSX.Element => {
  if (props.renderAll) {
    const SubSection = props.renderAll;
    return <SubSection {...props} />;
  }
  if (props.row) {
    if (!props.render) {
      throw new Error(`either render or renderAll must be supplied as a prop`);
    }
    const SubSection = props.render;
    return <SubSection entity={props.entity} fieldGroup={props.fieldGroup} keyValue={props.keyValue} row={props.row} />;
  }
  if (props.isNotFound) {
    const RenderNotFound = props.renderNotFound || DefaultNotFound;
    return <RenderNotFound keyValue={props.keyValue} />;
  }
  if (props.error) {
    const RenderError = props.renderError || DefaultError;
    return <RenderError error={props.error} />;
  }
  if (props.isFetching) {
    const RenderLoading = props.renderFetching || DefaultFetching;
    return <RenderLoading />;
  }
};
