import { AnyAction, Store } from "redux";
import { TransState, UserState } from "../index";

export type StoreState = {
  trans: TransState;
  user: UserState;
};

export type ReduxStore = Store<StoreState, AnyAction>;
let store: ReduxStore;

export const getStore = () => {
  if (!store) {
    throw new Error(`store has not been set`);
  }
  return store;
};

export const setStore = (arg: Store<StoreState, AnyAction>) => {
  if (store) {
    throw new Error(`DataProvider already set`);
  }
  store = arg;
};
