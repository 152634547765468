import Debug from "debug";

const error = Debug("error");

export type NotifyType = "primary" | "success" | "danger" | "warning" | "info";
export type NotifyInfo = [string, NotifyType];
export type NotifySubscriber = (info: NotifyInfo) => void;

let notifyFunction: NotifySubscriber = null;

export const setNotifySubscriber = (func: NotifySubscriber): void => {
  notifyFunction = func;
};

export const notify = (info: NotifyInfo) => {
  if (notifyFunction) {
    notifyFunction(info);
  } else {
    error(`notify function not registered! ${info}`);
  }
};
