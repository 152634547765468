import * as React from "react";
import {
  getConfig,
  PageProps,
  PageTitle,
  Section,
  TransButtons,
  UpdateWrapper,
  useTrans,
} from "packages/gossamer-react-redux";
import { User } from "../../model";
import { CurrentPoints } from "./CurrentPoints";
import { cloneEntity } from "packages/gossamer-universal";

const localUser = cloneEntity(User);
localUser.fields.medicalPoints.visible = false;

export const ProfileMedicalUpdate = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/user/points-calc-medical",
  });
  return (
    <InnerFrame>
      <br />
      <br />
      <PageTitle title="Update My Medical Achievements" subtitle="Things that contribute to my overall points score" />
      <Section>
        <CurrentPoints transaction={trans} category="medical" />
        <UpdateWrapper transaction={trans} entity={localUser} keyValue={getConfig("user-id")} fieldGroup="medical" />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
