import * as React from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Event } from "model";
import { getDataProvider, PageProps, PageTitle, Section } from "packages/gossamer-react-redux";

type Status = "waiting" | "clicked" | "done" | "error";

export const EventDelete = (props: PageProps): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const InnerFrame = props.innerFrame;
  const [status, setStatus] = React.useState<Status>("waiting");

  const handleClick = async () => {
    setStatus("clicked");
    try {
      const eventRow = await getDataProvider().getRecord(Event, params.key);
      eventRow.visibility = "HIDDEN";
      await getDataProvider().updateRow(Event, eventRow);
      setStatus("done");
      navigate("/");
    } catch (error) {
      console.error(error);
      setStatus("error");
    }
  };

  return (
    <InnerFrame>
      <PageTitle verb="Delete" entity={Event} keyValue={params.key} />
      <Section>
        <p>Are you sure you want to delete this event?</p>
        <Button size="sm" onClick={handleClick} variant="danger">
          {status === "clicked" && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
          <span>&nbsp;Delete</span>
        </Button>
        {status === "error" && <p>Sorry, an error occurred, please try again later</p>}
      </Section>
    </InnerFrame>
  );
};
