import { Primitive } from "../types/core";
import { Field, FieldType, OptionsField, TextField } from "../types/entity";

// \u0009 tab
// \u000A LF
// \u0020-\u007E basic latin letters, digits and symbols excludes control chars
// \u00A0-\u00FF latin-1 supplement punctuation, symbols and characters
// export const GENERAL_CHAR_REGEX = /^[\u0009\u000A\u0020-\u007E\u00A0-\u00FF]*$/;
//
export const GENERAL_CHAR_REGEX = /^[\s\P{Cc}]*$/u;

export const validate = (field: Field, value: Primitive): string | null => {
  if (field.mandatory && (value === undefined || value === null || String(value).trim() === "")) {
    return "required";
  }

  if (typeof value === "string" && !GENERAL_CHAR_REGEX.test(value)) {
    return "invalid character(s)";
  }

  if (field.type !== FieldType.Boolean && typeof value === "boolean") {
    return "cannot be a boolean value";
  }

  if (field.encrypted) {
    return null; // TODO - can't validate encrypted values
  }

  const numberValue =
    typeof value === "string" ? parseFloat(value) : typeof value === "boolean" ? (value ? 1 : 0) : value;

  // permissive of nulls - handled by mandatory check
  if (!!value && typeof field.min === "number" && numberValue !== null && numberValue < field.min) {
    return `must be at least ${field.min}`;
  }
  if (!!value && typeof field.max === "number" && numberValue !== null && numberValue > field.max) {
    return `must be at most ${field.max}`;
  }

  if (!!value && typeof field.regex === "string" && typeof value === "string") {
    const regexp = new RegExp(field.regex);
    if (!regexp.test(value)) {
      return `must match pattern ${field.regex}`;
    }
  }

  const minLength = (field as TextField).minLength;
  if (!!value && typeof minLength === "number" && typeof value === "string" && value.length < minLength) {
    return `must be at least ${minLength} characters long`;
  }

  const maxLength = (field as TextField).maxLength;
  if (!!value && typeof maxLength === "number" && typeof value === "string" && value.length > maxLength) {
    return `must be at most ${maxLength} characters long`;
  }

  const options = (field as OptionsField).options;
  if (!!value && options && !options.find((option) => option.id === String(value))) {
    return `not one of the allowed options`;
  }

  if (!!value && [FieldType.Date, FieldType.Datetime].indexOf(field.type) > -1) {
    const d = new Date(value as string);
    try {
      d.toISOString(); // throws if date is invalid
    } catch (e) {
      return `invalid date`;
    }
  }

  return null;
};
