import * as React from "react";
import { useSelector } from "react-redux";
import { Entity, Row, SecurityAction } from "packages/gossamer-universal";
import { isAllowed, selectUser } from "../index";

interface IsAllowedProps<T extends Row> {
  action: SecurityAction;
  children?: React.ReactNode;
  entity: Entity<T>;
  special?: string;
  row?: T;
}

export const IsAllowed = <T extends Row>(props: IsAllowedProps<T>): JSX.Element => {
  const user = useSelector(selectUser)();
  if (!isAllowed(props.entity, props.action, user, props.special, props.row)) {
    return null;
  }
  return <>{props.children}</>;
};
