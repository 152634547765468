import React from "react";

interface FixedSubLevelProps {
  children: React.ReactNode;
}

export const FixedSubLevel = (props: FixedSubLevelProps) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      padding: "58px 15px 10px",
      zIndex: 10,
      backgroundColor: "var(--bs-dark)",
      borderBottom: "1px solid var(--bs-border-color)",
    }}
  >
    {props.children}
  </div>
);
