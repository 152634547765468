
a.FileLink {
    text-decoration: underline;
    cursor: pointer;
  }
  
  div:global(.fileupload) {
    flex-flow: row nowrap;
  }
  
  div:global(.fileupload) > div {
    flex-grow: 1;
    background-color: var(--bs-body-bg);
  }
  
  div:global(.fileupload) > button {
    position: absolute;
    right: 0;
  }

  