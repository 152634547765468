import * as React from "react";
import { CreateWrapper, PageProps, PageTitle, Section, TransButtons, useTrans } from "packages/gossamer-react-redux";
import { getListRowLink } from "packages/gossamer-universal";
import { User } from "model";

export const UserCreate = (props: PageProps): JSX.Element => {
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/user/search",
    changeFollowOnURLOnSuccess: ({ keyOfFirstCreate }) =>
      getListRowLink(User.frontEndOptions.listRowLink, keyOfFirstCreate),
  });
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Create" entity={User} />
      <Section>
        <CreateWrapper entity={User} transaction={trans} />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
