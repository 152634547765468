import { InnerFrameProps } from "packages/gossamer-react-redux";
import * as React from "react";
import { Container } from "react-bootstrap";

export const InnerFrame = (props: InnerFrameProps): JSX.Element => {
  // const LocalMenu = props.localMenu;
  return (
    <Container
      fluid="md"
      style={{
        marginTop: 60,
      }}
    >
      {props.children}
    </Container>
  );
};
