import * as React from "react";

export const LoginOrSignupLinks = (): JSX.Element => {
  return (
    <>
      <a href="/signin">Log in</a>&nbsp; or&nbsp;
      <a href="/signup">Sign up</a>
    </>
  );
};
