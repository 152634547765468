import Debug from "debug";
import { Dispatch, Middleware } from "redux";
import { displayReduxTKError, FetchJson } from "../index";
import {
  addQueryToGCache,
  addRecordToGCache,
  GCacheState,
  gCacheSweep,
  clearSingleQueryInGCache,
  clearSingleRecordInGCache,
  getQueryFromGCache,
  GetQueryPayload,
  getRecordFromGCache,
  GetRecordPayload,
  makeQueryGCacheKey,
  makeRecordGCacheKey,
  setQueryDataInGCache,
  setQueryErrorInGCache,
  setRecordDataInGCache,
  setRecordErrorInGCache,
} from "./ReduxGCache";

const debug = Debug("gfe/da/ReduxGCacheMiddleware");

// Middleware
export const gCacheMiddleware: (fetchJson: FetchJson) => Middleware<{}, { cache: GCacheState }> =
  (fetchJson) => (store) => (next) => (action) => {
    debug(`inside cacheMiddleware action: ${action.type}, ${getQueryFromGCache.type}`);
    if (action.type === getRecordFromGCache.type) {
      onGetRecordFromGCache(fetchJson, store.getState().cache, next, action.payload);
    } else if (action.type === getQueryFromGCache.type) {
      onGetQueryFromGCache(fetchJson, store.getState().cache, next, action.payload);
    } else if (action.type === gCacheSweep.type) {
      doGCacheSweep(store.getState().cache, next);
    } else {
      next(action);
    }
  };

const onGetRecordFromGCache = (fetchJson: FetchJson, cache: GCacheState, next: Dispatch, payload: GetRecordPayload) => {
  const now = Date.now();
  const item = cache.records[makeRecordGCacheKey(payload.entity.id, payload.key)];
  const cacheHit = !!item && item.expiresAt > now;
  debug(`getting record ${payload.entity.id}/${payload.key} from cache: ${cacheHit}, ${item?.expiresAt} >? ${now}`);
  if (!cacheHit) {
    onRecordGCacheMiss(fetchJson, cache, next, payload);
  }
};

const onRecordGCacheMiss = (fetchJson: FetchJson, cache: GCacheState, next: Dispatch, payload: GetRecordPayload) => {
  if (payload.entity.retrieveAllDataInSingleQuery) {
    onGetQueryFromGCache(fetchJson, cache, next, {
      entity: payload.entity,
      query: {},
    });
    return;
  }
  next(
    addRecordToGCache({
      entityId: payload.entity.id,
      key: payload.key,
    })
  );
  debug(`calling fetchApi...`);
  fetchJson("GET", `/${payload.entity.service}/${payload.entity.id}/${encodeURIComponent(payload.key)}`)
    .then((data) => {
      debug(`return from fetchApi... with data: ${data?.length}`);
      next(
        setRecordDataInGCache({
          entityId: payload.entity.id,
          key: payload.key,
          data,
          expiresAt: Date.now() + payload.entity.timeToLiveRecordMillis,
        })
      );
    })
    .catch((err) => {
      console.error(err);
      next(
        setRecordErrorInGCache({
          entityId: payload.entity.id,
          key: payload.key,
          error: displayReduxTKError(err),
        })
      );
    });
};

const onGetQueryFromGCache = (fetchJson: FetchJson, cache: GCacheState, next: Dispatch, payload: GetQueryPayload) => {
  debug("inside getQuery...");
  const now = Date.now();
  const queryStr = JSON.stringify(payload.query);
  const item = cache.queries[makeQueryGCacheKey(payload.entity.id, queryStr)];
  const cacheHit = !!item && item.expiresAt > now;
  debug(`getting query ${payload.entity.id}/{...} from cache: ${cacheHit}, ${item?.expiresAt} >? ${now}`);
  if (cacheHit) {
    debug(`data already exists: ${cache.queries[makeQueryGCacheKey(payload.entity.id, queryStr)]?.data?.length}`);
  }
  if (!cacheHit) {
    onQueryGCacheMiss(fetchJson, next, payload, queryStr);
  }
};

const onQueryGCacheMiss = (fetchJson: FetchJson, next: Dispatch, payload: GetQueryPayload, queryStr: string) => {
  next(
    addQueryToGCache({
      entityId: payload.entity.id,
      query: queryStr,
    })
  );
  debug(`calling fetchApi...`);
  fetchJson("GET", `/${payload.entity.service}/${payload.entity.id}?q=${encodeURIComponent(queryStr)}`)
    .then((data) => {
      debug(`return from fetchApi... with data: ${data?.data?.length}`);
      next(
        setQueryDataInGCache({
          entityId: payload.entity.id,
          query: queryStr,
          data: data.data,
          expiresAt: Date.now() + payload.entity.timeToLiveQueryMillis,
        })
      );
      data?.data?.forEach((record) => {
        debug(`dispatching setRecordDataInCache for ${payload.entity.id} with key ${payload.entity.getRowKey(record)}`);
        next(
          setRecordDataInGCache({
            entityId: payload.entity.id,
            key: payload.entity.getRowKey(record),
            data: record,
            expiresAt: Date.now() + payload.entity.timeToLiveRecordMillis,
          })
        );
      });
    })
    .catch((err) => {
      console.error(err);
      next(
        setQueryErrorInGCache({
          entityId: payload.entity.id,
          query: queryStr,
          error: displayReduxTKError(err),
        })
      );
    });
};

/**
 * This approach will remove cached items even if still in use in the page. This
 * causes an "infinite spinner" as nothing triggers a reload of these items.
 */
const doGCacheSweep = (cache: GCacheState, next: Dispatch) => {
  debug(`starting cache sweep... queries: ${Object.values(cache.queries).length}`);
  let count = 0;
  const now = Date.now();
  Object.values(cache.queries).forEach((item) => {
    debug(`sweep: checking query ${item.entityId}: ${item.expiresAt} < ${now} ? ${item.expiresAt < now}`);
    if (item.expiresAt < now) {
      debug(`yes! removing...`);
      count += 1;
      next(
        clearSingleQueryInGCache({
          entityId: item.entityId,
          query: item.query,
        })
      );
    }
  });
  Object.values(cache.records).forEach((item) => {
    debug(`sweep: checking record ${item.entityId}/${item.key}: ${item.expiresAt} < ${now} ? ${item.expiresAt < now}`);
    if (item.expiresAt < now) {
      count += 1;
      next(
        clearSingleRecordInGCache({
          entityId: item.entityId,
          key: item.key,
        })
      );
    }
  });
  debug(`finishing cache sweep... ${count}`);
};
