import { Entity, FieldType, FrontEndOptions, getUuid, PersistenceType } from "packages/gossamer-universal";

export interface OrganisationRow {
  id: string;
  name: string;
  createdBy: string;
  image: string;
}

export const Organisation: Entity<OrganisationRow> = {
  id: "Organisation",
  title: "Organisation",
  service: "main",
  rowLabel: (row: OrganisationRow) => row.name,
  primaryKeyFields: ["id"],
  security: [{ role: "admin", action: "all", access: "all" }],
  defaultSort: [["name", "ASC"]],
  defaultFilters: [{ fieldId: "name", operator: "ct", value: "" }],
  frontEndOptions: {
    listRowLink: "/organisation/display/{key}",
    autoCompleterSearchExpression: {
      and: [
        {
          fieldId: "name",
          operator: "eq",
          value: "[[SEARCH_TERM]]",
        },
      ],
    },
  } as FrontEndOptions<OrganisationRow>,
  fields: {
    id: {
      seq: 5,
      type: FieldType.Text,
      label: "Id",
      persistence: PersistenceType.FrontEndGenerated,
      mandatory: true,
      visible: false,
      evaluateFrontEndCreate: () => getUuid(),
    },
    name: {
      seq: 10,
      type: FieldType.Text,
      label: "Name",
      listColumn: true,
      mandatory: true,
      maxLength: 80,
    },
    createdBy: {
      seq: 20,
      type: FieldType.Text,
      // can't make this a Reference entity on User as seems to create a infinite reference issue
      label: "Created By",
      persistence: PersistenceType.BackEndManaged,
    },
    image: {
      seq: 30,
      type: FieldType.Image,
      label: "Image",
    },
  },
};
