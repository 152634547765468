import React from "react";

type KeyboardEventHandler = (event: KeyboardEvent) => void;

export function useEventListener(
  eventName: "keyup" | "keydown" | "keypress",
  handler: KeyboardEventHandler
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef<KeyboardEventHandler>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      window.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener);
      };
    },
    [eventName] // Re-run if eventName changes
  );
}
