import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import { Button, Stack } from "react-bootstrap";
import { DisplayToast } from "packages/gossamer-react-redux";
import { Logo } from "front-end/shared/Logo";

export const PageFrame = (): JSX.Element => {
  return (
    <div style={{ marginBottom: 120 }}>
      <header
        style={{
          backgroundColor: "var(--bs-dark)",
          borderBottom: "unset",
          minHeight: 58,
        }}
      >
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Logo />
          <Stack
            direction="horizontal"
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Link to="/signup">
              <Button variant="link">Register</Button>
            </Link>
            <Link to="/signin">
              <Button variant="link">Login</Button>
            </Link>
          </Stack>
        </Stack>
      </header>
      <Outlet />
      <DisplayToast />
    </div>
  );
};
