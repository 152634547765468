import Debug from "debug";
import * as React from "react";
import { Entity, getRowDataFromKey, Row } from "packages/gossamer-universal";
import { displayReduxTKError, Transaction, TransFieldSet, TransRow, useRecord, useUpdateRow } from "../index";

const debug = Debug("gfe/se/UpdateWrapper");

interface UpdateWrapperProps<T extends Row> {
  createOnNotFound?: boolean;
  entity: Entity<T>;
  fieldGroup?: string;
  keyValue: string | null | undefined;
  onAddRow?: (row: TransRow<T>) => void;
  transaction: Transaction;
}

type UpdateWrapperItemFoundProps<T extends Row> = UpdateWrapperProps<T> & {
  data: T;
};

const UpdateWrapperFound = <T extends Row>(props: UpdateWrapperItemFoundProps<T>): JSX.Element => {
  const row = useUpdateRow(props.transaction, props.entity, props.data, props.onAddRow);
  return <TransFieldSet row={row} fieldGroup={props.fieldGroup} />;
};

type UpdateWrapperErrorProps<T extends Row> = UpdateWrapperProps<T> & {
  error: any;
};

const UpdateWrapperNotFound = <T extends Row>(props: UpdateWrapperErrorProps<T>): JSX.Element => {
  const [row, setRow] = React.useState<TransRow<T>>(null);
  const [outError, setOutError] = React.useState<any>(null);
  React.useEffect(() => {
    debug(`calling makeCreateRow(), ${props.error}, ${props.createOnNotFound}`);
    if (props.error === "not found" && props.createOnNotFound) {
      const tempRow = props.transaction.makeCreateRow<T>(props.entity);
      const keyData = getRowDataFromKey(props.entity, props.keyValue);
      Object.keys(keyData).forEach((fieldId) => tempRow.setFieldValue(fieldId, keyData[fieldId]));
      if (props.onAddRow) {
        props.onAddRow(tempRow);
      }
      setRow(tempRow);
    } else {
      setOutError(props.error);
    }
  }, []);
  return (
    <>
      {row && <TransFieldSet row={row} fieldGroup={props.fieldGroup} />}
      {outError && <div>{displayReduxTKError(outError)}</div>}
    </>
  );
};

export const UpdateWrapper = <T extends Row>(props: UpdateWrapperProps<T>): JSX.Element => {
  const { data, error, isFetching } = useRecord(props.entity, props.keyValue);
  debug(`inside UpdateWrapper`, props.entity.id, props.keyValue, data, error);
  return (
    <>
      {data && <UpdateWrapperFound {...props} data={data} />}
      {error && <UpdateWrapperNotFound {...props} error={error} />}
      {isFetching && <div>Loading...</div>}
    </>
  );
};
