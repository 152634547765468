import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import { Button, Stack } from "react-bootstrap";
import { DisplayToast, NavIcons, UserButton } from "../index";

export const PageFrame = (): JSX.Element => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false);
  const className = "pageframe mobileSidebar" + (mobileSidebarOpen ? "Open" : "Closed");
  const toggleMobileSidebar = () => setMobileSidebarOpen(!mobileSidebarOpen);
  return (
    <div className={className}>
      <header>
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          <Stack direction="horizontal" gap={1}>
            <Button size="sm" className="mobileOnly" onClick={toggleMobileSidebar}>
              #
            </Button>
            <Link to="/">
              <Button size="sm">Home</Button>
            </Link>
          </Stack>
          <Stack direction="horizontal" gap={1}>
            <NavIcons />
            <UserButton />
          </Stack>
        </Stack>
      </header>
      <Outlet />
      <DisplayToast />
    </div>
  );
};
