import * as React from "react";
import { useParams } from "react-router-dom";
import {
  CreateWrapper,
  PageProps,
  PageTitle,
  Section,
  TransButtons,
  useRecord,
  useTrans,
} from "packages/gossamer-react-redux";
import { Event, EventRow } from "model";
import { cloneEntity } from "packages/gossamer-universal";
import { Spinner } from "react-bootstrap";

const localEvent = cloneEntity(Event);

export const EventCopy = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const params = useParams();
  const { data, error, isFetching } = useRecord(Event, params.key);
  return (
    <InnerFrame>
      <PageTitle title="Copy your Event" />
      {isFetching && <Spinner animation="border" size="sm" />}
      {!!error && <div>An error occurred, please try again</div>}
      {!!data && <EventCopyInner eventRow={data} />}
    </InnerFrame>
  );
};

interface EventCopyInnerProps {
  eventRow: EventRow;
}

const EventCopyInner = (props: EventCopyInnerProps) => {
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/event/organising",
  });
  const copyData = React.useMemo(() => {
    const out = { ...props.eventRow };
    delete out.id;
    delete out.createdAt;
    delete out.reserved;
    return out;
  }, []);
  return (
    <>
      <Section>
        <CreateWrapper data={copyData} entity={localEvent} transaction={trans} />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </>
  );
};
