import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { Four04 } from "packages/gossamer-react-redux";
import { Home } from "./Home";
import { InnerFrame } from "./InnerFrame";
import { EventRoutes } from "../event/Routes";
import { OrganisationRoutes } from "../organisation/Routes";
import { UserRoutes } from "../user/Routes";
import { PageFrame } from "./PageFrame";
import { About } from "front-end/shared/About";
import { HowItWorks } from "front-end/shared/HowItWorks";
import { ContactUs } from "front-end/shared/ContactUs";

export const LoggedInRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<PageFrame />}>
        <Route index element={<Home innerFrame={InnerFrame} />} />
        <Route path="/about" element={<About innerFrame={InnerFrame} />} />
        <Route path="/contact" element={<ContactUs innerFrame={InnerFrame} />} />
        <Route path="/how-it-works" element={<HowItWorks innerFrame={InnerFrame} />} />
        <Route path="/event/*" element={<EventRoutes innerFrame={InnerFrame} />} />
        <Route path="/organisation/*" element={<OrganisationRoutes innerFrame={InnerFrame} />} />
        <Route path="/user/*" element={<UserRoutes innerFrame={InnerFrame} />} />
        <Route path="*" element={<Four04 innerFrame={InnerFrame} />} />
      </Route>
    </Routes>
  );
};
