import * as React from "react";
import { Card, Col as BCol, Row as BRow } from "react-bootstrap";
import {
  ColumnWidths,
  Field,
  FieldId,
  getFieldArray,
  getRowKey,
  getSectionColumnWidths,
  Row,
} from "packages/gossamer-universal";
import { ErrorBoundary } from "../ancillary/ErrorBoundary";
import { General } from "../fields/General";
import { SubSectionMultiProps } from "../data/QueryMulti";

export interface DataTilesProps<T extends Row> extends SubSectionMultiProps<T> {
  columnWidths?: ColumnWidths;
  hideFieldLabels?: boolean;
}

interface RenderFieldProps {
  fieldId: FieldId;
  field: Field;
  hideFieldLabels?: boolean;
  value: string;
}

const RenderField = (props: RenderFieldProps): JSX.Element => {
  if (props.hideFieldLabels) {
    return <RenderFieldSub {...props} key={props.fieldId} />;
  }
  return (
    <div id={props.fieldId} key={props.fieldId} className="fieldBlock">
      {!props.hideFieldLabels && <div>{props.field.label}</div>}
      <b>
        <RenderFieldSub {...props} />
      </b>
    </div>
  );
};

const RenderFieldSub = (props: RenderFieldProps): JSX.Element => (
  <General editable={false} fieldId={props.fieldId} field={props.field} value={props.value} />
);

export const DataTiles = <T extends {}>(props: DataTilesProps<T>): JSX.Element => {
  const children = props.rows
    .filter((row) => !props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1)
    .map((row: any) => {
      const key = getRowKey(props.entity, row);
      return (
        <BCol key={key} {...getSectionColumnWidths(props.columnWidths)}>
          <Card>
            <Card.Body>
              {getFieldArray(props.entity)
                .filter(
                  ([field, fieldId]: [Field, string]) =>
                    field.visible !== false && (!!row[fieldId] || field.showEvenIfBlank)
                )
                .filter(([field]: [Field, string]) => field.listColumn)
                .map(([field, fieldId]: [Field, string]) => (
                  <RenderField
                    fieldId={fieldId}
                    field={field}
                    hideFieldLabels={props.hideFieldLabels}
                    key={fieldId}
                    value={row[fieldId]}
                  />
                ))}
            </Card.Body>
          </Card>
        </BCol>
      );
    });
  if (props.rows.length === 0) {
    return null;
  }
  return (
    <ErrorBoundary>
      <BRow className="mb20px">{children}</BRow>
    </ErrorBoundary>
  );
};
