import { cloneDeep } from "lodash";
import { FieldId, Primitive, Row } from "../types/core";
import { DataRowAction } from "../types/data";
import { Entity, Field, FieldType, IntegerField, PersistenceType } from "../types/entity";
import { getLocalDateString, isBlank } from "./core";

export const KEY_SEPARATOR = "|";

export const cloneEntity = <T extends Row>(entity: Entity<T>): Entity<T> => cloneDeep(entity);

export const cloneField = (field: Field): Field => cloneDeep(field);

export const createData = <T extends Row>(entity: Entity<T>): T => {
  const out = {};
  Object.keys(entity.fields).forEach((fieldId: FieldId) => {
    const field = entity.fields[fieldId];
    const defaultVal =
      field.defaultVal !== undefined ? field.defaultVal : field.type === FieldType.Boolean ? false : null;
    out[fieldId] = defaultVal;
    if (field.evaluateFrontEndCreate) {
      out[fieldId] = field.evaluateFrontEndCreate();
    }
  });
  return out as T;
};

export const getField = <T extends Row>(entity: Entity<T>, fieldId: FieldId): Field => entity.fields[fieldId];

export const getFieldArray = <T extends Row>(entity: Entity<T>): [Field, FieldId][] => {
  const out: [Field, FieldId][] = Object.keys(entity.fields).map((fieldId: FieldId) => [
    entity.fields[fieldId],
    fieldId,
  ]);
  out.sort((a: [Field, FieldId], b: [Field, FieldId]) => a[0].seq - b[0].seq);
  return out as [Field, FieldId][];
};

export const getFieldPrimitiveFromString = (fieldType: FieldType, value: string): Primitive => {
  if (fieldType === FieldType.Boolean) {
    return value === "true";
  }
  if ([FieldType.Decimal, FieldType.Integer].indexOf(fieldType) > -1) {
    const num = parseFloat(value);
    return Number.isFinite(num) ? num : null;
  }
  if (value === "") {
    return null;
  }
  return value;
};

export const getFieldStringFromPrimitive = (fieldType: FieldType, value: Primitive): string => {
  if (fieldType === FieldType.Boolean && typeof value === "boolean") {
    return value ? "true" : "false";
  }
  if (!value) {
    return "";
  }
  if (fieldType === FieldType.Date) {
    return getLocalDateString(new Date(value as number));
  }
  if (fieldType === FieldType.Datetime) {
    const d = new Date(value as number);
    return (
      getLocalDateString(d) +
      "T" +
      d
        .toLocaleTimeString([], {
          hour12: false,
        })
        .substring(0, 5)
    );
  }
  return String(value);
};

export const getTitlePlural = (entity: Entity<{}>) => entity.pluralTitle || `${entity.title}s`;

export const getRowDataFromKey = <T extends Row>(entity: Entity<T>, key: string): Partial<T> => {
  const keyParts = key.split(KEY_SEPARATOR);
  if (keyParts.length !== entity.primaryKeyFields.length || keyParts.find((part) => !part)) {
    throw new Error(`invalid key: ${key} for entity ${entity.id}`);
  }
  const out = {};
  entity.primaryKeyFields.forEach((fieldId, index) => {
    out[fieldId] = keyParts[index];
  });
  return out;
};

export const getRowKey = <T extends Row>(entity: Entity<T>, data: T): string => {
  return entity.primaryKeyFields
    .map((fieldId) => {
      const valueAny = data[fieldId];
      const keyPart = isBlank(valueAny)
        ? undefined
        : entity.fields[fieldId].type === FieldType.Datetime
        ? new Date(valueAny).toJSON()
        : String(valueAny);
      if (!keyPart) {
        throw new Error(`blank key part from field ${String(fieldId)} of entity ${entity.id}`);
      }
      return keyPart;
    })
    .join(KEY_SEPARATOR);
};

export const isAllowAddRows = <T extends Row>(entity: Entity<T>) => entity.allowAddRows !== false;

export const isAllowDeleteRows = <T extends Row>(entity: Entity<T>) => entity.allowDeleteRows !== false;

export const isFieldEditable = (field: Field, rowAction: DataRowAction): boolean => {
  if (typeof field.editable === "boolean") {
    return field.editable;
  }
  if (field.persistence === PersistenceType.SetOnCreateOnly) {
    return rowAction === "C";
  }
  if (
    [PersistenceType.BackEndManaged, PersistenceType.BackEndOnly, PersistenceType.FrontEndGenerated].indexOf(
      field.persistence || PersistenceType.Normal
    ) > -1
  ) {
    return false;
  }
  return true;
};

export const isFieldFilterable = (field: Field): boolean => {
  if (typeof field.filterable === "boolean") {
    return field.filterable;
  }
  if (
    !!field.evaluateFrontEndIn ||
    !!field.evaluateBackEndIn ||
    !!field.evaluateBackEndOut /* || !!field.encrypted */
  ) {
    return false;
  }
  return [FieldType.File, FieldType.Flex].indexOf(field.type) === -1;
};

export const isFieldSortable = (field: Field): boolean => {
  if (typeof field.sortable === "boolean") {
    return field.sortable;
  }
  if (!!field.evaluateFrontEndIn || !!field.evaluateBackEndIn || !!field.evaluateBackEndOut || !!field.encrypted) {
    return false;
  }
  if (field.type === FieldType.Reference) {
    return field.refEntity.primaryKeyFields.length === 1;
  }
  return [FieldType.File, FieldType.Flex, FieldType.Richtext].indexOf(field.type) === -1;
};

export const isKeyComplete = <T extends Row>(entity: Entity<T>, data: T): boolean => {
  return entity.primaryKeyFields.reduce((prev: boolean, curr: string) => (prev && !!data[curr]) || false, true);
};

export const isKeyField = <T extends Row>(entity: Entity<T>, fieldId: FieldId): boolean => {
  return entity.primaryKeyFields.indexOf(fieldId) > -1;
};

export const makeClone = () => {
  let seq = 0;
  return (field: Field, overrideListCol?: boolean, overrideLabel?: string): Field => {
    seq += 5;
    const out = Object.assign({}, field, { seq });
    delete (out as IntegerField).autoIncrement;
    if (typeof overrideListCol === "boolean") {
      out.listColumn = overrideListCol;
    }
    if (overrideLabel) {
      out.label = overrideLabel;
    }
    return out;
  };
};
