import Debug from "debug";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAuthProvider, isSignedIn } from "../index";

const debug = Debug("gfe/au/Auth");

interface AuthProps {
  loggedInRoutes: () => JSX.Element;
  notLoggedInRoutes: () => JSX.Element;
}

export const Auth = (props: AuthProps): JSX.Element => {
  const isUserSignedIn = useSelector(isSignedIn)();
  const LoggedInRoutes = props.loggedInRoutes;
  const NotLoggedInRoutes = props.notLoggedInRoutes;

  debug(`Auth isSignedIn?`, isUserSignedIn);

  useEffect(() => {
    debug(`Auth load useEffect()`);
    getAuthProvider().onload();
  }, []);

  if (isUserSignedIn) {
    return <LoggedInRoutes />;
  }
  return <NotLoggedInRoutes />;
};
