import Debug from "debug";

export type ConfigKey = string;

const debug = Debug("gfe/an/ConfigManager");

const values: Record<ConfigKey, string> = {
  "api-base-url": null,
  "built-at": null,
  "login-bypass": null,
  "bs-theme": "dark",
  "org-path": null,
  "role-id": null,
  "user-id": null,
};

Object.keys(values).forEach((key) => {
  const overrideValue = localStorage.getItem(key);
  if (overrideValue) {
    values[key] = overrideValue;
  }
});

debug(values);

export const getConfig = (key: ConfigKey) => values[key];

export const setConfig = (key: ConfigKey, value: string) => (values[key] = value);

if (typeof window !== "undefined") {
  window.document.querySelector("html").setAttribute("data-bs-theme", getConfig("bs-theme"));
}
