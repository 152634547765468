import * as React from "react";
import { Button, InputGroup, Offcanvas } from "react-bootstrap";
import { OptionsField } from "packages/gossamer-universal";
import { ChecksGroup, getConfig } from "packages/gossamer-react-redux";
import { Event, EventRow } from "../../model/Event";

interface MainFiltersProps {
  render: ({ rows }: { rows: EventRow[] }) => JSX.Element;
  rows: EventRow[];
}

export const MainFilters = (props: MainFiltersProps): JSX.Element => {
  const [showFilters, setShowFilters] = React.useState(false);
  const [eventType, setEventType] = React.useState("");
  const [distributionType, setDistributionType] = React.useState("");
  const [freePaid, setFreePaid] = React.useState("");
  const [orgMode, setOrgMode] = React.useState("");
  const [specialism, setSpecialism] = React.useState("");
  const [grade, setGrade] = React.useState("");
  const [otherCharacteristics, setOtherCharacteristics] = React.useState("");

  React.useEffect(() => {
    console.log(`mounting MainFilters`);
  }, []);

  const handleDone = () => {
    setShowFilters(false);
  };

  const userOrg = getConfig("org-path");
  const Render = props.render;
  const rowsOut = props.rows
    .filter((row: EventRow) => {
      return (
        (!eventType || eventType.split(",").indexOf(row.type) > -1) &&
        (!distributionType || distributionType.split(",").indexOf(row.distributionType) > -1) &&
        (!freePaid ||
          (freePaid.split(",").indexOf("free") > -1 && (!row.price || String(row.price) == "0")) ||
          (freePaid.split(",").indexOf("paid") > -1 && !!row.price && String(row.price) != "0")) &&
        (!orgMode || orgMode === "any" || userOrg === row.organisation) &&
        (!specialism || specialism.split(",").indexOf(row.specialism) > -1) &&
        (!grade || grade.split(",").indexOf(row.grade) > -1) &&
        (!otherCharacteristics ||
          (otherCharacteristics.split(",").indexOf("ALLOW-ORAL-PRESENTATION") > -1 && row.allowsOralPresentation) ||
          (otherCharacteristics.split(",").indexOf("ALLOW-POSTER-PRESENTATION") > -1 && row.allowsPosterPresentation) ||
          (otherCharacteristics.split(",").indexOf("ALLOW-CLINICAL-AUDIT-PRESENTATION") > -1 &&
            row.clinicalAuditPresentations !== "NONE") ||
          (otherCharacteristics.split(",").indexOf("ALLOW-CO-TUTOR") > -1 && row.acceptCoTutorOffers))
      );
    })
    .slice();

  return (
    <>
      <div style={{ position: "fixed", top: 60, right: 10, zIndex: 20 }}>
        <img
          src="/filters.svg"
          onClick={setShowFilters.bind(null, true)}
          style={{
            paddingRight: 5,
          }}
        />
      </div>
      <Offcanvas show={showFilters} onHide={setShowFilters.bind(null, false)} placement="end">
        <Offcanvas.Header closeButton>
          <h1>Filters</h1>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <InputGroup className="mb10px" id="sc-eventType">
            <InputGroup.Text>Event Type</InputGroup.Text>
            <ChecksGroup
              inline={false}
              options={(Event.fields.type as OptionsField).options}
              onItemSelected={setEventType}
              mandatory={false}
              type="checkbox"
              value={eventType}
            />
          </InputGroup>
          <InputGroup className="mb10px" id="sc-distributionType">
            <InputGroup.Text>Hosting Type</InputGroup.Text>
            <ChecksGroup
              inline={false}
              options={(Event.fields.distributionType as OptionsField).options}
              onItemSelected={setDistributionType}
              mandatory={false}
              type="checkbox"
              value={distributionType}
            />
          </InputGroup>
          <InputGroup className="mb10px" id="sc-freePaid">
            <InputGroup.Text>Free / Paid?</InputGroup.Text>
            <ChecksGroup
              inline={true}
              options={[
                {
                  id: "free",
                  label: "Free",
                },
                {
                  id: "paid",
                  label: "Paid",
                },
              ]}
              onItemSelected={setFreePaid}
              mandatory={false}
              type="checkbox"
              value={freePaid}
            />
          </InputGroup>

          {!!userOrg && (
            <InputGroup className="mb10px" id="searchCriterion-org">
              <ChecksGroup
                mandatory={true}
                inline={true}
                type="radio"
                onItemSelected={setOrgMode}
                options={[
                  {
                    id: "any",
                    label: "available from any organisation",
                  },
                  { id: "user", label: "from my organisation only" },
                ]}
                value={orgMode}
              />
            </InputGroup>
          )}
          <InputGroup className="mb10px" id="sc-specialism">
            <InputGroup.Text>Specialty</InputGroup.Text>
            <ChecksGroup
              inline={false}
              options={(Event.fields.specialism as OptionsField).options}
              onItemSelected={setSpecialism}
              mandatory={false}
              type="checkbox"
              value={specialism}
            />
          </InputGroup>
          <InputGroup className="mb10px" id="sc-grade">
            <InputGroup.Text>Grade</InputGroup.Text>
            <ChecksGroup
              inline={false}
              options={(Event.fields.grade as OptionsField).options}
              onItemSelected={setGrade}
              mandatory={false}
              type="checkbox"
              value={grade}
            />
          </InputGroup>
          <InputGroup className="mb10px" id="sc-otherCharacteristics">
            <ChecksGroup
              inline={false}
              options={[
                {
                  id: "ALLOW-ORAL-PRESENTATION",
                  label: "opportunity of oral presentation",
                },
                {
                  id: "ALLOW-POSTER-PRESENTATION",
                  label: "opportunity of poster presentation",
                },
                {
                  id: "ALLOW-CLINCAL-AUDIT-PRESENTATION",
                  label: "opportunity of clinical audit presentation",
                },
                {
                  id: "ALLOW-CO-TUTOR",
                  label: "opportunity to co-tutor",
                },
              ]}
              onItemSelected={setOtherCharacteristics}
              mandatory={false}
              type="checkbox"
              value={otherCharacteristics}
            />
          </InputGroup>
          <div>
            <Button onClick={handleDone}>Done</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Render rows={rowsOut} />{" "}
    </>
  );
};
