import Debug from "debug";
import * as React from "react";
import { useNavigate } from "react-router";
import { getListRowLink, getRowKey, Primitive, Row } from "packages/gossamer-universal";
import {
  ErrorBoundary,
  General,
  SubSectionMultiProps,
  SubSectionSingleProps,
  updateListManagerWithRows,
} from "../index";
import styles from "./Tree.module.css";

const debug = Debug("gfe/se/Tree");

interface TreeProps<T extends Row> extends SubSectionMultiProps<T> {
  displayFieldId: string;
  parentFieldId: string;
  renderNode?: (props: SubSectionSingleProps<T>) => JSX.Element;
}

export const Tree = <T extends {}>(props: TreeProps<T>): JSX.Element => {
  const navigate = useNavigate();
  const displayField = props.entity.fields[props.displayFieldId];
  const isRowLinks = !!props.entity.frontEndOptions?.listRowLink || !!props.onRowClick;
  const handleRowLinkClick = (key: string) => {
    if (props.onRowClick) {
      props.onRowClick(key);
    } else if (props.entity.frontEndOptions?.listRowLink) {
      if (props.rowClickSetsSearchPaths) {
        updateListManagerWithRows(props.entity, props.rows);
      }
      navigate(getListRowLink(props.entity.frontEndOptions?.listRowLink, key));
    }
  };

  const makeTestRow = (nodeId: Primitive) => (row: T) =>
    String(row[props.parentFieldId]) === String(nodeId) &&
    (!props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1);

  const getChildRows = (nodeId: Primitive) => {
    const testRow = makeTestRow(nodeId);
    return props.rows.filter(testRow);
  };

  const hasChildren = (nodeId: Primitive) => {
    const testRow = makeTestRow(nodeId);
    return !!props.rows.find(testRow);
  };

  const RenderNodeDefault = (innerProps: SubSectionSingleProps<T>): JSX.Element => (
    <General
      editable={false}
      fieldId={props.displayFieldId}
      field={displayField}
      suppressLink={isRowLinks}
      value={innerProps.row[props.displayFieldId]}
    />
  );

  const RenderNode = props.renderNode || RenderNodeDefault;

  const renderNode = (row: T) => {
    const key = getRowKey(props.entity, row);
    return (
      <div key={key}>
        <div onClick={() => handleRowLinkClick(key)} className={styles.TreeLink}>
          <RenderNode entity={props.entity} keyValue={key} row={row} />
        </div>
        {hasChildren(key) && renderChildren(key)}
      </div>
    );
  };

  const renderChildren = (parentId: Primitive) => {
    const rows = getChildRows(parentId);
    const children = rows.map((row) => {
      const key = getRowKey(props.entity, row);
      return <li key={key}>{renderNode(row)}</li>;
    });

    return <ul>{children}</ul>;
  };

  const topLevelNodes = getChildRows(null);
  return <ErrorBoundary>{topLevelNodes.map((node) => renderNode(node))}</ErrorBoundary>;
};
