import Debug from "debug";
import { getDataProvider, handleSignIn, handleSignOut } from "packages/gossamer-react-redux";
import { User } from "../../model/User";
import { deduceNameFromEmail } from "packages/gossamer-universal";

const debug = Debug("model/Auth");

const ADMINS = ["stephen.d.francis@gmail.com", "jameswallner@ymail.com"];

export const getRoleFromUserId = (userId: string): string => (ADMINS.indexOf(userId) === -1 ? "normal" : "admin");

const createNewUserRecord = async (userId: string, displayName: string) => {
  const name = (displayName || deduceNameFromEmail(userId)).split(" ");
  await getDataProvider().createRow(User, {
    emailAddress: userId,
    firstName: name[0],
    lastName: name.length > 1 ? name[1] : "",
  });
  return await getDataProvider().getRecord(User, userId);
};

const getUserRecordOrCreateIfMissing = async (userId: string, displayName: string) => {
  const userRow = (await getDataProvider().getRecord(User, userId)) || (await createNewUserRecord(userId, displayName));
  return userRow?.organisation || null;
};

export const onAlreadySignedIn = async (userId: string, displayName: string) => {
  const organisationId = await getUserRecordOrCreateIfMissing(userId, displayName);
  debug(`onAlreadySignedIn`, userId, displayName, organisationId);
  handleSignIn(userId, getRoleFromUserId(userId), organisationId, displayName, userId);
};

export const onSuccessfulSignIn = async (userId: string, displayName: string) => {
  const organisationId = await getUserRecordOrCreateIfMissing(userId, displayName);
  debug(`onSuccessfulSignIn`, userId, displayName, organisationId);
  handleSignIn(userId, getRoleFromUserId(userId), organisationId, displayName, userId);
};

export const onSuccessfulSignUp = async (userId: string, displayName: string) => {
  debug(`onSuccessfulSignUp`, userId, displayName);
  await createNewUserRecord(userId, displayName);
};

export const onSignOut = async () => {
  handleSignOut();
};
