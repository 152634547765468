import Debug from "debug";
import * as React from "react";

const error = Debug("error");

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(errorArg: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    error(errorArg, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>Render Error</div>;
    }
    return this.props.children;
  }
}
