import * as React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IsAllowed, PageProps, PageTitle, QueryMultiURLParams, Search, Section } from "packages/gossamer-react-redux";
import { User } from "model";

export const UserSearch = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  return (
    <InnerFrame>
      <PageTitle verb="Search" entity={User} />
      <Section>
        <QueryMultiURLParams entity={User} render={Search} />
        <IsAllowed entity={User} action="create">
          <Link to="/user/create">
            <Button>Create</Button>
          </Link>
        </IsAllowed>
      </Section>
    </InnerFrame>
  );
};
