import * as React from "react";
import {
  getConfig,
  PageProps,
  PageTitle,
  Section,
  TransButtons,
  UpdateWrapper,
  useTrans,
} from "packages/gossamer-react-redux";
import { User } from "../../model";

export const ProfileMainUpdate = (props: PageProps): JSX.Element => {
  const InnerFrame = props.innerFrame;
  const { cancel, saveWithOutcome, trans } = useTrans({
    followOnURL: "/user/profile-main",
  });

  return (
    <InnerFrame>
      <PageTitle title="Update My Profile" subtitle="Information about me" />
      <Section>
        <UpdateWrapper transaction={trans} entity={User} keyValue={getConfig("user-id")} fieldGroup="main" />
      </Section>
      <TransButtons cancel={cancel} saveWithOutcome={saveWithOutcome} trans={trans} />
    </InnerFrame>
  );
};
