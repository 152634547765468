import * as React from "react";
import { getEntity, QuerySingle } from "../index";

interface DynamicTextElementProps {
  entityId: string;
  recordKey: string;
}

const DynamicTextElement = (props: DynamicTextElementProps): JSX.Element => {
  const entity = getEntity(props.entityId);
  return (
    <QuerySingle
      entity={entity}
      keyValue={props.recordKey}
      render={(props) => {
        const label = (
          entity.frontEndOptions?.rowLabelReference ||
          entity.frontEndOptions?.rowLabelDynamic ||
          entity.rowLabel
        )(props.row);
        return <DynamicText text={label} />;
      }}
    />
  );
};

interface DynamicTextProps {
  text: string;
}

export const DynamicText = (props: DynamicTextProps): JSX.Element => {
  const regex = /<(.+?):(.+?):(.+?)>/g;
  let result: RegExpExecArray;
  let lastIndex = 0;
  const out = [];
  while ((result = regex.exec(props.text)) !== null) {
    out.push(<span key={result.index}>{props.text.substring(lastIndex, result.index)}</span>);
    lastIndex = result.index + result[1].length + result[2].length + result[3].length + 4;
    if (result.length !== 4 || result[1] !== "lookup") {
      out.push(<span key="~invalid">invalid dynamic text</span>);
    } else {
      out.push(<DynamicTextElement entityId={result[2]} recordKey={result[3]} key={result[0]} />);
    }
  }
  out.push(<span key={lastIndex}>{props.text.substring(lastIndex)}</span>);
  return <>{out}</>;
};
