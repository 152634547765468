import * as React from "react";
import { Card, Col as BCol, Row as BRow } from "react-bootstrap";
import {
  ColumnWidths,
  Entity,
  Field,
  FieldId,
  getFieldArray,
  getSectionColumnWidths,
  isAllowDeleteRows,
  Row,
} from "packages/gossamer-universal";
import { ErrorBoundary, TransField, TransRow } from "../index";
import styles from "./TransTiles.module.css";
import { FieldBlock } from "./FieldBlock.module.css";

export interface TransTilesProps<T extends Row> {
  columnWidths?: ColumnWidths;
  deleteRow: (row: TransRow<T>) => void;
  entity: Entity<T>;
  renderTransTile?: (props: TransTileProps<T>) => JSX.Element;
  rows: TransRow<T>[];
}

export const TransTiles = <T extends {}>(props: TransTilesProps<T>): JSX.Element => {
  const sectionColumnWidths = getSectionColumnWidths(props.columnWidths);
  const children = props.rows.map((row: TransRow<T>) => (
    <BCol key={row.getRowNumber()} {...sectionColumnWidths}>
      <TransTile entity={props.entity} row={row} deleteRow={props.deleteRow} />
    </BCol>
  ));
  return (
    <ErrorBoundary>
      <BRow className="mb20px">{children}</BRow>
    </ErrorBoundary>
  );
};

export interface TransTileProps<T extends Row> {
  deleteRow: (row: TransRow<T>) => void;
  entity: Entity<T>;
  row: TransRow<T>;
}

export const TransTile = <T extends {}>(props: TransTileProps<T>): JSX.Element => {
  return (
    <Card className="tile">
      <Card.Body>
        {isAllowDeleteRows(props.entity) && (
          <div className={styles.TileDelete}>
            <button onClick={() => props.deleteRow(props.row)} className="ControlIcon">
              ✖
            </button>
          </div>
        )}
        {getFieldArray(props.entity)
          .filter(([field, fieldId]: [Field, FieldId]) => field.listColumn)
          .map(([field, fieldId]: [Field, FieldId]) => (
            <div id={fieldId} key={fieldId} className={FieldBlock}>
              <div>{field.label}</div>
              <TransField fieldId={fieldId} field={field} row={props.row} />
            </div>
          ))}
      </Card.Body>
    </Card>
  );
};
