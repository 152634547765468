import Debug from "debug";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  Field,
  Entity,
  getFieldArray,
  getFieldColumnAlign,
  getListRowLink,
  getRowKey,
  isFieldSortable,
  QueryOptions,
  Row,
  Sort,
  SortDirection,
  getFieldColumnStyle,
} from "packages/gossamer-universal";
import { ErrorBoundary, General, sortHeaderClicked, SubSectionMultiProps, updateListManagerWithRows } from "../index";
import styles from "./List.module.css";

const debug = Debug("gfe/se/List");

interface SortIconsProps {
  fieldId: string;
  sortHeaderClicked?: (fieldId: string, dir: SortDirection) => void;
  sorts?: Sort[];
}

const SortIcons = (props: SortIconsProps): JSX.Element => {
  const getExistingSort = (fieldId: string) => {
    const sort: Sort = props.sorts?.find((sort: Sort) => sort[0] === fieldId);
    if (sort) {
      return [props.sorts?.indexOf(sort), sort[1]];
    }
    return null;
  };

  const getHeaderClassName = (fieldId: string, dir: SortDirection) => {
    let out = "ControlIcon";
    const sort = getExistingSort(fieldId);
    if (sort && sort[1] === dir) {
      out += ` sort_${sort[0]}`;
    }
    return out;
  };

  return (
    <div className={styles.SortIcons}>
      <button
        onClick={props.sortHeaderClicked.bind(null, props.fieldId, "ASC")}
        className={getHeaderClassName(props.fieldId, "ASC")}
        title="sort ascending by this column"
      >
        ▲
      </button>
      <button
        onClick={props.sortHeaderClicked.bind(null, props.fieldId, "DESC")}
        className={getHeaderClassName(props.fieldId, "DESC")}
        title="sort descending by this column"
      >
        ▼
      </button>
    </div>
  );
};

export interface ListColumnHeaderProps<T extends Row> {
  entity: Entity<T>;
  field: Field;
  fieldId: string;
  queryOptions?: QueryOptions;
  updateQueryOptions?: (newQueryOptions: QueryOptions) => void;
}

export const ListColumnHeader = <T extends {}>(props: ListColumnHeaderProps<T>): JSX.Element => {
  const sortable =
    props.entity.sortable !== false && isFieldSortable(props.field) && props.queryOptions && !!props.updateQueryOptions;
  const style: any = {};
  if (props.field.listColumnWidth!) {
    style.width = props.field.listColumnWidth;
  }
  const className = `cellAlign-${getFieldColumnAlign(props.field)} colSortable-${sortable}`;
  return (
    <th key={props.fieldId}>
      <div style={style} className={className}>
        <span>{props.field.label}</span>
        {props.field.description && <a title={props.field.description}>ⓘ</a>}
        {sortable && (
          <SortIcons
            fieldId={props.fieldId}
            sortHeaderClicked={(fieldId: string, dir: SortDirection) =>
              props.updateQueryOptions(sortHeaderClicked(props.queryOptions, fieldId, dir))
            }
            sorts={props.queryOptions.order}
          />
        )}
      </div>
    </th>
  );
};

export interface ListCellProps<T extends {}> {
  field: Field;
  fieldId: string;
  isRowLinks: boolean;
  row: T;
}

export const ListCell = <T extends {}>(props: ListCellProps<T>): JSX.Element => {
  try {
    return (
      <td
        key={props.fieldId}
        style={getFieldColumnStyle(props.field, props.row)}
        className={`cellAlign-${getFieldColumnAlign(props.field)}`}
      >
        <ErrorBoundary>
          <General
            editable={false}
            fieldId={props.fieldId}
            field={props.field}
            suppressLink={props.isRowLinks}
            value={props.row[props.fieldId]}
            row={props.row}
          />
        </ErrorBoundary>
      </td>
    );
  } catch (e) {
    console.error(e);
    return <td></td>;
  }
};

interface ListProps<T extends Row> extends SubSectionMultiProps<T> {}

export const List = <T extends {}>(props: ListProps<T>): JSX.Element => {
  const navigate = useNavigate();
  const isRowLinks = !!props.entity.frontEndOptions?.listRowLink || !!props.onRowClick;
  const handleRowLinkClick = (key: string) => {
    if (props.onRowClick) {
      props.onRowClick(key);
    } else if (props.entity.frontEndOptions?.listRowLink) {
      if (props.rowClickSetsSearchPaths) {
        updateListManagerWithRows(props.entity, props.rows);
      }
      navigate(getListRowLink(props.entity.frontEndOptions?.listRowLink, key));
    }
  };

  const children = props.rows
    .filter((row) => !props.excludeValues || props.excludeValues.indexOf(getRowKey(props.entity, row)) === -1)
    .map((row: any) => {
      const key = getRowKey(props.entity, row);
      debug(
        `row ${key} ${Object.keys(row)}, ${getFieldArray(props.entity)
          .filter(([field, _]: [Field, string]) => field.listColumn)
          .map(([_, fieldId]) => fieldId)}`
      );
      const className = isRowLinks ? styles.RowLink : null;
      return (
        <tr key={key} onClick={() => handleRowLinkClick(key)} className={className}>
          {getFieldArray(props.entity)
            .filter(([field, _]: [Field, string]) => field.listColumn)
            .map(([field, fieldId]: [Field, string]) => (
              <ListCell key={fieldId} field={field} fieldId={fieldId} isRowLinks={isRowLinks} row={row} />
            ))}
        </tr>
      );
    });

  if (props.rows.length === 0) {
    return null;
  }
  return (
    <ErrorBoundary>
      <Table responsive striped bordered hover={isRowLinks}>
        <thead>
          <tr>
            {getFieldArray(props.entity)
              .filter(([field, _]: [Field, string]) => field.listColumn)
              .map(([field, fieldId]: [Field, string]) => (
                <ListColumnHeader
                  key={fieldId}
                  entity={props.entity}
                  field={field}
                  fieldId={fieldId}
                  queryOptions={props.queryOptions}
                  updateQueryOptions={props.updateQueryOptions}
                />
              ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </ErrorBoundary>
  );
};
