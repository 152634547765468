import Debug from "debug";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAuthProvider, selectIsSignedIn } from "../index";

const debug = Debug("gfe/au/Auth");

interface AuthProps {
  loggedInRoutes: () => JSX.Element;
  notLoggedInRoutes: () => JSX.Element;
}

export const Auth = (props: AuthProps): JSX.Element => {
  // no idea why seems necessary to supply the equality function...! make re-render go away...
  const isUserSignedIn = useSelector(selectIsSignedIn, (left, right) => left() === right())();
  const LoggedInRoutes = props.loggedInRoutes;
  const NotLoggedInRoutes = props.notLoggedInRoutes;

  debug(`isSignedIn?`, isUserSignedIn);

  useEffect(() => {
    debug(`Auth load useEffect()`);
    getAuthProvider().onload();
  }, []);

  if (isUserSignedIn) {
    return <LoggedInRoutes />;
  }
  return <NotLoggedInRoutes />;
};
