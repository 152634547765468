import * as React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { NotifyInfo, setNotifySubscriber } from "../index";

type NotifyInfoAndKey = NotifyInfo & {
  index: number;
};

let globalIndex: number = 0;

export const DisplayToast = (): JSX.Element => {
  const [toasts, setToasts] = React.useState<NotifyInfoAndKey[]>([]);
  setNotifySubscriber((info: NotifyInfo) => {
    const newToasts = toasts.slice();
    newToasts.push(
      Object.assign(
        {
          index: globalIndex++,
        },
        info
      )
    );
    setToasts(newToasts);
  });

  const onClose = (index: number) => {
    const newToasts = toasts.slice();
    newToasts.splice(
      toasts.findIndex((toast) => toast.index === index),
      1
    );
    setToasts(newToasts);
  };

  return (
    <div aria-live="polite" aria-atomic="true" style={{ position: "fixed", left: 0, right: 0, bottom: 20 }}>
      <ToastContainer position="bottom-center" style={{ position: "absolute" }}>
        {toasts.map((toast) => (
          <Toast
            autohide={true}
            show={true}
            bg={toast[1]}
            key={`${toast[0]}-${toast.index}`}
            delay={5000}
            onClose={onClose.bind(null, toast.index)}
          >
            <Toast.Body>{toast[0]}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </div>
  );
};
