import * as React from "react";
import styles from "./HomeBlock.module.css";

export const HomeBlock = (): JSX.Element => {
  return (
    <div className={styles.HomeBlock}>
      <div className={styles.HomeBlockMain}>Search, Book, Achieve</div>
      <div className={styles.HomeBlockSub}>Find or Create your next Medical Event</div>
    </div>
  );
};
