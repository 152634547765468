import Debug from "debug";
import { Entity, Row } from "packages/gossamer-universal";
import * as React from "react";
import { Transaction } from "./Transaction";
import { TransRow } from "./TransRow";

const debug = Debug("gfe/se/useUpdateRow");

export const useUpdateRow = <T extends Row>(
  trans: Transaction,
  entity: Entity<T>,
  data: T | null,
  onAddRow?: (row: TransRow<T>) => void
): TransRow<T> => {
  const [row, setRow] = React.useState<TransRow<T>>(null);
  React.useEffect(() => {
    if (row) {
      throw new Error("trying to create duplicate row");
    }
    if (data) {
      debug(`calling makeUpdateRow() with data: ${JSON.stringify(data)}`);
      const tempRow = trans.makeUpdateRow<T>(entity, data);
      setRow(tempRow);
      if (onAddRow) {
        onAddRow(tempRow);
      }
    }
  }, [data]);
  return row;
};
